import React, { useState, useEffect } from "react";
import { Image, Row, Button, Modal } from 'antd';
import { Link } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons';
import { connect } from "react-redux"
import getLanguage from "../../../utilFunction/utilFunction";
import Aos from "aos";
import "aos/dist/aos.css";
import { apiURL } from '../../../Config/Config';
import Moment from 'react-moment';

const AdminEvent =(props)=>{

    const [data, setData] = useState([''])
    const [isLoading, setIsLoading] = useState(false);
    const [idEvent, setIdEvent] = useState('');
    const [visibleModal, setvisibleModal] = useState(false);
    
    useEffect(() => {
        Aos.init({
            duration : 1000,
            delay: 0,
            once: true,
            disable: 'mobile'
        }); 
        getData()
    }, []);

    const getData=async()=>{
    const requestOptions = {
        method: 'GET',
    };
    const res =  await fetch(apiURL+"getAllEvents", requestOptions);
    if(res.status == 200){
        const resJson = await res.json();
        setData(resJson);
    }            
    }

    const removeData =async (id) => {
        console.log("testtt",id);
        const requestOptions = {
            method: 'POST',
          };
          const data = await fetch(apiURL+'deleteActuality/'+id,requestOptions);
          const dataJson = await data.json();
          console.log("here2",dataJson);
          if(data.status == 200){
            console.log("success");
            setvisibleModal(false)
            window.location='/admin/events'
          }
    }
    
    const renderHeader = () => {
        let headerElement = ['id', 'image', 'title fr', 'title en', 'content fr', 'content en', 'social Link', 'meeting link', 'creationDate', 'action']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const addRedirection =()=>{
      window.location="/admin/add-event"
    }

    const showModal = (id) => {
        setIdEvent(id)
        setvisibleModal(true);
      };
    const closeModal=()=>{
        setvisibleModal(false);
      }

    return(
        <>
           <h1 id='title'>Events</h1>
            <center>
                <button className='button' onClick={() => addRedirection()}>Add</button>
            </center>
            <Row justify="center">
                <table id='employee'>
                    <thead>
                        <tr>{renderHeader()}</tr>
                    </thead>
                    <tbody>
                    {data.length>0 && Object.keys(data[0]).length !== 0 && data.map((event) => (
                        <tr key={event.id}>
                            <td>{event.id}</td>
                            <td>
                                <Image src={apiURL+event.image.replace('/public/', '')} width={100} height={50} />
                            </td>
                            <td>{event.titleFr}</td>
                            <td>{event.titleEn}</td>
                            <td>{event.contentFr.substring(0, 100)}...</td>
                            <td>{event.contentEn.substring(0, 100)}...</td>
                            <td>{event.socialLink}</td>
                            <td>{event.meetingLink}</td>
                            <td><Moment format="YYYY/MM/DD">{event.creationDate}</Moment></td>
                            <td className='opration'>
                                <Link className='button' to={`/admin/editEvent/${event.id}`}>Edit</Link>
                                <button className='button'  onClick={()=>showModal(event.id)}>Delete</button>
                            </td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
            </Row>
            <Modal title="supprimer evennement" visible={visibleModal} footer={null} mask={true} closable={false} maskClosable={true}>
            <p>veuillez confirmer votre choix</p>
            <Button onClick={()=>removeData(idEvent)}>delete</Button>
            <Button onClick={()=>closeModal()} >cancel</Button>
            </Modal>
        </>
    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        lang:state.auth.lang
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(AdminEvent);