import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload, Switch} from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"
import isURL from 'validator/es/lib/isURL';

class EditVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url:'',content_fr:'',content_en:'',title_fr:'',title_en:'',
            idVideo:'',                        
  
         validation:{error:[true,true,true,true,true],errorMsg:
         [getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,    
        ]},
          videoError:[true,true,true,true,true],
          videoErrorMsg:['','','','','']}
       
    }

    componentDidMount(){
        this.setState({idVideo:this.props.match.params.id})
        console.log(this.props.match.params.id);
        this.getVideoById()
    }
    getVideoById=async()=>{
        const requestOptions = {
            method: 'GET',
          };
          const data = await fetch(apiURL+'getVideo/'+this.props.match.params.id,requestOptions);
          const dataJson = await data.json();
          if(data.status == 200){
            console.log("success");
            this.setState({url:dataJson.url, title_fr:dataJson.titleFr, title_en:dataJson.titleEn, content_fr:dataJson.contentFr, content_en:dataJson.contentEn});
            // if(this.state.buttons == false){
            //   this.setState({buttons: 0})
            //   console.log('uifaux', this.state.buttons);
            // }
            // else{
            //   this.setState({buttons: 1})
            //   console.log('uivrai', this.state.buttons);
            // }
          }
    }

    videoValidation =() =>{
      let aux ={...this.state.validation}
        if(!isURL(this.state.url) ){
        
          aux.error[0]=true
          aux.errorMsg[0]='Veuillez saisir une URL valide.'
        }else{
            aux.error[0]=false
            aux.errorMsg[0]=''
        }
        if(this.state.title_fr.trim()=='' ){
        
          aux.error[1]=true
          aux.errorMsg[1]=getLanguage(this.props).required
        }else{
            aux.error[1]=false
            aux.errorMsg[1]=''
        }
        if(this.state.title_en.trim()=='' ){
          
          aux.error[2]=true
          aux.errorMsg[2]=getLanguage(this.props).required
        }else{
            aux.error[2]=false
            aux.errorMsg[2]=''
        }        
        if(this.state.content_fr.trim()=='' ){
        
          aux.error[3]=true
          aux.errorMsg[3]=getLanguage(this.props).required
        }else{
            aux.error[3]=false
            aux.errorMsg[3]=''
        }
        if(this.state.content_en.trim()=='' ){
          
          aux.error[4]=true
          aux.errorMsg[4]=getLanguage(this.props).required
        }else{
            aux.error[4]=false
            aux.errorMsg[4]=''
        }        
        this.setState({validation:aux})
    }
  
    updateVideo=async()=>{
      this.videoValidation()
         const ERROR = [...this.state.validation.error]
            const ERROR_MSG=[...this.state.validation.errorMsg]
            this.setState({
              videoError:ERROR,
              videoErrorMsg:ERROR_MSG
            })
        if(!this.state.validation.error.includes(true)){
        this.setState({loading:true})
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let formdata =new FormData()
        // formdata.append('photo',this.state.photo)        
        // else{
        //   formdata.append('url',this.state.sliderImg)
        // }
        formdata.append('url',this.state.url)
        formdata.append('title_fr',this.state.title_fr)
        formdata.append('title_en',this.state.title_en)        
        formdata.append('content_fr',this.state.content_fr)
        formdata.append('content_en',this.state.content_en)        
        
        const requestOptions = {
          method: 'POST',
          // headers: myHeaders,
          body: formdata
        };
        console.log('aaaa',apiURL+'updateVideo/'+this.state.idVideo,requestOptions);
        const data = await fetch(apiURL+'updateVideo/'+this.state.idVideo,requestOptions);
        console.log("here",data);
        const dataJson = await data.json();
        console.log("here2",dataJson);
        if(data.status == 200){
          console.log("success");
          this.setState({loading:false})
          window.location='/admin/videos'
        }
        }
    }

    render() {       
            
        return (  <>
            
            <h1 id='title'>Editer Vidéo</h1>
            
            <Row align="center">
                    <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                <Form
                name="basic"
                layout="vertical"
                >
                <Form.Item
                    label="Video"
                    name="url"
                    >     
                    {/* <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).requiredImage}</span> */}
                    <Input onChange={(e)=>this.setState({url: e.target.value})} value={this.state&&this.state.url} />
                    {this.state.videoError[0]&&<div style={{color:'red'}}>{this.state.videoErrorMsg[0]}</div>} 
                </Form.Item>    
                <Form.Item
                    label="titre en français"
                    name="title_fr"
                    >
                    <Input onChange={(e)=>this.setState({title_fr: e.target.value})} value={this.state&&this.state.title_fr} />
                    {this.state.videoError[1]&&<div style={{color:'red'}}>{this.state.videoErrorMsg[1]}</div>}                </Form.Item>
                <Form.Item
                    label="titre en anglais"
                    name="title_en"
                    >
                    <Input onChange={(e)=>this.setState({title_en: e.target.value})} value={this.state&&this.state.title_en} />
                  {this.state.videoError[2]&&<div style={{color:'red'}}>{this.state.videoErrorMsg[2]}</div>}                
                  </Form.Item>           
                <Form.Item
                    label="contenu en français"
                    name="content_fr"
                    >
                    <Input.TextArea onChange={(e)=>this.setState({content_fr: e.target.value})} value={this.state&&this.state.content_fr} />
                    {this.state.videoError[3]&&<div style={{color:'red'}}>{this.state.videoErrorMsg[3]}</div>}                </Form.Item>
                <Form.Item
                    label="contenu en anglais"
                    name="content_en"
                    >
                    <Input.TextArea onChange={(e)=>this.setState({content_en: e.target.value})} value={this.state&&this.state.content_en} />
                  {this.state.videoError[4]&&<div style={{color:'red'}}>{this.state.videoErrorMsg[4]}</div>}                
                  </Form.Item>
                <Button disabled={this.state.loading==true} onClick={this.updateVideo}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                    {getLanguage(this.props).submit} <RightOutlined />
                </Button>
                </Form>
                </Col>
                </Row>
                {this.state.loading == true ? <FullPageLoader  />: null}
        </> );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
export default connect(mapStateToProps, mapDispatchToProps)(EditVideo);