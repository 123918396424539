import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Input, Space, Modal, Table } from 'antd';
import { apiURL } from '../../../Config/Config';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const indexURL = apiURL+"getAllSharedFiles"

const SharedFiles = (props) => {
    const [data, setData] = useState([''])
    const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [idSharedfile, setIdSharedfile] = useState('');
    const [visibleModal, setvisibleModal] = useState(false);


    useEffect(() => {
        getData()
    }, [])

    const getData=async()=>{
    const requestOptions = {
        method: 'GET',
    };
    const res =  await fetch(indexURL, requestOptions);
    if(res.status == 200){
        const resJson = await res.json();        
        setData(resJson.reverse());
        console.log('data',data);
    }            
    }

    const removeData =async (id) => {
        const requestOptions = {
            method: 'POST',
          };
          const data = await fetch(apiURL+'deleteSharedFile/'+id,requestOptions);
          const dataJson = await data.json();
          console.log("here2",dataJson);
          if(data.status == 200){
            console.log("success");
            setvisibleModal(false)
            window.location='/admin/sharedfiles'
          }
    }   

    const addRedirection =()=>{
    window.location="/admin/add-sharedfile"
    }    

    const  handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0])
        setsearchedColumn(dataIndex)
    };

    const  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
        //   ref={node => {
        //     searchInput = node;
        //   }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setsearchText(selectedKeys[0])
                setsearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

const  handleReset = clearFilters => {
    clearFilters();
    setsearchText('')
  };
    
    const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      ...getColumnSearchProps('id'),
    },
    {
        title: 'Nom',
        dataIndex: 'name',
        key: 'name',
        ...getColumnSearchProps('name'),
    },  
    {
        title: 'Téléchargeable',
        dataIndex: 'downloadable',        
        key: 'downloadable',        
        ...getColumnSearchProps('downloadable'),
        render: (val)=><div className="">{val ? 'Activé':'Désactivé'}</div>
    },
    {
      title: 'Aperçu',
      dataIndex: 'previewing',
      key: 'previewing',
      ...getColumnSearchProps('previewing'),
      render: (val)=><div className="">{val ? 'Activé':'Désactivé'}</div>
    },
    {
             
      title: 'action',
      render: (dataIndex) => (
      <div style={{display:'flex', 'align-items':'flex-end', 'flex-direction': 'column', gap:3}}>
        <Link to={`/admin/editSharedfile/${dataIndex.id}`}>
          <Button size='small' type="primary">Editer</Button>
        </Link>
        <Button size='small' type="danger" onClick={()=>showModal(dataIndex.id)}>Supprimer</Button>
      </div>
      ),
    },
 
  ];
    const showModal = (id) => {
            setIdSharedfile(id)
            setvisibleModal(true);
        };
    const  closeModal=()=>{
        setvisibleModal(false);
      }

    const onSelect = (selectedKeys: React.Key[], info: any) => {
    console.log('selected', selectedKeys, info);
    };

    const onCheck = (checkedKeys: React.Key[], info: any) => {
        console.log('onCheck', checkedKeys, info);
    };
console.log(data,'dota');
    return (
        <>           
        <center>
            <h1>Fichiers Partageable</h1>                                        
          </center>
            <Row justify="center">                           
                <Col>
                <Button type="primary" style={{float:'right', marginBottom:20}}  onClick={() => addRedirection()}>Ajouter</Button>
                <Table columns={columns} dataSource={data} />
                </Col>
            </Row>
            <Modal title="supprimer fichier" visible={visibleModal} footer={null} mask={true} closable={false} maskClosable={true}>
            <p style={{textAlign:"center"}}>veuillez confirmer votre choix</p>
            <Row>
                <Col span={12}>
                <Button onClick={()=>removeData(idSharedfile)} >Supprimer</Button>
                </Col>
                <Col className="modal-button-delete" span={12}>
                <Button onClick={()=>closeModal()} >Annuler</Button>
                </Col>
            </Row>
            
            

      </Modal>
        </>
    )
}


export default SharedFiles