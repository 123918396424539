import { apiURL } from '../Config/Config';

    async function getAllActualities(props) {
    try {
        const requestOptions = {
            method: 'GET',
        };
        let response = await fetch(apiURL+"getAllActualities", requestOptions);
        let data = await response.json();
        return data;

    } catch(err) {
        console.log(err);
        return null;
    }
    }

export default getAllActualities