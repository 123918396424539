import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Table, Modal, Input, Space } from 'antd';
import { apiURL } from '../../../Config/Config';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const indexURL = apiURL+"getVideos"

const Videos = (props) => {
    const [data, setData] = useState([''])
    const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');
    const [idVideo, setIdVideo] = useState('');
    const [visibleModal, setvisibleModal] = useState(false);    

    useEffect(() => {
        getData()
    }, [])

    const getData=async()=>{
    const requestOptions = {
        method: 'GET',
    };
    const res =  await fetch(indexURL, requestOptions);
    if(res.status == 200){
        const resJson = await res.json();
        setData(resJson.reverse());
    }            
    }

    const removeData =async (id) => {
        console.log("testtt",id);
        const requestOptions = {
            method: 'POST',
          };
          const data = await fetch(apiURL+'deleteVideo/'+id,requestOptions);
          const dataJson = await data.json();
          console.log("here2",dataJson);
          if(data.status == 200){
            console.log("success");
            setvisibleModal(false)
            window.location='/admin/videos'
          }
    }


    const renderHeader = () => {
        let headerElement = ['id', 'video','title fr', 'title en', 'content fr', 'content en', 'Action']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const addRedirection =()=>{
        window.location="/admin/add-video"
    }
    
    const showModal = (id) => {
        setIdVideo(id)
        setvisibleModal(true);
      };
    const  closeModal=()=>{
        setvisibleModal(false);
      }

    const opts = {
        height: '168',
        width: '300',
        playerVars: {            
            autoplay: 1,
        },
        };
        
    const onReady=(event)=> {        
        event.target.pauseVideo();
    }

    const  handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0])
        setsearchedColumn(dataIndex)
    };

    const  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
        //   ref={node => {
        //     searchInput = node;
        //   }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setsearchText(selectedKeys[0])
                setsearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {        
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
    });

    const  handleReset = clearFilters => {
        clearFilters();
        setsearchText('')
    };

    const columns =[
        {
            title: 'id',
            dataIndex: 'id',            
            width: 50,
            ...getColumnSearchProps('id'),             
        },     
        {
            title: 'Videos',
            dataIndex: 'url',                        
            ...getColumnSearchProps('url'),     
            render: (val)=>(val&&val.length>0?<YouTube videoId={val.substr(32,11)} opts={opts} onReady={onReady} />:"")       
        },
        {
            title: 'Titre en français',
            dataIndex: 'titleFr',                        
            ...getColumnSearchProps('titleFr'),            
        },
        {
            title: 'Titre en anglais',
            dataIndex: 'titleEn',                        
            ...getColumnSearchProps('titleEn'),                         
        },
        {
            title: 'Contenu en français',
            dataIndex: 'contentFr',                        
            ...getColumnSearchProps('contentFr'),            
        },       
        {             
            title: 'action',                        
            render: (dataIndex) => (
            <div style={{display:'flex', 'align-items':'flex-end', 'flex-direction': 'column', gap:3}}>                
                <Link to={`/admin/editVideo/${dataIndex.id}`}><Button size='small' type="primary">Editer</Button></Link>
                <Button size='small' type="danger" onClick={()=>showModal(dataIndex.id)}>Supprimer</Button>
            </div>
            ),            
        },
    ];    

    return (
        <>
            <h1 id='title'>Vidéos</h1>         
            <Row justify="center">         
                <Col>
                    <Button type="primary" style={{float:'right', marginBottom:20}}  onClick={() => addRedirection()}>Ajouter</Button>
                    <Table columns={columns} dataSource={data}
                    pagination={{ defaultPageSize: 10, showSizeChanger: true}}
                    />
                </Col>
            </Row>
            <Modal title="supprimer video" visible={visibleModal} footer={null} mask={true} closable={false} maskClosable={true}>
            <p style={{textAlign:"center"}}>veuillez confirmer votre choix</p>
            <Row>
                <Col span={12}>
                <Button onClick={()=>removeData(idVideo)} >delete</Button>
                </Col>
                <Col className="modal-button-delete" span={12}>
                <Button onClick={()=>closeModal()} >cancel</Button>
                </Col>
            </Row>
      </Modal>
        </>
    )
}


export default Videos