import React, { Component, useEffect, useState  } from "react";
import { Row, Col, Input, Form, Button, Table, Card, Modal, Checkbox, message } from 'antd';
import { apiURL } from "../../Config/Config";
import { connect } from "react-redux";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import DownloadLink from "react-download-link";



const ShowCandidature =(props)=>{
    const [idCandidature, setidCandidature] = useState('');
    const [candidature, setcandidature] = useState([]);
    const [actifUser, setactifUser] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(false);
    const [files, setFiles] = useState([]);



    useEffect(() => {
        setidCandidature(props.match.params.id)
        getCandidatureById()
        getAllActifUser()
    }, [])
    const getCandidatureById=async()=>{
            const requestOptions = {
                method: 'GET',
              };
              const data = await fetch(apiURL+'show/'+props.match.params.id,requestOptions);
              const dataJson = await data.json();
              console.log("here2",dataJson);
              if(data.status == 200){    
                setcandidature(dataJson)
                setFiles(dataJson.files)
              }
        
    }

    const onChange=(checkedValues)=> {
        setSelectedUser(checkedValues)
        
      }
    const getAllActifUser =async()=>{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer '+props.auth.token}
          };
          const data = await fetch(apiURL+'getAllUserActif/'+props.auth.username,requestOptions);
          const dataJson = await data.json();
          console.log("here2",dataJson);
          if(data.status == 200){
            console.log("success");
            setactifUser(dataJson)
          }
    }
    const showModal=()=>{
        setIsModalVisible(true);
        
    }
    const renderChecknox =()=>{
        return(
            <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
            <Row>
            <Col span={10}>
                {actifUser && actifUser.map((el)=>(
                    <Checkbox value={el.id}>{el.username}</Checkbox>
                ))}
            </Col>
            </Row>
        </Checkbox.Group>
            
        )
    }

    const sendUser =async()=>{
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        let formdata =new FormData()
        selectedUser.forEach(element => {
            formdata.append('idUser[]',element)
        });
        formdata.append('candidatures',idCandidature)
        
        const requestOptions = {
          method: 'POST',
          // headers: myHeaders,
          body: formdata
        };
        const data = await fetch(apiURL+'assignUserCand',requestOptions);
        const dataStatus = await data.status;
        console.log("here2",dataStatus);
        if(dataStatus == 200){
            setIsModalVisible(false);
            message.success('success');
          
        }
    }
    const closeModal=()=>{
        setIsModalVisible(false);
    }
    console.log("files",files);
    // console.log("candidature",candidature);
    
    return(
        <div>
            <Card>
                 <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>Nom: <span className='text-candidiature'>{candidature.nom}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>Prenom: <span className='text-candidiature'>{candidature.prenom}</span> </p>
                    </Col>
                </Row>
               <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>email: <span className='text-candidiature'>{candidature.email}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>genre: <span className='text-candidiature'>{candidature.genre}</span> </p>
                    </Col>
                </Row>
                 <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>telephone: <span className='text-candidiature'>{candidature.tel}</span></p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>nom du startup: <span className='text-candidiature'>{candidature.nom_startup}</span> </p>
                    </Col>
                </Row>
                <Row>
                    {/* <Col md={10}>
                    <p>definition_projet: {candidature.definition_projet}</p>
                    </Col> */}
                    <Col md={20}>
                    <p className='label-candidiature'>secteur d'activite: <span className='text-candidiature'>{candidature.secteur_activite}</span> </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>description du projet:<span className='text-candidiature'>{candidature.description_projet}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>Vous avez le Label Startup Act ?:<span className='text-candidiature'>{candidature.startup_act === true ? "Oui" : "Non"}</span> </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>A quel stade en êtes-vous dans le développement de votre projet ?:<span className='text-candidiature'>{candidature.stade_developpement}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>capital en dinars:<span className='text-candidiature'>{candidature.capital}</span> </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>besoin en financement en Dinars:<span className='text-candidiature'>{candidature.besoin_financement}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>Disposez-vous de fonds à ce stade:<span className='text-candidiature'>{candidature.fonds}</span> </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>Votre chiffre d'affaire pour l'année 2020: <span className='text-candidiature'>{candidature.chiffre_affaire}</span></p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>Nombre de personnes dans l'équipe: <span className='text-candidiature'>{candidature.nombre_equipe}</span></p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>Nombre de femmes dans l'équipe: <span className='text-candidiature'>{candidature.nombre_femme}</span></p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>Considérez vous que votre projet a un impact positif sur la société ?: <span className='text-candidiature'>{candidature.impact_projet == true ? "Oui": "Non"}</span></p>
                    </Col>
                </Row>
                <Row>
                   
                    <Col md={12}>
                    <p className='label-candidiature'>En quoi consiste votre créativité dans ce projet ?:<span className='text-candidiature'>{candidature.creativite_projet}</span> </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'> Pourquoi souhaitez-vous rejoindre Damya Business Angels ?:<span className='text-candidiature'>{candidature.cause_rejoint}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>Postulez-vous en parallèle à programme d'accompagnement ?:<span className='text-candidiature'>{candidature.prog_accompagnement == true ? "Oui": "Non"}</span> </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>Remarques et commentaires éventuels:<span className='text-candidiature'>{candidature.remarque_commentaire}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>dateCreation:<span className='text-candidiature'>{candidature.dateCreation}</span> </p>
                    </Col>
                </Row>
                <Row>
                   
                    {files.length>0 && Object.keys(files[0]).length !== 0 && files.map(({ id, url, name, type,  }) => (
                              <Col md={12}>  <a href={apiURL+url.replace('/public/', '')} download>Click to download file {type}</a></Col>
                    ))
                    }

                </Row>
                {/* <Row>
                    <Col md={112}>
                    <Button onClick={showModal} >Assigner candidature</Button>
                    </Col>
                    
                </Row> */}


        <Modal visible={isModalVisible} footer={false} closable={false}>
        {renderChecknox()}

        <Row style={{paddingTop:"50px"}}>
            <Col span={12}>
            <Button onClick={sendUser}>Confirmer</Button>
            </Col>
            <Col span={12} style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
            <Button onClick={closeModal}>annuler</Button>
            </Col>
        </Row>
      </Modal>
    </Card>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(ShowCandidature);