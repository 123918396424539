import React, { Component } from "react";
import { connect } from "react-redux"
import { Link } from 'react-router-dom'
import { Layout, Button, Space, Row, Col, Carousel, Empty } from 'antd';
import Aos from "aos";
import "aos/dist/aos.css";
import { apiURL } from '../../Config/Config';
import { RightOutlined } from '@ant-design/icons';
import CountUp from 'react-countup';
import YouTube from 'react-youtube';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import standby from '../../assets/img/standby.png'
import support from '../../assets/img/support.png'
import impact from '../../assets/img/impact.png'
import ethique from '../../assets/img/ethique.png'
import office from '../../assets/img/office.png'
import rectangle from '../../assets/img/rectangle.png'
import rectangleGrey from '../../assets/img/rectangle-grey.png'
import investWoman from '../../assets/img/invest-woman.png'
import carouselLeft from '../../assets/img/carousel-left.png';
import carouselRight from '../../assets/img/carousel-right.png';
import detectBrowserLanguage from 'detect-browser-language'
import getLanguage from "../../utilFunction/utilFunction";
import getAllActualities from './../../utilFunction/showAllActualities';
import getAllBoards from './../../utilFunction/showAllBoards';
import getAllPartners from './../../utilFunction/showAllPartners';
import getAllNumbers from './../../utilFunction/showAllNumbers';
import getAllSliders from './../../utilFunction/showAllSliders';
import getAllVideos from './../../utilFunction/showAllVideos';
import VisibilitySensor from 'react-visibility-sensor';


const { Content } = Layout;

class LandingPage extends Component {
  
  constructor(props) {
    super(props);
    this.video = React.createRef()
    this.state = {
      userLanguage:'',
      dimensions: null,
      slideIndex:0, 
      updateCount:0,
      actualityData: [''],
      boardData: [''],
      sliderData:[''],
      videoData:[''],
      partnersData:[''],
      numbersData:[''],
      didViewCountUp: false
    }; 
    this.onVisibilityChange = this.onVisibilityChange.bind(this); 
  }

  onVisibilityChange = isVisible => {
    if (isVisible) {
      this.setState({didViewCountUp: true});
    }
  }
  
  componentDidMount(){
    getAllActualities(this.props).then((e)=>{
      this.setState({actualityData: e})
    })
    getAllBoards(this.props).then((e)=>{
      this.setState({boardData: e})
    })
    getAllPartners(this.props).then((e)=>{
      this.setState({partnersData: e})
    })
    getAllNumbers(this.props).then((e)=>{
      this.setState({numbersData: e})
    })
    getAllSliders(this.props).then((e)=>{
      this.setState({sliderData: e})      
    })
    getAllVideos(this.props).then((e)=>{
      this.setState({videoData: e})      
    })
    getLanguage(this.props)
    this.setState({ 
      userLanguage: detectBrowserLanguage(), 
      dimensions: {
        width: this.container.offsetWidth,
      }
      })      
    Aos.init({
      duration : 1000,
      delay: 0,
      once: true,
      disable: 'mobile'
    });     
    window.scrollTo(0, 0)
  }

  refreshed = ()=>{
    Aos.refresh();
  }

  opts = {
    width: '100%',
    height: '100%',
      playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  onReady=(event)=> {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  renderVideo(video) {
    const { dimensions } = this.state;
    var width = 0;
    var height = 0;
    if (dimensions.width>768) {
      width = dimensions.width*55/100;
    } else {
      width = dimensions.width*80/100;
    }
    height = width*60/100;
    return (
      <center>
      <div className="player-wrapper">
        <YouTube className="youtube-player" src={video} videoId={video.substr(32,11)} opts={this.opts} onReady={this.onReady}/>        
      </div>
      </center>
    );
  }

  handleClick = e => {
    console.log('click ', e);
    this.setState({ current: e.key });
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  redirectEmail(email){
     window.location = "mailto:"+email;
  }
  
  redirectLinkedIn(link){
    link = link.replace("https://",'');
    link = link.replace("http://",'');
    window.open("https://"+link, "_blank")
  }
 
    render() {      
      
    const { dimensions } = this.state;
    
    const NextArrow = ({ onClick }) => {
            return (
            <div className="arrow next" onClick={onClick}>
                <img className="arrow-size" src={carouselRight} />
            </div>
            );
        };
    
    const PrevArrow = ({ onClick }) => {
            return (
            <div className="arrow prev" onClick={onClick}>
                <img className="arrow-size" src={carouselLeft} />
            </div>
            );
        };
    
    const BoardNextArrow = ({ onClick }) => {
            return (
            <div className="arrow board-next" onClick={onClick}>
                <img className="arrow-size" src={carouselRight} />
            </div>
            );
        };
    
    const BoardPrevArrow = ({ onClick }) => {
            return (
            <div className="arrow board-prev" onClick={onClick}>
                <img className="arrow-size" src={carouselLeft} />
            </div>
            );
        };
    
    const ActualityNextArrow = ({ onClick }) => {
            return (
            <div className="arrow actuality-next" onClick={onClick}>
                <img className="arrow-size" src={carouselRight} />
            </div>
            );
        };
    
    const ActualityPrevArrow = ({ onClick }) => {
            return (
            <div className="arrow actuality-prev" onClick={onClick}>
                <img className="arrow-size" src={carouselLeft} />
            </div>
            );
        };

    const PartnerNextArrow = ({ onClick }) => {
            return (
            <div className="arrow partner-next" onClick={onClick}>
                <img className="arrow-size" src={carouselRight} />
            </div>
            );
        };
    
    const PartnerPrevArrow = ({ onClick }) => {
            return (
            <div className="arrow partner-prev" onClick={onClick}>
                <img className="arrow-size" src={carouselLeft} />
            </div>
            );
        };
    

    const settings = {
            infinite: true,
            lazyLoad: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: 0,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            
        };
        
    const boardSettings = {
            infinite: true,
            speed: 800,
            slidesToShow: 4,
            slidesToScroll: 1,
            swipeToSlide: true,
            centerPadding: 0,
            nextArrow: <BoardNextArrow />,
            prevArrow: <BoardPrevArrow />,
            responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        };

    const actualitySetting = {
            infinite: false,
            speed: 800,
            slidesToShow: 3,
            slidesToScroll: 1,
            swipeToSlide: true,
            centerPadding: 0,
            nextArrow: <ActualityNextArrow />,
            prevArrow: <ActualityPrevArrow />,
            dots:true,
            responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,             
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
        };

        const partnerSettings = {
            infinite: this.state.partnersData.length>3?true:false,            
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            swipeToSlide: true,
            nextArrow: <PartnerNextArrow />,
            prevArrow: <PartnerPrevArrow />,
            responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
            
        };
    
    console.log(this.state.userLanguage&&this.state.userLanguage);
    console.log("partners", this.state.partnersData);
    return (
      
      <div>
        <Content>
          {/* home section */}
          <Carousel autoplay lazyload>
          {this.state.sliderData.length>0 && Object.keys(this.state.sliderData[0]).length !== 0 && this.state.sliderData.map((slider)=>(          
          <div>          
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>            
              <div className="slider1-section">
                <img src={apiURL+slider.url.replace('/public/', '')} className="slider1" />
                <img src={apiURL+slider.url.replace('/public/', '')} className="hidden-slider-height"/> 
                {/* <h1 data-aos="fade-up" className={this.props.lang == "fr"?this.props.lang+"_home-title":this.props.lang+"_home-title"}><span className="break-line-1">{getLanguage(this.props).slider1TitlePart1}</span><span className="break-line-2">{getLanguage(this.props).slider1TitlePart2}</span>{getLanguage(this.props).slider1TitlePart3}</h1>       */}
                <h1 data-aos="fade-up" className="home-title">{this.props.lang == "fr"? slider.titleFr:slider.titleEn} </h1>      
                {slider.buttons == true ?
                <Space className="btn-home">
                  <Link to="/investor">
                    <Button data-aos="home-btn-animation" className="btn-slide-damya" >
                      {getLanguage(this.props).DevenirDamyaAngel}
                    </Button>
                  </Link>
                  <Link to="/startup">
                    <Button data-aos="home-btn-animation" className="btn-slide">
                      {getLanguage(this.props).DéposezVotreProjet}
                    </Button>
                  </Link>
                </Space>
                : <p className="impact-text">{this.props.lang == "fr"? slider.textFr: slider.textEn}</p> }
              </div>
            </Col>
          </Row>                   
          </div>                    
          ))}                    
          </Carousel>
          {/* services section */}
          <div className={this.props.lang == "fr"?this.props.lang+"_service-section":this.props.lang+"_service-section"}>
            <h2 data-aos="zoom-in-up" className={this.props.lang == "fr"?this.props.lang+"_title-section-service":this.props.lang+"_title-section-service"}>{getLanguage(this.props).servicesTitle}</h2>
            <Row data-aos="fade-up" justify="center" className={this.props.lang == "fr"?this.props.lang+"_service-columns-pad":this.props.lang+"_service-columns-pad"} gutter={[{ xs: 24, sm: 24, md: 24, lg: 20, xl: 65 }, 50]}>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <img src={support} className={this.props.lang == "fr"?this.props.lang+"_img-center":this.props.lang+"_img-center"} />
                <p className={this.props.lang == "fr"?this.props.lang+"_service-title":this.props.lang+"_service-title"}>{getLanguage(this.props).serviceTitle1}</p>
                <p className={this.props.lang == "fr"?this.props.lang+"_service-text":this.props.lang+"_service-text"}></p>
              </Col>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <img src={standby} className={this.props.lang == "fr"?this.props.lang+"_img-center":this.props.lang+"_img-center"} />
                <p className={this.props.lang == "fr"?this.props.lang+"_service-title":this.props.lang+"_service-title"}>{getLanguage(this.props).serviceTitle2}</p>
                <p className={this.props.lang == "fr"?this.props.lang+"_service-text":this.props.lang+"_service-text"}></p>
              </Col>           
              <Col className="gutter-row pad-row" xs={24} sm={12} md={12} lg={6} xl={6}>
                <img src={impact} className={this.props.lang == "fr"?this.props.lang+"_img-center":this.props.lang+"_img-center"} />
                <p className={this.props.lang == "fr"?this.props.lang+"_service-title":this.props.lang+"_service-title"}>{getLanguage(this.props).serviceTitle5}</p>
                <p className={this.props.lang == "fr"?this.props.lang+"_service-text":this.props.lang+"_service-text"}></p>
              </Col>            
              <Col className="gutter-row pad-row" xs={24} sm={12} md={12} lg={6} xl={6} >
                <img src={ethique} className={this.props.lang == "fr"?this.props.lang+"_img-center":this.props.lang+"_img-center"} />
                <p className={this.props.lang == "fr"?this.props.lang+"_service-title":this.props.lang+"_service-title"}>{getLanguage(this.props).serviceTitle7}</p>
                <p className={this.props.lang == "fr"?this.props.lang+"_service-text":this.props.lang+"_service-text"}></p>
              </Col>
            </Row>
          </div>
          {/* actuality section */}
          {this.state.actualityData ? 
          <div data-aos="fade-up" className="actuality-section">
            <h2 className="title-section-actuality">{getLanguage(this.props).actuality}</h2>
            <Slider {...actualitySetting}>
            {this.state.actualityData.length>0 && Object.keys(this.state.actualityData[0]).length !== 0 && this.state.actualityData.map((act)=>(
              <div>
                <div className="actuality-card">
                  <img src={apiURL+act.image.replace('/public/', '')} className="actuality-img" />
                  <div className="card-content">                  
                  <h2 className="actuality-title" style={{paddingTop:"20px"}}>{this.props.lang == "fr" ? act.titleFr : act.titleEn}</h2>
                  <p className="single-actuality-text"> {this.props.lang == "fr" ? act.excerptFr : act.excerptEn}...</p>
                  <Link to={`/actuality/${act.id}`}>
                    <Button className="actuality-btn">
                      {getLanguage(this.props).knowMore} <RightOutlined className="actuality-btn-arrow"/>
                    </Button>
                  </Link>
                </div>
              </div>            
              </div>
            ))}
            </Slider>
            <Link to="/actualities">
              <Button className="show-all-actuality-btn">
                {getLanguage(this.props).showAll} <RightOutlined className="actuality-arrow-btn"/>
              </Button>
            </Link>
          </div>:''}
          {/* startup section */}
          <div id="for-startup" className={this.props.lang == "fr"?this.props.lang+"_startup-section-relative":this.props.lang+"_startup-section-relative"}>       
            <img src={office} className={this.props.lang == "fr"?this.props.lang+"_startup-hidden-height":this.props.lang+"_startup-hidden-height"} />
            <img src={office} className={this.props.lang == "fr"?this.props.lang+"_office-img":this.props.lang+"_office-img"} />
            <div className={this.props.lang == "fr"?this.props.lang+"_relative-rect":this.props.lang+"_relative-rect"}>
              <img src={rectangle} className={this.props.lang == "fr"?this.props.lang+"_rectangle-img":this.props.lang+"_rectangle-img"} />
              <h2 data-aos="fade-left" className={this.props.lang == "fr"?this.props.lang+"_for-startup-title":this.props.lang+"_for-startup-title"}>{getLanguage(this.props).startupTitle}</h2>
              <p data-aos="fade-left" className={this.props.lang == "fr"?this.props.lang+"_startup-text-one":this.props.lang+"_startup-text-one"}>
                {getLanguage(this.props).startupText}<br />
                {getLanguage(this.props).startupTextOne} 
              </p>
              <Link to="/startup">
                <Button className={this.props.lang == "fr"?this.props.lang+"_read-more":this.props.lang+"_read-more"}>
                  {getLanguage(this.props).knowMore} <RightOutlined className={this.props.lang == "fr"?this.props.lang+"_arrow-mobile":this.props.lang+"_arrow-mobile"}/>
                </Button>
              </Link>
            </div>
          </div>
          {/* investment section */}
          <div className={this.props.lang == "fr"?this.props.lang+"_invest-section-relative":this.props.lang+"_invest-section-relative"}>
            <div>
            <img src={investWoman} className={this.props.lang == "fr"?this.props.lang+"_invest-hidden-height":this.props.lang+"_invest-hidden-height"}/>
            <img src={investWoman} className={this.props.lang == "fr"?this.props.lang+"_invest-woman":this.props.lang+"_invest-woman" } />
            <div className={this.props.lang == "fr"?this.props.lang+"_relative-rect-invest":this.props.lang+"_relative-rect-invest"}>
              <img src={rectangleGrey} className={this.props.lang == "fr"?this.props.lang+"_rectangle-grey":this.props.lang+"_rectangle-grey"} />
              <h2 data-aos="fade-right" className={this.props.lang == "fr"?this.props.lang+"_for-investor-title":this.props.lang+"_for-investor-title"}>{getLanguage(this.props).investTitle}</h2>
              <p data-aos="fade-right" className={this.props.lang == "fr"?this.props.lang+"_invest-text-one":this.props.lang+"_invest-text-one"}>{getLanguage(this.props).investText}</p>
              <Link to="/investor">
                <Button className={this.props.lang == "fr"?this.props.lang+"_invest-read-more":this.props.lang+"_invest-read-more"}>
                  {getLanguage(this.props).knowMore} <RightOutlined className={this.props.lang == "fr"?this.props.lang+"_arrow-mobile":this.props.lang+"_arrow-mobile"}/>
                </Button>
              </Link>
            </div>
            </div>
          </div>          
          <div ref={el => (this.container = el)} className={this.props.lang == "fr"?this.props.lang+"_testimony-section-non-relative":this.props.lang+"_testimony-section-non-relative"}>
            <h2 data-aos="fade-left" className={this.props.lang == "fr"?this.props.lang+"_title-section-testimony":this.props.lang+"_title-section-testimony"}>{getLanguage(this.props).testimonyTitle1}</h2>
            <div className={this.props.lang == "fr"?this.props.lang+"_video":this.props.lang+"_video"}>
              <center>
              <div data-aos="fade-up">
              <Slider {...settings}>
              {this.state.videoData && Object.keys(this.state.videoData[0]).length !== 0 && this.state.videoData.map((video)=>(
                <div>
                    {dimensions && this.renderVideo(video.url)}
                    <p className={this.props.lang == "fr"?this.props.lang+"_testimony-name":this.props.lang+"_testimony-name"}>{this.props.lang == "fr"?video.titleFr:video.titleEn}</p>               
                    <p className={this.props.lang == "fr"?this.props.lang+"_subtitle-section-testimony":this.props.lang+"_subtitle-section-testimony"}>{this.props.lang == "fr"?video.contentFr:video.contentEn}</p>
                </div>    
                ))}                                
              </Slider>                
              </div>
              </center>
            </div>                 
          </div>

          {/* partner section */}
          {this.state.partnersData.length>0?
          <div data-aos="fade-up" className={this.props.lang == "fr"?this.props.lang+"_partner-section-non-relative":this.props.lang+"_partner-section-non-relative"}>
            <h2 className={this.props.lang == "fr"?this.props.lang+"_title-section-partner":this.props.lang+"_title-section-partner"}>{getLanguage(this.props).partnerTitle}</h2>
            <div className={this.props.lang == "fr"?this.props.lang+"_partner-logo-spacing":this.props.lang+"_partner-logo-spacing"}>          
            <Slider {...partnerSettings}>
            {Object.keys(this.state.partnersData[0]).length !== 0 && this.state.partnersData.map((partners)=>(
                <div className="partner-home-col">
                  <img className="partner-img" src={apiURL+partners.path.replace('/public/', '')} />
                </div>
                ))}
            </Slider>                              
            </div>  
          </div>:''}

          {/* numbers section */}
          {this.state.numbersData.length>0?
          <div data-aos="fade-up" style={{marginTop:45}} className="number-section-non-relative">
            <h2 className="title-section-number">{getLanguage(this.props).damyaInNumbers}</h2>            
            <Row justify="center" className="row-numbers">
            {Object.keys(this.state.numbersData[0]).length !== 0 && this.state.numbersData.map((n)=>(
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <div className="counter-up">
                          <VisibilitySensor
                            onChange={this.onVisibilityChange}
                            delayedCall
                          >
                            <CountUp end={this.state.didViewCountUp?n.number:0} duration={2} ref={countUp => { this.myCountUp = countUp; }}/>
                          </VisibilitySensor>
                        </div>
                        <p className="element-number" data-aos="fade-in">{this.props.lang == "fr" ? n.titleFr:n.titleEn}</p>
                    </Col>
                ))}  
            </Row>                                                  
          </div>:<Empty />}
        </Content>
      </div >

    )

  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    lang:state.auth.lang
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);

