import React, { Component } from "react";
import StartupForm from './startup/StartupForm';
import { Row, Col, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { connect } from "react-redux"
import { Link } from 'react-router-dom'
import getLanguage from "../utilFunction/utilFunction"
import startupFormBackground from '../assets/img/startup-form-background.png'
import arrowLeft from '../assets/img/arrow-left.png'
import {apiURL} from '../Config/Config'
import isEmail from 'validator/lib/isEmail';
import flechCercle from "../assets/img/flech-cercleee.png"
import StepZeroStartup from "./startup/StepZeroStartup";
import FullPageLoader from "./FullPageLoader";
import StepsThxStarup from "./startup/StepsThxStarup";


class Startup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      current:0,
      startupFormData:{nom:'',prenom:'',genre:'',email:'',tel:'',pEse:'',nStrup:'',dCEse:'',dPSPse:'',sActvt:'',dDP:'',lSA:'',sDPrjet:'',cDnrs:'',bFDnrs:'',fSde:'',cAre:'',pEqpe:'',fEqpe:'',iPSciete:'',cPrjet:'',dBA:'',pAccgnment:'',rCEvntuels:'',cv:'',docs:'',
         validation:{error:[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],errorMsg:
         [getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,]}},
          startupFormError:[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
          startupFormErrorMsg:['','','','','','','','','','','','','','','','','','','','','','','','','',''],
        };
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  onChangeStartupFormData=(value,key,index)=>{
  let aux ={...this.state.startupFormData}
      aux[key]=value
      if(key=="nom"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="prenom"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="genre"){
        if(!value){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="email"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }
        if(!isEmail(value)){
      
      aux.validation.error[index]=true
      aux.validation.errorMsg[index]=getLanguage(this.props).requiredCorrectEmail
    }
        else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="tel"){
        if(value==undefined ){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }if(!isNaN(Number(value)) ==false){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).requiredCorrectPhoneNumber
        }
        // if(value.length > 8 || value.length < 8){
        //   aux.validation.error[index]=true
        //   aux.validation.errorMsg[index]=getLanguage(this.props).requiredCorrectPhoneNumber1
        // }
        else{
          value=value.toString();
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="pEse"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="nStrup"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="dCEse"){
       
        if(!value){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="dPSPse"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="sActvt"){
        if(value.length == 0){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="dDP"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="lSA"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="sDPrjet"){
        if(value.length == 0){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="cDnrs"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }if(!isNaN(Number(value)) ==false ){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).numberRequired
        }
        else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="bFDnrs"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }if(!isNaN(Number(value)) ==false){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).numberRequired
        }
        else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="fSde"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="cAre"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }if(!isNaN(Number(value)) ==false){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).numberRequired
        }
        else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="pEqpe"){
        console.log("1000",value);
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }if(!isNaN(Number(value)) ==false){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).numberRequired
        }
        else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="fEqpe"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }if(!isNaN(Number(value)) ==false){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).numberRequired
        }
        else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="iPSciete"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="cPrjet"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="dBA"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="pAccgnment"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="rCEvntuels"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="cv"){
        console.log('cv', value);
        if(!value){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="docs"){
        // console.log("hereeee10");
        // console.log("docs", value.fileList[0]);
        if(!value ){
          console.log("hereeeeeeeeeeeeeeeeeee");
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      console.log(aux);
      const action ={type:"SET_STARTUP_STEP_ONE"};
      this.props.dispatch(action);
      this.setState({startupFormData:aux})
  }

  // onChangeStepTwoData=(value,key,index)=>{
  // let aux ={...this.state.stepTwoData}
  //     aux[key]=value
  //     if(key=="sActvt"){
  //       if(value.length == 0){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="dDP"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="lSA"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="sDPrjet"){
  //       if(value.length == 0){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="cDnrs"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }if(!isNaN(Number(value)) ==false ){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).numberRequired
  //       }
  //       else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="bFDnrs"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }if(!isNaN(Number(value)) ==false){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).numberRequired
  //       }
  //       else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="fSde"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="cAre"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }if(!isNaN(Number(value)) ==false){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).numberRequired
  //       }
  //       else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     const action ={type:"SET_STARTUP_STEP_TWO", value:aux}
  //     this.props.dispatch(action);
  //     console.log(aux);
  //     this.setState({stepTwoData:aux})
  // }

  // onChangeStepThreeData=(value,key,index)=>{
  // let aux ={...this.state.stepThreeData}
  //     aux[key]=value
  //     if(key=="pEqpe"){
  //       console.log("1000",value);
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }if(!isNaN(Number(value)) ==false){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).numberRequired
  //       }
  //       else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="fEqpe"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }if(!isNaN(Number(value)) ==false){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).numberRequired
  //       }
  //       else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="iPSciete"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="cPrjet"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="dBA"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="pAccgnment"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="rCEvntuels"){
  //       if(value.trim()===''){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="cv"){
  //       console.log('cv', value);
  //       if(!value){
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     if(key=="docs"){
  //       // console.log("hereeee10");
  //       // console.log("docs", value.fileList[0]);
  //       if(!value ){
  //         console.log("hereeeeeeeeeeeeeeeeeee");
  //         aux.validation.error[index]=true
  //         aux.validation.errorMsg[index]=getLanguage(this.props).required
  //       }else{
  //         aux.validation.error[index]=false
  //         aux.validation.errorMsg[index]=''
  //       }
  //     }
  //     const action ={type:"SET_STARTUP_STEP_THREE", value:aux}
  //     this.props.dispatch(action);
  //     console.log(aux);
  //     this.setState({stepThreeData:aux})
  // }

  nextStep=()=>{
    this.setState({current: this.state.current+1})
      // console.log('hello');
      // if(this.state.current == 0){
      //   this.setState({current: this.state.current+1});
      // }
      // if(this.state.current===1){
      //   const ERROR = [...this.state.startupFormData.validation.error]
      //   const ERROR_MSG=[...this.state.startupFormData.validation.errorMsg]
      //   this.setState({
      //     startupFormError:ERROR,
      //     startupFormErrorMsg:ERROR_MSG
      //   })
      //   if(!this.state.startupFormData.validation.error.includes(true)){
      //     this.setState({current: this.state.current+1})
      //   }
      // }
      // else if(this.state.current===2){
      //   const ERROR = [...this.state.stepTwoData.validation.error]
      //   const ERROR_MSG=[...this.state.stepTwoData.validation.errorMsg]
      //   this.setState({
      //     stepTwoError:ERROR,
      //     stepTwoErrorMsg:ERROR_MSG
      //   })
      //   if(!this.state.stepTwoData.validation.error.includes(true)){
      //     this.setState({current: this.state.current+1})
      //   }
      // }
  }

  // prevStep=()=>{
  //     console.log('hello');
  //     if(this.state.current===2){
  //       this.setState({current: this.state.current-1})
  //     }
  //     else if(this.state.current===3){
  //       this.setState({current: this.state.current-1})
  //     }
  // }

  // submit=()=>{
  //     console.log('hello');
  //     if(this.state.current===3){
  //       const ERROR = [...this.state.stepThreeData.validation.error]
  //       const ERROR_MSG=[...this.state.stepThreeData.validation.errorMsg]
  //       this.setState({
  //         stepThreeError:ERROR,
  //         stepThreeErrorMsg:ERROR_MSG
  //       })
  //       if(!this.state.stepThreeData.validation.error.includes(true)){
  //         this.setState({current: this.state.current+1})
  //       }
  //     }
  // }

  handleRegisterForm =async()=>{
    if(this.state.current===1){
      const ERROR = [...this.state.startupFormData.validation.error]
      const ERROR_MSG=[...this.state.startupFormData.validation.errorMsg]
      this.setState({
        startupFormError:ERROR,
        startupFormErrorMsg:ERROR_MSG
      })
      if(!this.state.startupFormData.validation.error.includes(true)){
        this.setState({loading:true})
      const myHeaders = new Headers();
      // console.log('step1', this.state.startupFormData.nom);
      // console.log('step2', this.state.stepTwoData1.sActvt);
      myHeaders.append("Content-Type", "application/json");
      // const stepOne = this.state.stepOneData
      // const stepTwo = this.state.stepTwoData
      const startupForm = this.state.startupFormData
      const cv = startupForm.cv
      const document = startupForm.docs
      // const cvName = cv.name
      // const documentName = document.name

      console.log("im here", cv, document, cv.name, document.name);

      let formdata =new FormData()
      formdata.append('nom',startupForm.nom)
      formdata.append('prenom',startupForm.prenom)
      formdata.append('genre',startupForm.genre)
      formdata.append('email',startupForm.email)
      formdata.append('tel',startupForm.tel)
      formdata.append('position',startupForm.pEse)
      formdata.append('nom_startup',startupForm.nStrup)
      formdata.append('date_creation',startupForm.dCEse)
      formdata.append('definition_projet',startupForm.fdPSPse)
      formdata.append('secteur_activite',startupForm.sActvt)
      formdata.append('description_projet',startupForm.dDP)
      formdata.append('startup_act',startupForm.lSA)
      formdata.append('stade_developpement',startupForm.sDPrjet)
      formdata.append('capital',startupForm.cDnrs)
      formdata.append('besoin_financement',startupForm.bFDnrs)
      formdata.append('fonds',startupForm.fSde)
      formdata.append('chiffre_affaire',startupForm.cAre)
      formdata.append('nombre_equipe',startupForm.pEqpe)
      formdata.append('nombre_femme',startupForm.fEqpe)
      formdata.append('impact_projet',startupForm.iPSciete)
      // formdata.append('impact_positif',startupForm.iPtive)
      formdata.append('creativite_projet',startupForm.cPrjet)
      formdata.append('cause_rejoint',startupForm.dBA)
      formdata.append('prog_accompagnement',startupForm.pAccgnment)
      formdata.append('remarque_commentaire',startupForm.rCEvntuels)
      formdata.append('cv',cv)
      formdata.append('document',document)
      
     
      const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata
      };
     await fetch(apiURL+'add',requestOptions)
      .then(response => {
        if(response.status == 201)
        {
          this.setState({loading:false, current:2})
        console.log("res",response);
          response.json().then(result =>{
            
          })
        }
      })
     
      }
    }
     
    }

  btnSubmit = () => {
    if(this.state.current == 0){
      return (
        <div>
          <Row>
            <Col className="btn-step-zero-section" xs={24} sm={24} md={24} lg={24} xl={24} className="az">
              <div onClick={this.nextStep} className={this.props.lang == "fr"?this.props.lang+"_btn-next-step-zero":this.props.lang+"_btn-next-step-zero"}>
              <Row className="content-btn-step-zero"><Col xs={20} sm={20} md={20} lg={20} xl={20}>{getLanguage(this.props).submitProject}
</Col><Col xs={4} sm={4} md={4} lg={4} xl={4}><img className="image-btn-step-zero" src={flechCercle} width={"100%"} /></Col></Row> 
              </div>
            </Col>
          </Row>    
        </div>
        )
      }
  else
  {
    return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={21} xl={21} className="az">
            <Button disabled={this.state.loading==true} onClick={this.handleRegisterForm} type="link"  htmlType="submit" className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
              {getLanguage(this.props).submit} <RightOutlined />
            </Button>
          </Col>
      </Row>    
    </div>
    )
  }
  }
  
    render() {
      console.log("hereee",this.state);
        return (
            <div className={this.props.lang == "fr"?this.props.lang+"_startup-form-container":this.props.lang+"_startup-form-container"}>
                <img src={startupFormBackground} className={this.props.lang == "fr"?this.props.lang+"_startup-form-fixed-background-image":this.props.lang+"_startup-form-fixed-background-image"} />
                <span className={this.props.lang == "fr"?this.props.lang+"_fixed-background-image-text":this.props.lang+"_fixed-background-image-text"}>{getLanguage(this.props).submit} <br />{getLanguage(this.props).project}</span>
                <Link to="/">
                <div className={this.props.lang == "fr"?this.props.lang+"_fixed-startup-vector":this.props.lang+"_fixed-startup-vector"}>
                  <img src={arrowLeft} className={this.props.lang == "fr"?this.props.lang+"_fixed-left-arrow":this.props.lang+"_fixed-left-arrow"} />
                  <h3 className={this.props.lang == "fr"?this.props.lang+"_fixed-oriented-text":this.props.lang+"_fixed-oriented-text"}>{getLanguage(this.props).return}</h3>
                </div>
                </Link>
                <div className={this.props.lang == "fr"?this.props.lang+"_startup-form-background":this.props.lang+"_startup-form-background"}>


                  {this.state.current == 0 ? <StepZeroStartup />:null}
                  {this.state.current == 1? <StartupForm onChangeStartupFormData={this.onChangeStartupFormData}
                  startupFormData={this.state.startupFormData}
                  startupFormError={this.state.startupFormError}
                  startupFormErrorMsg={this.state.startupFormErrorMsg} /> :null}
                  {this.state.loading == true ? <FullPageLoader  />: null}
                  {this.state.current == 2 ? <StepsThxStarup /> : null}
                  {this.state.current !== 2 ?this.btnSubmit():null}
                  
                  {/* {this.state.current == 2? <StepTwo onChangeStepTwoData={this.onChangeStepTwoData}
                  stepTwoData={this.state.stepTwoData}
                  stepTwoError={this.state.stepTwoError}
                  stepTwoErrorMsg={this.state.stepTwoErrorMsg} /> :null}

                  {this.state.current == 3? <StepThree onChangeStepThreeData={this.onChangeStepThreeData}
                  stepThreeData={this.state.stepThreeData}
                  stepThreeError={this.state.stepThreeError}
                  stepThreeErrorMsg={this.state.stepThreeErrorMsg} /> :null}
                  {this.state.loading == true ? <FullPageLoader  />: null}
                  {this.state.current == 4 ? <StepsThxStarup /> : null}
                  {this.state.current !==4?this.btnPagination():null} */}

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch: (action) => {
        dispatch(action);
      },
    };
  };
  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      lang:state.auth.lang
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Startup);