import React, { Component } from "react";
import { MenuOutlined} from '@ant-design/icons';
import { Menu, Button, Dropdown, Drawer, Popover, Row } from 'antd';
import classnames from "classnames";
import ukFlag from '../assets/img/uk-flag.png'
import frFlag from '../assets/img/fr-flag.png'
import { connect } from "react-redux"
import {Link, withRouter} from 'react-router-dom'
import getLanguage from "../utilFunction/utilFunction"
import logoutIcon from '../assets/img/member-space-icon.png'
import femaleAvatar from '../assets/img/female-avatar.svg'
import { apiURL } from '../Config/Config';
import PropTypes from 'prop-types';


const { SubMenu } = Menu;

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'fr',
      visible: false,
      TopBarvisible: true,
      prevScrollpos: window.pageYOffset,
      data: ['']
    };
  }
  
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.getData();
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getData=async()=>{
    const requestOptions = {
        method: 'GET',
    };
    const res =  await fetch(apiURL+"api/verifauthuser/"+this.props.auth.username, requestOptions);
    if(res.status == 200){
        const resJson = await res.json();
        this.setState({
          data: resJson
        })
    }            
    }

  handleScroll = () => {
  const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const topBarvisible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      topBarvisible
    });
};

  showDrawer = () => {
    this.setState({visible:true})
  };
  onClose = () => {
    this.setState({visible:false})
  };

  menuMobile = (location) => {
  return (
    <>
      <Button type="link" className={this.props.lang == "fr"?this.props.lang+"_topBar-menu mobileVisible":this.props.lang+"_topBar-menu mobileVisible"} onClick={this.showDrawer} icon={<MenuOutlined />} />
      
      <Drawer
        title="Menu"
        placement="left"
        closable={false}
        onClose={this.onClose}
        visible={this.state.visible}
      >
        <Menu mode="inline" onClick={this.onClose} selectedKeys={[location.pathname]}>
      
        <SubMenu key="/" title={getLanguage(this.props).home}>
          <Menu.ItemGroup className="_dropdown-menu" >
            <Menu.Item key="/aboutus"><Link to="/aboutus">{getLanguage(this.props).aboutUs}</Link></Menu.Item>
            <Menu.Item key="/programs"><Link to="/programs">{getLanguage(this.props).program}</Link></Menu.Item>
            <Menu.Item key="/boards"><Link to="/boards">{getLanguage(this.props).board}</Link></Menu.Item>
            <Menu.Item key="/partners"><Link to="/partners">{getLanguage(this.props).ourPartner}</Link></Menu.Item>
            <Menu.Item key="/damya-numbers"><Link to="/damya-numbers">{getLanguage(this.props).damyaInNumbers}</Link> </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <Menu.Item key="/actualities">
          <Link to="/actualities">
            {getLanguage(this.props).actuality}
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="/team">
          <Link to="/coming-soon">{getLanguage(this.props).ourTeam}</Link>
        </Menu.Item> */}
        <Menu.Item key="/investor">
          <Link to="/investor">{getLanguage(this.props).DevenirDamyaAngel}</Link>
        </Menu.Item>
        <Menu.Item key="/startup">
          <Link to="/startup">{getLanguage(this.props).raiseFunds}</Link>
        </Menu.Item>
        <Menu.Item key="/damya-numbers">
          <Link to="/damya-numbers">{getLanguage(this.props).damyaInNumbers}</Link>
        </Menu.Item>
        <Menu.Item key="/contact">
          <Link to="/contact">{getLanguage(this.props).contact}</Link>
        </Menu.Item>
      </Menu>
      </Drawer>
    </>
  );
};

  langSelect=()=>{
   return (
  <Menu onClick={(key)=>this.handleLangClick(key)} >
      <Menu.Item key="fr">
        <img src={frFlag}/> Français
      </Menu.Item>
      <Menu.Item key="en">
        <img src={ukFlag}/> Anglais
      </Menu.Item>
  </Menu>
);
  }

  handleLangClick = (e) => {
    console.log('click', e.key);
    this.setState({ lang: e.key });
    const action = {type:'SET_CURRENT_USER', lang:e.key, token:null}
    this.props.dispatch(action);
  } 
  returnLang =()=>{
    if(this.props.auth.lang == 'fr'){
      return <div><img src={frFlag} /> Francais </div>
    }
    if(this.props.auth.lang =='en'){
      return <div><img src={ukFlag} /> English  </div>
    }
  }
  logout = () => {
    const action = {type:'LOGOUT', token:null, username:null, isLogIn:null, isAdmin:null}
    this.props.dispatch(action);
    setTimeout(() => {
      window.location='/'
    }, 200);
    

  }
  returHome =()=>{
    window.location='/member-space'
  } 

  content = (data) => {
    
    if(data && this.props.auth.isAdmin == false){
      console.log(data,"mytopdatabar");
      return(
      <div className="member-space-logout">
        <center>        
        <Link to="/member-space">
          <img src={femaleAvatar} className="member-space-logout-avatar"/>
          </Link>
          <span className="member-space-logout-name">{data.nom} {data.prenom}</span><br />
          <span className="member-space-logout-email">{this.props.auth.username}</span><br />
          <Row>
          <Button onClick={this.returHome} className="member-space-logout-btn">Espace membre</Button>
          </Row>
          <Row>
          <Button onClick={this.logout} className="member-space-logout-btn">Log out</Button>
          </Row>
          
          
        </center>
      </div>
      );
    }else{
      return null
    }
    }

  render() {

    const { location } = this.props;
    return (
      <div>
        <div className={classnames("topBar", {
          "topBar-hidden": this.state.topBarvisible == false
        })}>
            {this.menuMobile(location)}
            <div className="mobileHidden">                            
              <Link to="/contact"><span className={this.props.lang == "fr"?this.props.lang+"_topBar-menu":this.props.lang+"_topBar-menu"}>{getLanguage(this.props).contact}</span></Link>
            </div>          
            <div className="topbar-flex">
            <Dropdown overlay={this.langSelect()}>
              <Button className={this.props.lang == "fr"?this.props.lang+"_lang-dropdown":this.props.lang+"_lang-dropdown"}>

                <span className={this.props.lang == "fr"?this.props.lang+"_topBar-menu":this.props.lang+"_topBar-menu"}>{this.returnLang()}</span>
              </Button>
            </Dropdown>
              {this.props.auth && this.props.auth.token && this.props.auth.isAdmin !== true ? <Popover placement="bottomLeft" content={this.content(this.state.data)} trigger="click">
              {/* <Link to="#"><Button className={this.props.lang == "fr"?this.props.lang+"_lang-dropdown":this.props.lang+"_lang-dropdown"}>{this.menuUser}{this.props.auth.username} <DownOutlined /></Button></Link>  */}
                <img src={logoutIcon} className="topBar-icon" />
              </Popover>  
              :<Link to="/login"><span className={this.props.lang == "fr"?this.props.lang+"_topBar-link":this.props.lang+"_topBar-link"}>{getLanguage(this.props).memberSpace}</span></Link>}
          </div>
        </div>
        
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    lang:state.auth.lang
  };
};
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(TopBar))