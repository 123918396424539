import React, { Component } from "react";
import { Row, Col, Input, Button, notification } from 'antd';
import { connect } from "react-redux"
import { Link } from 'react-router-dom'
import getLanguage from "../utilFunction/utilFunction"
import logoFooter from '../assets/img/logo-footer.png'
import iconFacebook from '../assets/img/facebook.png'
import iconTwitter from '../assets/img/twitter-icon.png'
import iconInstagram from '../assets/img/instagram-icon.png'
import linkedin from '../assets/img/linkedin.png'
import isEmail from "validator/lib/isEmail";
import { apiURL } from "../Config/Config";



class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
         email:''
        };
      }
    sendSubscription=async()=>{
        console.log("hereeeeee");
        if(this.state.email == ''){
            this.setState({errorMsg:'required'})
        }
        if(!isEmail(this.state.email)){
            this.setState({errorMsg:'verifier que vous avez saisir une adresse email valide'})
        }else{
            let formdata =new FormData()
            formdata.append('email',this.state.email)
            
    
            const requestOptions = {
              method: 'POST',
              body: formdata
            };
          
              const data = await fetch(apiURL+'addSubscription',requestOptions);
              const dataJson = await data.json();
              console.log("here2",dataJson);
              if(data.status == 201){ 
                notification.success({
                    message: `success `,
                    description:
                    'success',
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
                
              }
            
            }
        }
        redirectFacebook =()=>{
            window.location="https://www.facebook.com/DAMYATWBAN"
        }
        redirectLinkedIn=()=>{
            window.location ='https://www.linkedin.com/company/damya-women-business-angel-network-tunisia/'
        }
    render() {
        console.log("email",this.state.errorMsg);
        return (
            <div>
                {/* footer section */}
                <div className="footer-section-relative"> 
                    {/* <img src={logoFooter} className={this.props.lang == "fr"?this.props.lang+"_logo-footer":this.props.lang+"_logo-footer"} /> */}
                    <Row justify="space-between" className="footer-padding">
                        {/* <Col xs={24} sm={24} md={12} lg={5} xl={5}>
                            <div className={this.props.lang == "fr"?this.props.lang+"_footer-item-title":this.props.lang+"_footer-item-title"}>{getLanguage(this.props).followUs}</div>
                            <div className="social-icon-inline"><Link to="#"> <img src={iconFacebook} className="social-icon"/></Link><Link to="#"> <img src={iconTwitter} className="social-icon"/></Link> <Link to="#"> <img src={iconInstagram} className="social-icon"/></Link></div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={5} xl={5}>
                            <div className={this.props.lang == "fr"?this.props.lang+"_footer-item-title":this.props.lang+"_footer-item-title"}>{getLanguage(this.props).navigation}</div>
                            <div className={this.props.lang == "fr"?this.props.lang+"_footer-item-content":this.props.lang+"_footer-item-content"}>
                                <Link to="#">Damya</Link>
                                <Link to="/aboutus">{getLanguage(this.props).aboutUs}</Link>
                                <Link to="/programs">{getLanguage(this.props).program}</Link>
                                <Link to="/boards">{getLanguage(this.props).board}</Link>
                                <Link to="/partners">{getLanguage(this.props).ourPartner}</Link>
                                <Link to="/actualities">{getLanguage(this.props).news}</Link>
                                <Link to="/coming-soon">{getLanguage(this.props).ourTeam}</Link>                                
                                <Link to="/investor">{getLanguage(this.props).DevenirDamyaAngel}</Link>
                                <Link to="/startup">{getLanguage(this.props).raiseFunds}</Link>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={5} xl={5}>
                            <div className={this.props.lang == "fr"?this.props.lang+"_footer-item-title":this.props.lang+"_footer-item-title"}>{getLanguage(this.props).contactInfo}</div>
                            <div className={this.props.lang == "fr"?this.props.lang+"_footer-item-content-height":this.props.lang+"_footer-item-content-height"}>
                                <p>+216 24 713 711</p>
                                <p>damyatwban@gmail.com</p>
                                <p>{getLanguage(this.props).schedule}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={9} xl={9}>
                            <div className={this.props.lang == "fr"?this.props.lang+"_footer-item-title":this.props.lang+"_footer-item-title"}>{getLanguage(this.props).newsletter}</div>
                            <div className={this.props.lang == "fr"?this.props.lang+"_footer-item-content-height":this.props.lang+"_footer-item-content"}>
                                <p>{getLanguage(this.props).subscriptionText}</p>
                                <div className="footer-subscription-block">
                                    <Input className={this.props.lang == "fr"?this.props.lang+"_footer-input-subscription":this.props.lang+"_footer-input-subscription"}
                                        placeholder={getLanguage(this.props).exampleEmail}
                                        onSearch={onSearch}
                                    />
                                    <Button className="btn-footer-subscription">
                                        {getLanguage(this.props).inscription}
                                    </Button>
                                </div>
                            </div>
                        </Col> */}
                        <Col className="footer-col-padding" xs={24} sm={24} md={12} lg={6} xl={6}>
                            <div className="footer-item-title">{getLanguage(this.props).contactfooter}</div>
                            <div className="footer-item-content-height">
                                <p>(+216) &nbsp;&nbsp;24 713 711</p>
                                <p>{getLanguage(this.props).schedule}</p>                                
                                <p>3 Rue Kawakibi, Tunis 1002</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={9} xl={9}>
                        <center>
                            <img src={logoFooter} className="logo-footer" />
                        
                            <div className="social-icon-inline">
                                <div className="footer-item-title-follow">{getLanguage(this.props).followUs}</div>
                                <div className=""><span onClick={this.redirectFacebook} style={{cursor:"pointer"}}><img  src={iconFacebook} className="social-icon-facebook"/></span><span onClick={this.redirectLinkedIn} style={{cursor:"pointer"}}> <img src={linkedin} className="social-icon"/></span></div>                                
                            </div>
                            <Link to="/contact">
                                <p className="footer-email">Network@damyaangels.org.tn</p>
                            </Link>
                        </center>
                        </Col>
                        <Col className="footer-col-padding" xs={24} sm={24} md={12} lg={9} xl={9}>
                            <div className="footer-item-title">{getLanguage(this.props).newsletter}</div>
                            <div className="footer-item-content-newsletter-height">
                                <p>{getLanguage(this.props).subscriptionText}</p>
                                <div className="footer-subscription-block">
                                    
                                    <Input className="footer-input-subscription"
                                        placeholder={getLanguage(this.props).exampleEmail}
                                        onChange={(e)=>this.setState({email:e.target.value})}
                                    />
                                    
                                    
                                    <Button onClick={this.sendSubscription} className="btn-footer-subscription">
                                        {getLanguage(this.props).inscription}
                                    </Button>
                                </div>
                                <Row>
                                    {this.state.errorMsg&&<div style={{color:'red'}}>{this.state.errorMsg}</div>} 
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch: (action) => {
        dispatch(action);
      },
    };
  };
  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      lang:state.auth.lang
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Footer);