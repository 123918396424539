import React from 'react'
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import femaleAvatar from '../../assets/img/female-avatar.svg'
import { connect } from "react-redux"

const TheHeaderDropdown = (props) => {
  const logout = () => {
    const action = {type:'LOGOUT_ADMIN', token:null, username:null, isLogIn:null, isAdmin:null}
    props.dispatch(action);
    setTimeout(() => {
      window.location='/'
    }, 100);
      

  }
  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={femaleAvatar}
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Account</strong>
        </CDropdownItem>
        <CDropdownItem onClick={logout}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          LogOut
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}


const mapDispatchToProps = (dispatch) => {
  return {
      dispatch: (action) => {
      dispatch(action);
      },
  };
};
const mapStateToProps = (state) => {
  return {
      auth: state.auth,
      };
  };
    
export default connect(mapStateToProps, mapDispatchToProps)(TheHeaderDropdown);
