import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload, Switch} from 'antd';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"


class AddFolder extends Component {

    constructor(props) {
    super(props);
    this.state = {
      userLanguage:'',
      loading:false,
      folderData:{title_fr:'',title_en:'',
         validation:{error:[true,true],errorMsg:
         ['*required',         
         '*required',
         ]}},
          folderError:[true,true],
          folderErrorMsg:['',''],
        };
    }
    
    componentDidMount(){
        window.scrollTo(0, 0)
    }

    onChangeFolder=(value,key,index)=>{
    let aux ={...this.state.folderData}
      aux[key]=value
      if(key=="title_fr"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="title_en"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }      
      
      this.setState({folderData:aux})
  }

  handleFolderForm =async()=>{
      const ERROR = [...this.state.folderData.validation.error]
      const ERROR_MSG=[...this.state.folderData.validation.errorMsg]
      this.setState({
        folderError:ERROR,
        folderErrorMsg:ERROR_MSG
      })
      if(!this.state.folderData.validation.error.includes(true)){
      this.setState({loading:true})
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const folders = this.state.folderData
      let formdata =new FormData()
      formdata.append('title_fr',folders.title_fr)
      formdata.append('title_en',folders.title_en)      
      
      
      const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata
      };
      const data = await fetch(apiURL+'addFolder',requestOptions);         
      if(data.status == 201){
        console.log("success");
        this.setState({loading:false})
        window.location='/admin/folders'
      }
    }
  }

    render() {
     
        return (
            <>
            
                <h1 id='title'>Ajouter un dossier</h1>
                
                <Row align="center">
                        <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                    <Form
                    name="basic"
                    layout="vertical"
                    >                                        
                    <Form.Item
                        label="titre en francais"
                        name="title_fr"
                        >
                        <Input onChange={(e)=>this.onChangeFolder(e.target.value,'title_fr',0)} value={this.state.folderData.title_fr} />
                        {this.state.folderError[0]&&<div style={{color:'red'}}>{this.state.folderErrorMsg[0]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="titre en anglais"
                        name="title_en"
                        >
                        <Input onChange={(e)=>this.onChangeFolder(e.target.value,'title_en',1)} value={this.state.folderData.title_en} />
                        {this.state.folderError[1]&&<div style={{color:'red'}}>{this.state.folderErrorMsg[1]}</div>} 
                    </Form.Item>                                 
                    <Button disabled={this.state.loading==true} onClick={this.handleFolderForm}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                        {getLanguage(this.props).submit} <RightOutlined />
                    </Button>
                    </Form>
                    </Col>
                    </Row>
                    {this.state.loading == true ? <FullPageLoader  />: null}
            </>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(AddFolder);