import React, { Component } from "react";
import { Row, Col, Input, Form, Select, DatePicker, Space, Upload, Button  } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { connect } from "react-redux"
import getLanguage from "../../utilFunction/utilFunction";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


const { Option } = Select;
class StartupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: false,
      value2:[],
      value:[]
    };
  }

componentDidMount(){
  window.scrollTo(0, 0)
}

onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
  console.log('Failed:', this.props);

};

activitySector =()=>{
const children = [];
children.push(<Option key={"E-commerce"}>{getLanguage(this.props).eCommerce}</Option>);
children.push(<Option key={"Technologie"}>{getLanguage(this.props).technology}</Option>);
children.push(<Option key={"Industrie"}>{getLanguage(this.props).industry}</Option>);
children.push(<Option key={"Agriculture"}>{getLanguage(this.props).agriculture}</Option>);
children.push(<Option key={"Communication"}>{getLanguage(this.props).communication}</Option>);
children.push(<Option key={"Service"}>{getLanguage(this.props).service}</Option>);

  const value2 = this.state.value2
  const selectProps2 = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    value2,
    children,
    onChange: (newValue) => {
      this.props.onChangeStartupFormData(newValue,'sActvt',9)
      this.setState({value2:newValue})
    },
    placeholder: getLanguage(this.props).selectIem,
    maxTagCount: 'responsive',
  };
  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
      <Select {...selectProps2} />
    </Space>
  );
};


StadeDev =()=>{
const children = [];
children.push(<Option key={"Analyse du marché et des besoins des clients"}>{getLanguage(this.props).marketCustomerNeeds}</Option>);
children.push(<Option key={"Conception du produit ou du service"}>{getLanguage(this.props).productServiceDesign}</Option>);
children.push(<Option key={"Phase de test sur les premiers clients"}>{getLanguage(this.props).testPhase}</Option>);
children.push(<Option key={"Commercialisation"}>{getLanguage(this.props).startupMarketing}</Option>);
children.push(<Option key={"Communication"}>{getLanguage(this.props).communication}</Option>);
children.push(<Option key={"Développement de l'activité (phase de croissance)"}>{getLanguage(this.props).businessDevelopment}</Option>);

  const value = this.state.value
  const selectProps2 = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    value,
    children,
    onChange: (newValue) => {
      this.props.onChangeStartupFormData(newValue,'sDPrjet',12)
      this.setState({value:newValue})
    },
    placeholder: getLanguage(this.props).selectIem,
    maxTagCount: 'responsive',
  };
  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
      <Select {...selectProps2} />
    </Space>
  );
};

    render() {
      const doc = {
          name: 'docs',            
          headers: {
            authorization: 'authorization-text',
          },
          onChange(info) {
            this.setState({document:info.originFileOb})
            console.log("info",info);
          
          },
        };

        const cv = {
          name: 'cv',            
          headers: {
            authorization: 'authorization-text',
          },
          onChange(info) {
            this.setState({document:info.originFileOb})
            console.log("info",info);
          
          },
        };

        return (
            <div>
            <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={21} xl={21}> 
            <h3 className={this.props.lang == "fr"?this.props.lang+"_startup-form-title":this.props.lang+"_startup-form-title"}>{getLanguage(this.props).formStep1}</h3>
            <p className={this.props.lang == "fr"?this.props.lang+"_startup-form-description-content":this.props.lang+"_startup-form-description-content"}>{getLanguage(this.props).startupFromWarning}</p>
         
          <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >    
    <div className="inline-inputs">
          <Form.Item
            label={getLanguage(this.props).lastName}
            name="nom"
            style={{ width: '33%' }}
          >
          <Input onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'nom',0)} value={this.props.startupFormData.nom} />
           {this.props.startupFormError[0]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[0]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).firstName}
            name="prenom"
            style={{ width: '33%' }}
          >
          <Input onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'prenom',1)} value={this.props.startupFormData.prenom} />
           {this.props.startupFormError[1]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[1]}</div>}
          </Form.Item>


          <Form.Item
            label={getLanguage(this.props).gender}
            name="genre"
            style={{ width: '33%' }}
          >
          <Select defaultValue="choix" onChange={(e)=>this.props.onChangeStartupFormData(e,'genre',2)}>
              <Option value="Homme">{getLanguage(this.props).male}</Option>
              <Option value="Femme">{getLanguage(this.props).female}</Option>
              
            </Select>
            {this.props.startupFormError[2]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[2]}</div>}
          </Form.Item>
          </div>
          <div className="inline-inputs">
          <Form.Item
            label={getLanguage(this.props).email}
            name="email"
            style={{ width: '50%' }}
          >
          <Input onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'email',3)} value={this.props.startupFormData.email} />
           {this.props.startupFormError[3]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[3]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).phone}
            name="tel"
            style={{ width: '50%' }}          
          >          
          <PhoneInput style={{ width: '100%' }} defaultCountry="TN" onChange={(e)=>this.props.onChangeStartupFormData(e,'tel',4)} value={this.props.startupFormData.tel} />          
           {this.props.startupFormError[4]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[4]}</div>}
          </Form.Item>
          </div>
          <div className="inline-inputs mobile-block">
          <Form.Item
            label={getLanguage(this.props).position}
            name="pEse"
            style={{ width: '100%' }}
          >
          <Select defaultValue="choix" onChange={(e)=>this.props.onChangeStartupFormData(e,'pEse',5)}>
              <Option value="Fondateur/ co-Fondateur">{getLanguage(this.props).founder}</Option>
              <Option value="Membre de l'équipe">{getLanguage(this.props).teamMember}</Option>
            </Select>
           {this.props.startupFormError[5]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[5]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).startupName}
            name="nStrup"
            style={{ width: '100%' }}
          >
          <Input onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'nStrup',6)} value={this.props.startupFormData.cFon} />
           {this.props.startupFormError[6]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[6]}</div>}
          </Form.Item>
          </div>
            <Form.Item
              label={getLanguage(this.props).businessCreation}
              name="dCEse" 
              style={{ width: '100%' }}
            >
              <DatePicker
              onChange={(e)=>this.props.onChangeStartupFormData(e.format('YYYY-MM-DD'),'dCEse',7)}
              value={this.props.startupFormData.eOAlle}
              style={{ width: '100%', borderRadius:"6px", height:"30px" }}
              dateRender={current => {
                const style = {};
                if (current.date() === 1) {
                  style.border = '1px solid #1890ff';
                  style.borderRadius = '50%';
                }
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }}
            />
              {this.props.startupFormError[7]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[7]}</div>}
            </Form.Item>

            <Form.Item
              label={getLanguage(this.props).projectDefinition}
              name="dPSPse"
            >
              <Input style={{ width: '100%' }} onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'dPSPse',8)} value={this.props.startupFormData.eOAlle} />
              {this.props.startupFormError[8]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[8]}</div>}
            </Form.Item>
            <Form.Item
              label={getLanguage(this.props).activitySector}
              name="sActvt"
            >              
            {this.activitySector()}
            {this.props.startupFormError[9]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[9]}</div>} 
            </Form.Item>
            <Form.Item
            label={getLanguage(this.props).projectDescription}
            name="dDP"
            >
            <Input style={{ width: '100%' }} onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'dDP',10)} value={this.props.startupFormData.eInt} />
           {this.props.startupFormError[10]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[10]}</div>} 
            </Form.Item>
            <Form.Item
              label={getLanguage(this.props).startupAct}
              name="lSA"
            >
            <Select defaultValue="choix" onChange={(e)=>this.props.onChangeStartupFormData(e,'lSA',11)}>
              <Option value="Oui">{getLanguage(this.props).yes}</Option>
              <Option value="Non">{getLanguage(this.props).no}</Option>
            </Select>
           {this.props.startupFormError[11]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[11]}</div>} 
          </Form.Item>

          <Form.Item
            label={getLanguage(this.props).projectStage}
            name="sDPrjet"
          >
            {this.StadeDev()}
           {this.props.startupFormError[12]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[12]}</div>} 
          </Form.Item>
          <div className="inline-inputs mobile-block">
          <Form.Item
            label={getLanguage(this.props).capital}
            name="cDnrs"
            style={{ width: '50%' }}
          >
          <Input onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'cDnrs',13)} value={this.props.startupFormData.cDirs} />
           {this.props.startupFormError[13]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[13]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).funding}
            name="bFDnrs"
            style={{ width: '50%' }}
          >
          <Input onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'bFDnrs',14)} value={this.props.startupFormData.bFDirs} />
           {this.props.startupFormError[14]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[14]}</div>}
          </Form.Item>
          </div>
          <div className="inline-inputs mobile-block">
          <Form.Item
            label={getLanguage(this.props).stageFunds}
            name="fSde"
            style={{ width: '50%' }}
          >
          <Select defaultValue="choix" onChange={(e)=>this.props.onChangeStartupFormData(e,'fSde',15)}>
              <Option value="Oui">{getLanguage(this.props).yes}</Option>
              <Option value="Non">{getLanguage(this.props).no}</Option>
            </Select>
            {this.props.startupFormError[15]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[15]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).turnover}
            name="cAre"
            style={{ width: '50%' }}
          >
          <Input onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'cAre',16)} value={this.props.startupFormData.rDBA} />
            {this.props.startupFormError[16]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[16]}</div>} 
          </Form.Item>
          </div>
          <div className="inline-inputs mobile-block">
          <Form.Item
          label={getLanguage(this.props).membersNumber}
          name="pEqpe"
          style={{ width: '50%' }}
          >
          <Input onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'pEqpe',17)}  value={this.props.startupFormData.pEqpe} />
           {this.props.startupFormError[17]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[17]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).femalesNumber}
            name="fEqpe"
          >
          <Input style={{ width: '100%','justify-content': 'end' }} onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'fEqpe',18)}  value={this.props.startupFormData.fEqpe} />
            {this.props.startupFormError[18]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[18]}</div>} 
          </Form.Item>
          </div>
          <Form.Item
            label={getLanguage(this.props).impact}
            name="iPSciete"
          >
          <Select style={{ width: '100%' }} defaultValue="choix" onChange={(e)=>this.props.onChangeStartupFormData(e,'iPSciete',19)}>
              <Option value="Oui">{getLanguage(this.props).yes}</Option>
              <Option value="Non">{getLanguage(this.props).no}</Option>
            </Select>
            {this.props.startupFormError[19]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[19]}</div>} 
          </Form.Item>          
          <div className="inline-inputs mobile-block">          
          <Form.Item
            label={getLanguage(this.props).projectCreativity}
            name="cPrjet"
            style={{ width: '100%' }}
          >
          <Input style={{ width: '100%' }} onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'cPrjet',20)} value={this.props.startupFormData.cPrjet} />
            {this.props.startupFormError[20]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[20]}</div>} 
          </Form.Item>
          </div>
          <Form.Item
            label={getLanguage(this.props).joinDamyaBusinessAngels}
            name="dBA"
          >
          <Input style={{ width: '100%' }} onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'dBA',21)} value={this.props.startupFormData.dBA} />
            {this.props.startupFormError[21]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[21]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).supportProgram}
            name="pAccgnment"
          >
          <Select style={{ width: '100%' }} defaultValue="choix" onChange={(e)=>this.props.onChangeStartupFormData(e,'pAccgnment',22)}>
              <Option value="Oui">{getLanguage(this.props).yes}</Option>
              <Option value="Non">{getLanguage(this.props).no}</Option>
            </Select>
            {this.props.startupFormError[22]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[22]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).notesComments}
            name="rCEvntuels"
          >
          <Input.TextArea style={{ width: '100%', height: '100px' }} onChange={(e)=>this.props.onChangeStartupFormData(e.target.value,'rCEvntuels',23)} value={this.props.startupFormData.rCEvntuels} />
            {this.props.startupFormError[23]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[23]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).suggestCVDownload}
            name="cv" 
          >
        <Upload maxCount={1} accept=".doc,.docx,application/msword"  {...cv} onChange={(info)=>this.props.onChangeStartupFormData(info.fileList[0].originFileObj,'cv',24)} value={this.props.startupFormData.cv}>
          <Button icon={<UploadOutlined />}>{getLanguage(this.props).clickDownload}</Button>
        </Upload>
        <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).requiredWordFile}</span>
        {this.props.startupFormError[24]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[24]}</div>} 
        </Form.Item>
        <Form.Item
          label={getLanguage(this.props).suggestDocDownload}
          name="docs"
        >
          <Upload maxCount={1} accept=".doc,.docx,application/msword" {...doc} onChange={(info)=>this.props.onChangeStartupFormData(info.fileList[0].originFileObj,'docs',25)} value={this.props.startupFormData.docs}>
            <Button icon={<UploadOutlined />}>{getLanguage(this.props).clickDownload}</Button>
          </Upload>
          <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).requiredWordFile}</span>
          {this.props.startupFormError[25]&&<div style={{color:'red'}}>{this.props.startupFormErrorMsg[25]}</div>} 
          </Form.Item>
        </Form>        
              </Col>
            </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch: (action) => {
        dispatch(action);
      },
    };
  };
  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      lang:state.auth.lang
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(StartupForm);