import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload} from 'antd';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"


class AddAngel extends Component {

    constructor(props) {
    super(props);
    this.state = {
      userLanguage:'',
      loading:false,
      memberData:{memberImg:'',nom:'',poste:'',posteEn:'',email:'',linkedin:'',description:'',descriptionEn:'',
         validation:{error:[true,true,true,true,true,true,true,true],errorMsg:
         ['*required',
         '*required',
         '*required',
         '*required',
         '*required',
         '*required',
         '*required',
         '*required',]}},
          memberError:[true,true,true,true,true,true,true,true],
          memberErrorMsg:['','','','','','','',''],
        };
    }
    
    componentDidMount(){
        window.scrollTo(0, 0)
    }

    onChangeMember=(value,key,index)=>{
    let aux ={...this.state.memberData}
      aux[key]=value
      if(key=="memberImg"){
        if(!value){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="nom"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="poste"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="posteEn"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="email"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="linkedin"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="description"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="descriptionEn"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      const action ={type:"SET_MEMBER_DATA", value:aux}
      this.props.dispatch(action);
      console.log(aux);
      this.setState({memberData:aux})
  }

  handleMemberForm =async()=>{
      const ERROR = [...this.state.memberData.validation.error]
      const ERROR_MSG=[...this.state.memberData.validation.errorMsg]
      this.setState({
        memberError:ERROR,
        memberErrorMsg:ERROR_MSG
      })
      if(!this.state.memberData.validation.error.includes(true)){
      this.setState({loading:true})
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const member = this.state.memberData
      let formdata =new FormData()
      formdata.append('image',member.memberImg)
      formdata.append('nom',member.nom)
      formdata.append('poste',member.poste)
      formdata.append('posteEn',member.posteEn)
      formdata.append('email',member.email)
      formdata.append('linkedin',member.linkedin)
      formdata.append('description',member.description)
      formdata.append('descriptionEn',member.descriptionEn)
      
      const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata
      };
      const data = await fetch(apiURL+'addAngel',requestOptions);
      console.log("here",data);
      const dataJson = await data.json();
      console.log("here2",dataJson);
      if(data.status == 201){
        console.log("success");
        this.setState({loading:false})
        window.location='/admin/angels'
      }
    }
  }

    render() {
      const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };

        return (
            <>
            
                <h1 id='title'>Ajouter un Angel</h1>
                
                <Row align="center">
                        <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                    <Form
                    name="basic"
                    layout="vertical"
                    >
                    
                    <Form.Item
                        label="Filename"
                        name="memberImg"
                        >
                            <ImgCrop grid>   
                              <Upload
                              maxCount={1}                              
                              onPreview={onPreview}
                              listType="picture-card"                              
                              onChange={(info)=>{info.fileList.length>0 ?this.onChangeMember(info.fileList[0].originFileObj,'memberImg',0):this.onChangeMember('','memberImg',0)}}
                              value={this.state.memberData.memberImg}
                              accept=".png,.jpeg,.jpg"
                              className="upload-list-inline"                              
                              >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                              </Upload>
                            </ImgCrop>                       
                        <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).requiredImage}</span>
                        {this.state.memberError[0]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[0]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="nom"
                        name="nom"
                        >
                        <Input onChange={(e)=>this.onChangeMember(e.target.value,'nom',1)} value={this.state.memberData.nom} />
                        {this.state.memberError[1]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[1]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="poste en francais"
                        name="poste"
                        >
                        <Input onChange={(e)=>this.onChangeMember(e.target.value,'poste',2)} value={this.state.memberData.poste} />
                        {this.state.memberError[2]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[2]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="poste en anglais"
                        name="posteEn"
                        >
                        <Input onChange={(e)=>this.onChangeMember(e.target.value,'posteEn',3)} value={this.state.memberData.posteEn} />
                        {this.state.memberError[3]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[3]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="email"
                        name="email"
                        >
                        <Input onChange={(e)=>this.onChangeMember(e.target.value,'email',4)} value={this.state.memberData.email} />
                        {this.state.memberError[4]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[4]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="linkedin"
                        name="linkedin"
                        >
                        <Input onChange={(e)=>this.onChangeMember(e.target.value,'linkedin',5)} value={this.state.memberData.linkedin} />
                        {this.state.memberError[5]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[5]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="description en francais"
                        name="description"
                        >
                        <Input.TextArea onChange={(e)=>this.onChangeMember(e.target.value,'description',6)} value={this.state.memberData.description} />
                        {this.state.memberError[6]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[6]}</div>} 
                    </Form.Item>    
                    <Form.Item
                        label="description en anglais"
                        name="descriptionEn"
                        >
                        <Input.TextArea onChange={(e)=>this.onChangeMember(e.target.value,'descriptionEn',7)} value={this.state.memberData.descriptionEn} />
                        {this.state.memberError[7]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[7]}</div>} 
                    </Form.Item>                    
                    <Button disabled={this.state.loading==true} onClick={this.handleMemberForm}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                        {getLanguage(this.props).submit} <RightOutlined />
                    </Button>
                    </Form>
                    </Col>
                    </Row>
                    {this.state.loading == true ? <FullPageLoader  />: null}
            </>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(AddAngel);