const initialState = {
  token: null,
  username:null,
  isLogIn:null,
  lang:"fr",
  valueStep1:null,
  valueSlider:null,
  isAdmin:null,
  valueActuality:null,
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        lang:action.lang
      };

      case "SET_STARTUP_STEP_ONE":
      return {
        ...state,
        valueStep1:action.value
      };
      
      case "SET_STARTUP_STEP_TWO":
      return {
        ...state,
        valueStep2:action.value
      };

      case "SET_STARTUP_STEP_THREE":
      return {
        ...state,
        valueStep3:action.value
      };

      // case "SET_INVESTOR_STEP_ONE":
      // return {
      //   ...state,
      //   valueStep1:action.value
      // };
      
      // case "SET_INVESTOR_STEP_TWO":
      // return {
      //   ...state,
      //   valueStep2:action.value
      // };

      case "SET_INVESTOR_Form":
      return {
        ...state,
        valueInvestorForm:action.value
      };
      
      case "SET_SLIDER_DATA":
      return {
        ...state,
        valueSlider:action.value
      };

      case "SET_ACTUALITY_DATA":
      return {
        ...state,
        valueActuality:action.value
      };

      // case "UPDATE_INVESTOR":
      // return {
      //   ...state,
      //   valueSlider:action.value
      // };

      case "GET_TOKEN":
        return {
          ...state,
          token:action.token,
          isLogIn:action.isLogIn,
          username:action.username,
          isAdmin:action.isAdmin
      
        };
    case "LOGOUT":
      return {
        token: null,
        username:action.value,
        isLogIn:action.value,
        lang:'fr',
        isAdmin:action.value
      };
      case "LOGOUT_ADMIN":
        return {
          token: null,
          username:null,
          isLogIn:null,
          isAdmin:null,
          lang:'fr'
        };
    default:
      return state;
  }
}

export default AuthReducer;