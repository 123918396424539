import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Form, Input, Upload} from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined, RightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../../editor/EditorToolbar";
import UpdateToolbar, { addModules, addFormats } from "../../editor/UpdateToolbar";
import isURL from 'validator/es/lib/isURL';
import "react-quill/dist/quill.snow.css";
import "../../editor/Editor";

const EditActuality = (props) => {
        
        const [requiredMark, setRequiredMarkType] = useState('optional');  
        const [loading, setLoading] = useState(false)  
        const [actualityImg, setActualityImg] = useState('')
        const [title_fr, setTitle_fr] = useState('')
        const [title_en, setTitle_en] = useState('')
        const [excerpt_fr, setExcerpt_fr] = useState('')
        const [excerpt_en, setExcerpt_en] = useState('')
        const [content_fr, setContent_fr] = useState('')
        const [content_en, setContent_en] = useState('')
        const [social_link, setSocial_link] = useState('')
        const [meeting_link, setMeeting_link] = useState('')
        const [idActuality, setIdActuality] = useState('')
        const [current, setCurrent] = useState(0)
        const [fileList, setFileList] = useState([''])
        const [validation, setValidation] = useState({error:[true,true,true,true,true,true,true,true,true],errorMsg:
         [getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
        ]})
        const [actualityError, setActualityError] = useState([true,true,true,true,true,true,true,true,true])
        const [actualityErrorMsg, setActualityErrorMsg] = useState(['','','','','','','','',''])
     
      useEffect(() => {
         setIdActuality(props.match.params.id)
         getActualityById()
      }, [])

    const getActualityById=async()=>{
        const requestOptions = {
            method: 'GET',
          };
          const data = await fetch(apiURL+'getActuality/'+props.match.params.id,requestOptions);
          const dataJson = await data.json();
          if(data.status == 200){
            console.log("success");
            setActualityImg(dataJson.image)
            setTitle_fr(dataJson.titleFr)
            setTitle_en(dataJson.titleEn)
            setExcerpt_fr(dataJson.excerptFr)
            setExcerpt_en(dataJson.excerptEn)
            setContent_fr(dataJson.contentFr)
            setContent_en(dataJson.contentEn)
            setSocial_link(dataJson.socialLink)
            setMeeting_link(dataJson.meetingLink)            
          }
    }

    const actualityValidation =() =>{
      let aux ={...validation}
      if(!actualityImg ){
          aux.error[0]=true
          aux.errorMsg[0]=getLanguage(props).required
        }else{
            aux.error[0]=false
            aux.errorMsg[0]=''
        }
        if(title_fr.trim()=='' ){
        
          aux.error[1]=true
          aux.errorMsg[1]=getLanguage(props).required
        }else{
            aux.error[1]=false
            aux.errorMsg[1]=''
        }
        if(title_en.trim()=='' ){
          
          aux.error[2]=true
          aux.errorMsg[2]=getLanguage(props).required
        }else{
            aux.error[2]=false
            aux.errorMsg[2]=''
        }
        if(excerpt_fr.length>230 ){
        
          aux.error[3]=true
          aux.errorMsg[3]='le nombre maximum de caractères a été dépassé'
        }else{
            aux.error[3]=false
            aux.errorMsg[3]=''
        }
        if(excerpt_en.length>230 ){
          
          aux.error[4]=true
          aux.errorMsg[4]='le nombre maximum de caractères a été dépassé'
        }else{
            aux.error[4]=false
            aux.errorMsg[4]=''
        }
        if(content_fr.trim()=='' || content_fr==='<p><br></p>'){
     
          aux.error[5]=true
          aux.errorMsg[5]=getLanguage(props).required
        }else{
            aux.error[5]=false
            aux.errorMsg[5]=''
        }
        if(content_en.trim()=='' || content_en==='<p><br></p>' ){
         
          aux.error[6]=true
          aux.errorMsg[6]=getLanguage(props).required
        }else{
            aux.error[6]=false
            aux.errorMsg[6]=''
        }
        if(!isURL(social_link) && !social_link.trim()==''){
         
          aux.error[7]=true
          aux.errorMsg[7]='Veuillez saisir une URL valide.'
        }else{
            aux.error[7]=false
            aux.errorMsg[7]=''
        }
        if(!isURL(meeting_link) && !meeting_link.trim()=='' ){
          aux.error[8]=true
          aux.errorMsg[8]='Veuillez saisir une URL valide.'
        }else{
            aux.error[8]=false
            aux.errorMsg[8]=''
        }        
        setValidation(aux)
    }
  
    const updateActuality=async()=>{
      actualityValidation()
         const ERROR = [...validation.error]
            const ERROR_MSG=[...validation.errorMsg]
          setActualityError(ERROR)
        setActualityErrorMsg(ERROR_MSG) 
        if(!validation.error.includes(true)){
        setLoading(true)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let formdata =new FormData()
        formdata.append('current',current)
        formdata.append('actuality_img',actualityImg)
        formdata.append('title_fr',title_fr)
        formdata.append('title_en',title_en)
        formdata.append('excerpt_fr',excerpt_fr)
        formdata.append('excerpt_en',excerpt_en)
        formdata.append('content_fr',content_fr)
        formdata.append('content_en',content_en)
        formdata.append('social_link',social_link)
        formdata.append('meeting_link',meeting_link)
        
        const requestOptions = {
          method: 'POST',
          // headers: myHeaders,
          body: formdata
        };
        const data = await fetch(apiURL+'updateActuality/'+idActuality,requestOptions);
        console.log("here",data);
        const dataJson = await data.json();
        console.log("here2",dataJson);
        if(data.status == 200){
          console.log("success");
          setLoading(false)
          window.location='/admin/actualities'
        }
        }
    }

      const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };
      
       const defaultFile = [
        {
          uid: '0',
          name: actualityImg && actualityImg.length>0?actualityImg.split('/').pop(): '',
          status: 'done',
          url: actualityImg && actualityImg.length>0? apiURL+actualityImg.replace('/public/', ''): '',
          thumbUrl: actualityImg && actualityImg.length>0? apiURL+actualityImg.replace('/public/', ''): '',
        },
      ];      
       
      const onChange = ({ fileList: newFileList }) => {        
        newFileList.length>0 ? setActualityImg(newFileList[0].originFileObj) : setActualityImg('')  
        setCurrent(1)
        setFileList(newFileList)        
      }
      console.log(title_en,'title');
        return (  
        <div>            
            <h1 id='title'>Editer Actualite</h1>
            
            <Row align="center">
                    <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                <Form
                name="basic"
                layout="vertical"
                initialValues={{
                  requiredMarkValue: requiredMark,
                }}
                requiredMark={requiredMark}
                >
                <Form.Item
                    label="Filename"
                    name="actualityImg"
                    required
                    >     <ImgCrop grid aspect={400/200}>                          
                          <Upload
                            onPreview={onPreview}
                            listType="picture-card"
                            maxCount={1}
                            onChange={onChange}                             
                            accept=".png,.jpeg,.jpg"
                            fileList={current==0?[...defaultFile]:fileList}
                            className="upload-list-inline"
                            // beforeUpload={() => false}
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload> 
                          </ImgCrop>                    
                    <span style={{color:"red",fontSize:"10px"}}>{getLanguage(props).requiredImage}</span>
                    {actualityError[0]&&<div style={{color:'red'}}>{actualityErrorMsg[0]}</div>} 
                </Form.Item>
                <Form.Item
                    label="titre en francais"
                    name="title_fr"
                    required
                    >
                    <Input onChange={(e)=>setTitle_fr(e.target.value)} value={title_fr} />
                    {actualityError[1]&&<div style={{color:'red'}}>{actualityErrorMsg[1]}</div>}                </Form.Item>
                <Form.Item
                    label="titre en anglais"
                    name="title_en"
                    required
                    >
                    <Input onChange={(e)=>setTitle_en(e.target.value)} value={title_en} />
                    {actualityError[2]&&<div style={{color:'red'}}>{actualityErrorMsg[2]}</div>}                </Form.Item>
                    <Form.Item
                    label="extrait en francais"
                    name="excerpt_fr"
                    tooltip={{
                          title: "un extrait de l'actualité apparaît sur la carte d'actualité",
                          icon: <InfoCircleOutlined />,
                        }}    
                    >
                    <Input.TextArea onChange={(e)=>setExcerpt_fr(e.target.value)} value={excerpt_fr} />
                    <span style={{color:"red",fontSize:"10px"}}>{excerpt_fr==undefined?'0':excerpt_fr.length}/230</span>
                    {actualityError[3]&&<div style={{color:'red'}}>{actualityErrorMsg[3]}</div>}                </Form.Item>
                <Form.Item
                    label="extrait en anglais"
                    name="excerpt_en"
                    tooltip={{
                          title: "un extrait de l'actualité apparaît sur la carte d'actualité",
                          icon: <InfoCircleOutlined />,
                        }}    
                    >
                    <Input.TextArea onChange={(e)=>setExcerpt_en(e.target.value)} value={excerpt_en} />
                    <span style={{color:"red",fontSize:"10px"}}>{excerpt_en==undefined?'0':excerpt_en.length}/230</span>
                    {actualityError[4]&&<div style={{color:'red'}}>{actualityErrorMsg[4]}</div>}                </Form.Item>
                <Form.Item
                    label="contenu en français"
                    name="content_fr"
                    required
                    >
                    {/* <Input.TextArea onChange={(e)=>setContent_fr(e.target.value)} value={content_fr} /> */}
                    <div className="editor" style={{background:"#fff"}}>
                        <div className="text-editor">
                    <EditorToolbar />
                    <ReactQuill
                      theme="snow"        
                      onChange={html => setContent_fr(html)} 
                      value={useState&&content_fr}
                      placeholder={"Écrire quelque chose d'incroyable..."}
                      modules={modules}
                      formats={formats}
                    />
                    {actualityError[5]&&<div style={{color:'red'}}>{actualityErrorMsg[5]}</div>}                
                  </div>
                  </div>    
                </Form.Item>
                <Form.Item
                    label="contenu en anglais"
                    name="content_en"
                    required
                    >
                    <div className="editor" style={{background:"#fff"}}>
                        <div className="text-editor">
                    <UpdateToolbar />
                    <ReactQuill
                      theme="snow"        
                      onChange={html => setContent_en(html)} 
                      value={content_en}
                      placeholder={"Écrire quelque chose d'incroyable..."}
                      modules={addModules}
                      formats={addFormats}
                    />
                  {actualityError[6]&&<div style={{color:'red'}}>{actualityErrorMsg[6]}</div>}  
                   </div>
                  </div>   
                  </Form.Item>
                  <Form.Item
                    label="social link"
                    name="social_link"
                    >
                    <Input onChange={(e)=>setSocial_link(e.target.value)} value={social_link} />
                  {actualityError[7]&&<div style={{color:'red'}}>{actualityErrorMsg[7]}</div>}                </Form.Item>
                  <Form.Item
                    label="meeting link"
                    name="meeting_link"
                    >
                    <Input onChange={(e)=>setMeeting_link(e.target.value)} value={meeting_link} />
                  {actualityError[8]&&<div style={{color:'red'}}>{actualityErrorMsg[8]}</div>}                </Form.Item>
                <Button disabled={loading==true} onClick={updateActuality}  className={props.lang == "fr"?props.lang+"_btn-next":props.lang+"_btn-next"}>
                    {getLanguage(props).submit} <RightOutlined />
                </Button>
                </Form>
                </Col>
                </Row>
                {loading == true ? <FullPageLoader  />: null}
        </div> )
    
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
export default connect(mapStateToProps, mapDispatchToProps)(EditActuality);