import React, { Component } from "react";
import ReactDOM from "react-dom";
import Preloader from "../../components/preloader/Preloader";
import Timer from "../../components/Countdown/Timer";
import Optin from "../../components/Optin/Optin";
import img from "../../assets/img/business-angel-network.png";

import "./styles.css";

class ComingSoon extends Component {
    render() {
  return (
      <div className="soon">
      <img src={img} className="img" />
    <div className="comingsoon">
      <div className="container">
        <h1>
          Website
          <br />
          Coming Soon
        </h1>
        <Timer />
        <Optin />
        <Preloader />
      </div>
    </div>
    </div>
  );
}
}

export default ComingSoon;
