import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Form, Input, Upload} from 'antd';
import { UploadOutlined, RightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "../../editor/EditorToolbar";
import UpdateToolbar, { addModules, addFormats } from "../../editor/UpdateToolbar";
import isURL from 'validator/es/lib/isURL';
import "react-quill/dist/quill.snow.css";
import "../../editor/Editor";


const AddActuality = (props) => {    
      const [requiredMark, setRequiredMarkType] = useState('optional');  
      const [loading, setLoading] = useState(false)  
      const [actualityData, setActualityData] = useState({actualityImg:'',title_fr:'',title_en:'',excerpt_fr:'',excerpt_en:'',content_fr:'',content_en:'',social_link:'',meeting_link:'',
         validation:{error:[true,true,true,false,false,true,true,false,false],errorMsg:
         [getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,
         getLanguage(props).required,]}})  
         const [actualityError, setActualityError] = useState([true,true,true,true,true,true,true,true,true]) 
         const [actualityErrorMsg, setActualityErrorMsg] = useState(['','','','','','','','',''])                     
    
    useEffect(() => {
        
    },[] )

    const onChangeActuality=(value,key,index)=>{
    let aux ={...actualityData}
      aux[key]=value
      if(key=="actualityImg"){
        if(!value){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="title_fr"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="title_en"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="excerpt_fr"){
        if(value.length>230){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='le nombre maximum de caractères a été dépassé'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="excerpt_en"){
        if(value.length>230){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='le nombre maximum de caractères a été dépassé'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="content_fr"){
        if(value.trim()==='' || value==='<p><br></p>'){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="content_en"){
        if(value.trim()==='' || value==='<p><br></p>'){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="social_link"){
        if(!isURL(value) && !value.trim()==''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='Veuillez saisir une URL valide.'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="meeting_link"){
        if(!isURL(value) && !value.trim()==''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='Veuillez saisir une URL valide.'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      const action ={type:"SET_ACTUALITY_DATA", value:aux}
      props.dispatch(action);
      console.log("auuuuuuuuuux",aux);
      setActualityData(aux)
      // setState({actualityData:aux})
  }

  const handleActualityForm =async()=>{
      const ERROR = [...actualityData.validation.error]
      const ERROR_MSG=[...actualityData.validation.errorMsg]
      setActualityError(ERROR)
      setActualityErrorMsg(ERROR_MSG)      
      if(!actualityData.validation.error.includes(true)){
        setLoading(true)      
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const actuality = actualityData
      let formdata =new FormData()
      formdata.append('actuality_img',actuality.actualityImg)
      formdata.append('title_fr',actuality.title_fr)
      formdata.append('title_en',actuality.title_en)
      formdata.append('excerpt_fr',actuality.excerpt_fr)
      formdata.append('excerpt_en',actuality.excerpt_en)
      formdata.append('content_fr',actuality.content_fr)
      formdata.append('content_en',actuality.content_en)
      formdata.append('social_link',actuality.social_link)
      formdata.append('meeting_link',actuality.meeting_link)
      
      const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata
      };
      
      const data = await fetch(apiURL+'addActuality',requestOptions);
      console.log("here",data);
      const dataJson = await data.json();
      console.log("here2",dataJson);
      if(data.status == 201){
        console.log("success");
        setLoading(false)
        // setState({loading:false})
        window.location='/admin/actualities'
      }
    }
  }
  const handleChange = value => {
    setActualityData({ value })
  };
const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };
    return(
            
            <div>
                <h1 id='title'>Ajouter de l'actualité</h1>
                
                <Row align="center">
                        <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                    <Form
                    name="basic"
                    layout="vertical"                    
                    initialValues={{
                      requiredMarkValue: requiredMark,
                    }}
                    requiredMark={requiredMark}
                    >
                    
                    <Form.Item
                        label="Filename"
                        name="actualityImg"
                        required
                        >
                            <ImgCrop grid aspect={350/200}>   
                              <Upload
                              maxCount={1}                              
                              onPreview={onPreview}
                              listType="picture-card"                            
                              onChange={(info)=>{info.fileList.length>0 ?onChangeActuality(info.fileList[0].originFileObj,'actualityImg',0):onChangeActuality('','actualityImg',0)}}
                              value={actualityData.actualityImg}
                              accept=".png,.jpeg,.jpg"
                              className="upload-list-inline"
                              // beforeUpload={() => false}
                              >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                              </Upload>
                            </ImgCrop>                        
                        <span style={{color:"red",fontSize:"10px"}}>{getLanguage(props).requiredImage}</span>
                        {actualityError[0]&&<div style={{color:'red'}}>{actualityErrorMsg[0]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="titre en francais"
                        name="title_fr"
                        required
                        >
                        <Input onChange={(e)=>onChangeActuality(e.target.value,'title_fr',1)} value={actualityData.title_fr} />
                        {actualityError[1]&&<div style={{color:'red'}}>{actualityErrorMsg[1]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="titre en anglais"
                        name="title_en"
                        required
                        >
                        <Input onChange={(e)=>onChangeActuality(e.target.value,'title_en',2)} value={actualityData.title_en} />
                        {actualityError[2]&&<div style={{color:'red'}}>{actualityErrorMsg[2]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="extrait en francais"
                        name="excerpt_fr"   
                        tooltip={{
                          title: "un extrait de l'actualité apparaît sur la carte d'actualité",
                          icon: <InfoCircleOutlined />,
                        }}                     
                        >
                        <Input.TextArea onChange={(e)=>onChangeActuality(e.target.value,'excerpt_fr',3)} value={actualityData.excerpt_fr} />
                        <span style={{color:"red",fontSize:"10px"}}>{actualityData.excerpt_fr==undefined?'0':actualityData.excerpt_fr.length}/230</span>
                        {actualityError[3]&&<div style={{color:'red'}}>{actualityErrorMsg[3]}</div>}                         
                    </Form.Item>
                    <Form.Item
                        label="extrait en anglais"
                        name="excerpt_en"
                        tooltip={{
                          title: "un extrait de l'actualité apparaît sur la carte d'actualité",
                          icon: <InfoCircleOutlined />,
                        }}    
                        >
                        <Input.TextArea onChange={(e)=>onChangeActuality(e.target.value,'excerpt_en',4)} value={actualityData.excerpt_en} />
                        <span style={{color:"red",fontSize:"10px"}}>{actualityData.excerpt_en==undefined?'0':actualityData.excerpt_en.length}/230</span>
                        {actualityError[4]&&<div style={{color:'red'}}>{actualityErrorMsg[4]}</div>}                         
                    </Form.Item>
                    <Form.Item
                        label="contenu en francais"
                        name="content_fr"  
                        required                      
                        >
                        <div className="editor" style={{background:"#fff"}}>
                        <div className="text-editor">
                    <EditorToolbar />
                    <ReactQuill
                      theme="snow"        
                      onChange={html => onChangeActuality(html,'content_fr',5)} 
                      // value={actualityData.content_fr}
                      placeholder={"Écrire quelque chose d'incroyable..."}
                      modules={modules}
                      formats={formats}
                    />
                    {actualityError[5]&&<div style={{color:'red'}}>{actualityErrorMsg[5]}</div>}  
                        </div>                      
                      </div>                       
                    </Form.Item>
                    <Form.Item
                        label="contenu en anglais"
                        name="content_en"
                        required
                        >
                        <div className="editor" style={{background:"#fff"}}>
                        <div className="text-editor">
                    <UpdateToolbar />
                    <ReactQuill
                      theme="snow"        
                      onChange={html => onChangeActuality(html,'content_en',6)} 
                      // value={actualityData.content_fr}
                      placeholder={"Écrire quelque chose d'incroyable..."}
                      modules={addModules}
                      formats={addFormats}
                    />
                    {actualityError[6]&&<div style={{color:'red'}}>{actualityErrorMsg[6]}</div>}  
                        </div>                      
                      </div>          
                    </Form.Item>
                    <Form.Item
                        label="social Link"
                        name="social_link"                        
                        >
                        <Input onChange={(e)=>onChangeActuality(e.target.value,'social_link',7)} value={actualityData.social_link} />
                        {actualityError[7]&&<div style={{color:'red'}}>{actualityErrorMsg[7]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="meeting link"
                        name="meeting_link"                        
                        >
                        <Input onChange={(e)=>onChangeActuality(e.target.value,'meeting_link',8)} value={actualityData.meeting_link} />
                        {actualityError[8]&&<div style={{color:'red'}}>{actualityErrorMsg[8]}</div>} 
                    </Form.Item>
                    <Button disabled={loading==true} onClick={handleActualityForm}  className={props.lang == "fr"?props.lang+"_btn-next":props.lang+"_btn-next"}>
                        {getLanguage(props).submit} <RightOutlined />
                    </Button>
                    </Form>
                    </Col>
                    </Row>
                    {loading == true ? <FullPageLoader  />: null}
        </div>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(AddActuality);