import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from 'antd';
import { connect } from "react-redux"
import getLanguage from "../../utilFunction/utilFunction";
import Aos from "aos";
import "aos/dist/aos.css";
import { apiURL } from '../../Config/Config';
import message from '../../assets/img/message.png'
import linkedin from '../../assets/img/linkedin.png'
import getBoardsData from './../../utilFunction/showAllBoards';
import { LockFilled } from "@ant-design/icons";

const Board =(props)=>{

  const [data,setData]=useState(['']);
  const [BoardById,setBoardById]=useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
    useEffect(() => {
        Aos.init({
          duration : 1000,
          delay: 0,
          once: true,
          disable: 'mobile'
        }); 
        window.scrollTo(0, 0)
        getBoardsData(props).then((e)=>{
          console.log('data',e);
            setData(e)
        })
    }, []);
    
    const redirectEmail = (email)=>{
     window.location = "mailto:"+email;
    }
    
    const redirectLinkedIn = (link)=>{
      link = link.replace("https://",'');
      link = link.replace("http://",'');
      window.open("https://"+link, "_blank")
    }
    const getBoardsDataByid =async(id)=>{
      const requestOptions = {
        method: 'GET',  
      };
      const UserVerification = await fetch(apiURL+"getBoardByid/"+id, requestOptions);
      console.log(UserVerification);
      const UserVerificationData = await UserVerification.json();
      setBoardById(UserVerificationData)
      console.log("hiiii",UserVerificationData);
    }
    const ShowModal =(id) =>{
      setIsModalVisible(true);
      console.log("hello",id);
      getBoardsDataByid(id)
      
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    return(
      console.log("board",BoardById),
        <div>
        <div className="board-section-non-relative">
            <h2 data-aos="zoom-in-up" className="title-section-board">{getLanguage(props).boardTitle}</h2>
            {/* board section default size */}
          <p data-aos="zoom-in-up" className="board-text">
            {getLanguage(props).boardText}
          </p>
            <Row justify="center" className="pad-board-row" gutter={[{ xs: 8, sm: 24, md: 50, lg: 20, xl: 35 }, 50]}>
              {data.length>0 && Object.keys(data[0]).length !== 0 && data.map((board)=>(
              <Col xs={24} sm={24} md={12} lg={8}>
              <div className="member-border" data-aos="fade-in">
                <img src={apiURL+board.image.replace('/public/', '')} className="board-member-img" />
                <p className="member-content">{props.lang == "fr" ?<div className="button-lire-plus" onClick={()=>ShowModal(board.id)}>Lire la suite</div>: <div  className="button-lire-plus" onClick={()=>ShowModal(board.id)}>See more</div>}</p>
                </div>
                <div className="member-info">
                <p className="member-name">{board.nom}</p>
                <p className="member-position">{props.lang == "fr" ? board.poste : board.posteEn}</p>
                <div className="flex-board">
                  <img src={message} className="member-icons" onClick={()=> redirectEmail(board.email)}/>
                  <img src={linkedin} className="member-icons" onClick={board && board.linkedin !== "#"? ()=> redirectLinkedIn(board.linkedin):null}/>
                </div>
                </div>
              </Col>
              ))}
              </Row>
          </div>
          <Modal footer={false} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
         <Col xs={24} sm={24} md={24} lg={24}>
             <div className="member-border" data-aos="fade-in">
               {BoardById && BoardById.image ?<img src={apiURL+BoardById.image.replace('/public/', '')} className="board-member-img" /> :null }
               {/* <img src={apiURL+BoardById.image.replace('/public/', '')} className="board-member-img" /> */}
               <p className="member-content">{props.lang == "fr" ?BoardById.description: BoardById.descriptionEn}...</p>
               </div>
               <div className="member-info">
               <p className="member-name">{BoardById.nom}</p>
               <p className="member-position">{props.lang == "fr" ? BoardById.poste : BoardById.posteEn}</p>
               <div className="flex-board">
                 <img src={message} className="member-icons" onClick={()=> redirectEmail(BoardById.email)}/>
                 <img src={linkedin} className="member-icons" onClick={()=> redirectLinkedIn(BoardById.linkedin)}/>
               </div>
               </div>
             </Col>
           </Modal>
        </div>  
         
    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        lang:state.auth.lang
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(Board);