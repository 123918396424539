import React, { Component } from 'react'
import { Row, Col, Button, Form, Select, Upload, Switch} from 'antd';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import isEmpty from 'validator/es/lib/isEmpty';
import { connect } from "react-redux"


class AddSharedFile extends Component {

    constructor(props) {
    super(props);
    this.state = {
      userLanguage:'',
      loading:false,
      downloadable:false,
      previewing:false,
      data:[''],
      sharedfileData:{file:'',folderId:'',      
         validation:{error:[true,true],errorMsg:
         ['*required',
         '*required',
         ]}},
          sharedfileError:[true,true],
          sharedfileErrorMsg:['',''],
        };
    }
    
    componentDidMount(){
        window.scrollTo(0, 0)
        this.getData()
    }

    getData=async()=>{
    const requestOptions = {
        method: 'GET',
    };
    const res =  await fetch(apiURL+"getAllFolders", requestOptions);
    if(res.status == 200){
        const resJson = await res.json();        
        this.setState({data:resJson.reverse()})
    }            
    }

    onChangeSharedfile=(value,key,index)=>{
    let aux ={...this.state.sharedfileData}
      aux[key]=value 
      if(key=="file"){
        if(!value){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="folderId"){
        if(isEmpty(value)){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }            
      const action ={type:"SET_SLIDER_DATA", value:aux}
      this.props.dispatch(action);
      console.log(aux);
      this.setState({sharedfileData:aux})
  }

  handleSharedfileForm =async()=>{
      const ERROR = [...this.state.sharedfileData.validation.error]
      const ERROR_MSG=[...this.state.sharedfileData.validation.errorMsg]
      this.setState({
        sharedfileError:ERROR,
        sharedfileErrorMsg:ERROR_MSG
      })
      if(!this.state.sharedfileData.validation.error.includes(true)){
      this.setState({loading:true})
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const sharedfile = this.state.sharedfileData
      let formdata =new FormData()
      formdata.append('file',sharedfile.file)
      formdata.append('folder_id',sharedfile.folderId)
      formdata.append('downloadable',this.state.downloadable)
      formdata.append('previewing',this.state.previewing)
      
      
      const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata
      };
      const data = await fetch(apiURL+'addSharedFile',requestOptions);
      console.log("here",data);
      const dataJson = await data.json();
      console.log("here2",dataJson);
      if(data.status == 201){
        console.log("success");
        this.setState({loading:false})
        window.location='/admin/sharedfiles'
      }
    }
  }
  // handleChange(value) {
  //   this.onChangeSharedfile(value,'folderId',1)
  // }

    render() {

      const { Option } = Select;
     
        return (
            <>
            
                <h1 id='title'>Ajouter un fichier</h1>
                
                <Row align="center">
                        <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                    <Form
                    name="basic"
                    layout="vertical"
                    >
                    <div className="inline-inputs mobile-block" style={{'justify-content':'space-between'}}>  
                    <Form.Item
                        label="Filename"
                        name="file"
                        >                            
                              <Upload
                              maxCount={1}                              
                              onChange={(info)=>{info.fileList.length>0 ?this.onChangeSharedfile(info.fileList[0].originFileObj,'file',0):this.onChangeSharedfile('','file',0)}}
                              value={this.state.sharedfileData.file}
                              accept=".png,.jpeg,.jpg,.doc,.docx,application/msword,.pdf,.pptx,.csv,.xls,.xlsx"
                              className="upload-list-inline"                              
                              >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                              </Upload>                         
                        <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).required}</span>
                        {this.state.sharedfileError[0]&&<div style={{color:'red'}}>{this.state.sharedfileErrorMsg[0]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="Dossier associé"
                        name="folderId"
                        >                            
                      <Select style={{ width: 120 }} onChange={(e)=>this.onChangeSharedfile(e,'folderId',1)}>
                        {this.state.data.length>0 && Object.keys(this.state.data[0]).length !== 0 && this.state.data.map(( folder, index ) => 
                          <Option value={folder.id.toString()}>{folder.title_fr}</Option>
                        )
                        }                       
                      </Select>
                      {this.state.sharedfileError[1]&&<div style={{color:'red'}}>{this.state.sharedfileErrorMsg[1]}</div>} 
                      </Form.Item>
                      </div>
                      <div className="inline-inputs mobile-block" style={{gap:100}}>   
                    <Form.Item
                        label="Téléchargeable"
                        name="downloadable"
                        >
                        <Switch
                          checked={this.state.downloadable}
                          checkedChildren="Oui"
                          unCheckedChildren="Non"
                          onChange={(e) => {
                            this.setState({downloadable:!this.state.downloadable})
                            console.log(this.state.downloadable,'button');
                            }
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Aperçu"
                        name="previewing"
                        >
                        <Switch
                          checked={this.state.previewing}
                          checkedChildren="Oui"
                          unCheckedChildren="Non"
                          onChange={(e) => {
                            this.setState({previewing:!this.state.previewing})
                            console.log(this.state.previewing,'button');
                            }
                            }
                        />
                    </Form.Item>
                    </div>
                    <Button disabled={this.state.loading==true} onClick={this.handleSharedfileForm}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                        {getLanguage(this.props).submit} <RightOutlined />
                    </Button>
                    </Form>
                    </Col>
                    </Row>
                    {this.state.loading == true ? <FullPageLoader  />: null}
            </>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(AddSharedFile);