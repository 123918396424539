import React, { Component } from 'react'
import { Row, Col, Button, Form, Input } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"

class EditFolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title_fr:'',title_en:'',
            idFolder:'',            
  
         validation:{error:[true,true],errorMsg:
         [getLanguage(this.props).required,
         getLanguage(this.props).required,                
        ]},
          folderError:[true,true],
          folderErrorMsg:['','']}
       
    }    

    componentDidMount(){
        this.setState({idFolder:this.props.match.params.id})
        console.log(this.props.match.params.id);
        this.getFolderById()
    }
    getFolderById=async()=>{
        const requestOptions = {
            method: 'GET',
          };
          const data = await fetch(apiURL+'getFolder/'+this.props.match.params.id,requestOptions);
          const dataJson = await data.json();
          if(data.status == 200){
            console.log("success");
            this.setState({title_fr:dataJson.title_fr, title_en:dataJson.title_en,
            });           
          }
    }

    folderValidation =() =>{
      let aux ={...this.state.validation}
        if(this.state.title_fr.trim()=='' ){
        
          aux.error[0]=true
          aux.errorMsg[0]=getLanguage(this.props).required
        }else{
            aux.error[0]=false
            aux.errorMsg[0]=''
        }
        if(this.state.title_en.trim()=='' ){
          
          aux.error[1]=true
          aux.errorMsg[1]=getLanguage(this.props).required
        }else{
            aux.error[1]=false
            aux.errorMsg[1]=''
        }       
        this.setState({validation:aux})
    }
  
    updateFolder=async()=>{
      this.folderValidation()
         const ERROR = [...this.state.validation.error]
            const ERROR_MSG=[...this.state.validation.errorMsg]
            this.setState({
              folderError:ERROR,
              folderErrorMsg:ERROR_MSG
            })
        if(!this.state.validation.error.includes(true)){
        this.setState({loading:true})
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let formdata =new FormData()              
        formdata.append('title_fr',this.state.title_fr)
        formdata.append('title_en',this.state.title_en)           
        
        const requestOptions = {
          method: 'POST',
          // headers: myHeaders,
          body: formdata
        };
        // console.log('aaaa',apiURL+'editFolder/'+this.state.idFolder,requestOptions);
        const data = await fetch(apiURL+'editFolder/'+this.state.idFolder,requestOptions);
        console.log("here",data);
        const dataJson = await data.json();
        console.log("here2",dataJson);
        if(data.status == 200){
          console.log("success");
          this.setState({loading:false})
          window.location='/admin/folders'
        }
        }
    }

    render() { 
            
        return (  <>
            
            <h1 id='title'>Editer un Dossier</h1>
            
            <Row align="center">
                    <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                <Form
                name="basic"
                layout="vertical"
                >                                
                <Form.Item
                    label="titre en francais"
                    name="title_fr"
                    >
                    <Input onChange={(e)=>this.setState({title_fr: e.target.value})} value={this.state&&this.state.title_fr} />
                    {this.state.folderError[0]&&<div style={{color:'red'}}>{this.state.folderErrorMsg[0]}</div>}                </Form.Item>
                <Form.Item
                    label="titre en anglais"
                    name="title_en"
                    >
                    <Input onChange={(e)=>this.setState({title_en: e.target.value})} value={this.state&&this.state.title_en} />
                    {this.state.folderError[1]&&<div style={{color:'red'}}>{this.state.folderErrorMsg[1]}</div>}                </Form.Item>                       
                <Button disabled={this.state.loading==true} onClick={this.updateFolder}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                    {getLanguage(this.props).submit} <RightOutlined />
                </Button>
                </Form>
                </Col>
                </Row>
                {this.state.loading == true ? <FullPageLoader  />: null}
        </> );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
export default connect(mapStateToProps, mapDispatchToProps)(EditFolder);