import React, { Component } from "react";
import { Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import classnames from "classnames";
import { Row, Col } from 'antd';
import logo from '../assets/img/logo.png'
import { connect } from "react-redux"
import { Link, withRouter } from 'react-router-dom'
import getLanguage from "../utilFunction/utilFunction"
import PropTypes from 'prop-types';


const { SubMenu } = Menu;

class Headers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'fr',
      visible: true,
      topScroll: 0
    };
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  // static propTypes = {
  //   match: PropTypes.object.isRequired,
  //   location: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired
  // };
  // componentWillMount(){
  //       hashHistory.listen((event)=>{
  //           pathname = event.pathname.split("/");
  //           if(pathname != null){
  //               this.setState({
  //                   test:pathname[1]
  //               });
  //           }
  //       });
  //   }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);    
  }

  handleScroll = () => {
  const { topScroll } = this.state;
    const currentScrollPos = window.pageYOffset;
    const visible = topScroll == currentScrollPos;

    this.setState({
      visible
    });

};
  
  handleClick = e => {
    console.log('click ', e);
  };

  render() {
    // const location = useLocation()
    const { location } = this.props;

    return ( 
        <div className="mobileHidden">
            <Row justify="space-between" className='hidden-header-row'>
            <Col span={3}>
              
            </Col>
            <Col >
              <Menu mode="horizontal" className="header-menu">
                <Menu.Item className="header-menu-list">
                  <a href="#">
                    hidden
                  </a>
                </Menu.Item>
                <Menu.Item className="header-menu-list">
                  <a href="#">
                    hidden
                  </a>
                </Menu.Item>
                <Menu.Item className="header-menu-list">
                  <a href="#">
                    hidden
                  </a>
                </Menu.Item>
                <Menu.Item className="header-menu-list">
                  <a href="#">
                    hidden 
                  </a>
                </Menu.Item>
                <Menu.Item className="header-menu-list">
                  <a href="#">
                    hidden
                  </a>
                </Menu.Item>
                <Menu.Item className="header-menu-list">
                  <a href="#">
                    hidden
                  </a>
                </Menu.Item>
              </Menu>
              </Col>
              </Row>
            <Row justify="space-between" className={classnames("header-row", {
          "header-fixed-row": this.state.visible == false
        })}>
            <Col span={3}>
              <Link to="/">
                <img src={logo} className={this.props.lang == "fr"?this.props.lang+"_logo":this.props.lang+"_logo"} />
              </Link>
            </Col>
            <Col >
              <Menu onClick={this.handleClick} selectedKeys={[location.pathname]} mode="horizontal" className={classnames("header-menu", {
          "header-fixed-menu": this.state.visible == false
        })}>             
                  <SubMenu key="/" title='Damya' icon={<DownOutlined />}>
                    <Menu.ItemGroup className="_dropdown-menu" >
                      <Menu.Item key="/aboutus"><Link to="/aboutus">{getLanguage(this.props).aboutUs}</Link></Menu.Item>
                      <Menu.Item key="/programs"><Link to="/programs">{getLanguage(this.props).program}</Link></Menu.Item>
                      <Menu.Item key="/boards"><Link to="/boards">{getLanguage(this.props).board}</Link></Menu.Item>
                      <Menu.Item key="/partners"><Link to="/partners">{getLanguage(this.props).ourPartner}</Link></Menu.Item>
                      <Menu.Item key="/damya-numbers"><Link to="/damya-numbers">{getLanguage(this.props).damyaInNumbers}</Link></Menu.Item>
                    </Menu.ItemGroup>
                  </SubMenu>
              
                <Menu.Item className="header-menu-list" key="/actualities">
                  <Link to="/actualities">
                    {getLanguage(this.props).actuality}
                  </Link>
                </Menu.Item>
                {/* <Menu.Item className="header-menu-list" key="/team">
                  <Link to="/coming-soon">
                    {getLanguage(this.props).ourTeam}
                  </Link>
                </Menu.Item> */}
                <Menu.Item className="header-menu-list" key="/investor">
                  <Link to="/investor">
                    {getLanguage(this.props).DevenirDamyaAngel}
                  </Link>
                </Menu.Item>
                <Menu.Item className="header-menu-list" key="/startup">
                  <Link to="/startup">
                    {getLanguage(this.props).raiseFunds}
                  </Link>
                </Menu.Item>
              </Menu>
              </Col>
              </Row>
            </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    lang:state.auth.lang
  };
};
export default connect(mapStateToProps, mapDispatchToProps) (withRouter(Headers))