import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import { connect } from "react-redux";
import AuthRoute from "./PrivateRoute/AuthRoute";
import AdminRoute from "./PrivateRoute/AdminRoute";
import detectBrowserLanguage from "detect-browser-language";

// admin
import { TheLayout } from "../components/admin";
import Sliders from "../components/admin/slider/Sliders";
import AddSlider from "../components/admin/slider/AddSlider";
import editSlider from "../components/admin/slider/editSlider";
import Boards from "../components/admin/boards/Boards";
import AddBoard from "../components/admin/boards/AddBoard";
import editBoard from "./../components/admin/boards/editBoard";
import Angels from "../components/admin/angels/Angels";
import AddAngel from "../components/admin/angels/AddAngel";
import editAngel from "./../components/admin/angels/editAngel";
import AdminActuality from "../components/admin/actualities/AdminActuality";
import AddActuality from "../components/admin/actualities/AddActuality";
import editActuality from "../components/admin/actualities/EditActuality";
import AdminEvent from "../components/admin/events/AdminEvent";
import AddEvent from "../components/admin/events/AddEvent";
import editEvent from "./../components/admin/events/editEvent";
import Numbers from "../components/admin/numbers/Numbers";
import AddNumber from "../components/admin/numbers/AddNumber";
import editNumber from "../components/admin/numbers/editNumber";
import Activities from "../components/admin/activities/Activities";
import AddActivity from "../components/admin/activities/AddActivity";
import EditActivity from "../components/admin/activities/EditActivity";
import Videos from "../components/admin/videos/Videos";
import AddVideo from "../components/admin/videos/AddVideo";
import EditVideo from "../components/admin/videos/EditVideo";
import Folders from "../components/admin/folders/Folders";
import AddFolder from "../components/admin/folders/AddFolder";
import EditFolder from "../components/admin/folders/EditFolder";
import SharedFiles from "../components/admin/sharedfiles/SharedFiles";
import AddSharedFile from "../components/admin/sharedfiles/AddSharedFile";
import EditSharedFile from "../components/admin/sharedfiles/EditSharedFile";
// front
// import MemberSpace from "../components/memberSpace/MemberSpace";
import LoginForm from "../components/LoginForm";
import LoginAdmin from "../components/admin/loginAdmin";
import ForgotPassword from "./../components/ForgotPassword";
import LandingPage from "../Pages/LandingPage/LandingPage";
import Startup from "../components/Startup";
import Investor from "../components/Investor";
import ComingSoon from "../components/CoomingSoon/ComingSoon";
import AboutUs from "../components/about us/AboutUs";
import Board from "../components/board/Board";
import ContactezNous from "../components/contactez nous/ContactezNous";
import Partner from "../components/partners/Partner";
import NosActivites from "./../components/nos activite/NosActivites";
import DamyaCounter from "./../components/damya counter/DamyaCounter";
import { apiURL } from "../Config/Config";
import AllCandidature from "../components/Candidature/AllCandidature";
import showCandidature from "../components/Candidature/showCandidature";
// import AddMembre from "../components/admin/Membres/AddMembre";
import Actualities from "../components/actuality/Actualities";
import SingleActuality from "../components/actuality/SingleActuality";
import Partners from "../components/admin/partenaire/Partners";
import Addpartners from "../components/admin/partenaire/Addpartners";
import ShowUser from "../components/admin/ShowUser";
import ShowContact from "../components/admin/contact/ShowContact";
import showSubscriptionNewsLetter from "../components/admin/newsletter/showSubscriptionNewsLetter";
import spaceMembre from "../components/memberSpace/spaceMembre";

class MainRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLanguage: "",
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTokenUser();
    this.setState({ userLanguage: detectBrowserLanguage() });
    if (detectBrowserLanguage() == "fr-FR") {
      const action = { type: "SET_CURRENT_USER", lang: "fr", token: null };
      this.props.dispatch(action);
    }
    if (detectBrowserLanguage() == "en-EN") {
      const action = { type: "SET_CURRENT_USER", lang: "en", token: null };
      this.props.dispatch(action);
    }
    const action = { type: "SET_CURRENT_USER", lang: "fr", token: null };
    this.props.dispatch(action);
  }

  getTokenUser = async () => {
    if (this.props.auth && this.props.auth.token) {
      let formdata = new FormData();
      formdata.append("username", this.props.auth.username);
      const requestOptions = {
        method: "POST",
        body: "formdata",
      };
      const data = await fetch(apiURL + "verfyOnlyUser", requestOptions);
      if (data.status == 200) {
        const dataJson = await data.json();
      } else {
        this.setState({ isAuthenticated: false });
      }
    }
  };

  render() {
    console.log("state", this.state.isAuthenticated);
    return (
      <div className="scroll-hide">
        <div className="background">
          <Router>
            <Switch>
              {/********** Admin routes ***********/}
              <AdminRoute
                exact
                path="/admin"
                isAuthenticated={this.props.auth.isAdmin}
                component={TheLayout}
              />
              <AdminRoute
                exact
                path="/candidature"
                isAuthenticated={this.props.auth.isAdmin}
                component={AllCandidature}
              />
              <AdminRoute
                exact
                path="/showCandidature/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={showCandidature}
              />
              <AdminRoute
                exact
                path="/showUser/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={ShowUser}
              />
              <AdminRoute
                exact
                path="/admin/showFormContact"
                isAuthenticated={this.props.auth.isAdmin}
                component={ShowContact}
              />
              <AdminRoute
                exact
                path="/admin/showSubscriptionNewsLetter"
                isAuthenticated={this.props.auth.isAdmin}
                component={showSubscriptionNewsLetter}
              />

              {/* Sliders */}
              <AdminRoute
                exact
                path="/admin/sliders"
                isAuthenticated={this.props.auth.isAdmin}
                component={Sliders}
              />
              <AdminRoute
                exact
                path="/admin/add-slider"
                isAuthenticated={this.props.auth.isAdmin}
                component={AddSlider}
              />
              <AdminRoute
                exact
                path="/admin/editSlider/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={editSlider}
              />

              {/* Boards */}
              <AdminRoute
                exact
                path="/admin/boards"
                isAuthenticated={this.props.auth.isAdmin}
                component={Boards}
              />
              <AdminRoute
                exact
                path="/admin/add-board"
                isAuthenticated={this.props.auth.isAdmin}
                component={AddBoard}
              />
              <AdminRoute
                exact
                path="/admin/editBoard/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={editBoard}
              />

              {/* Angels */}
              <AdminRoute
                exact
                path="/admin/angels"
                isAuthenticated={this.props.auth.isAdmin}
                component={Angels}
              />
              <AdminRoute
                exact
                path="/admin/add-angel"
                isAuthenticated={this.props.auth.isAdmin}
                component={AddAngel}
              />
              <AdminRoute
                exact
                path="/admin/editAngel/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={editAngel}
              />

              {/* Actualities */}
              <AdminRoute
                exact
                path="/admin/actualities"
                isAuthenticated={this.props.auth.isAdmin}
                component={AdminActuality}
              />
              <AdminRoute
                exact
                path="/admin/add-actuality"
                isAuthenticated={this.props.auth.isAdmin}
                component={AddActuality}
              />
              <AdminRoute
                exact
                path="/admin/editActuality/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={editActuality}
              />

              {/* Events */}
              <AdminRoute
                exact
                path="/admin/events"
                isAuthenticated={this.props.auth.isAdmin}
                component={AdminEvent}
              />
              <AdminRoute
                exact
                path="/admin/add-event"
                isAuthenticated={this.props.auth.isAdmin}
                component={AddEvent}
              />
              <AdminRoute
                exact
                path="/admin/editEvent/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={editEvent}
              />

              {/* Activities */}
              <AdminRoute
                exact
                path="/admin/activities"
                isAuthenticated={this.props.auth.isAdmin}
                component={Activities}
              />
              <AdminRoute
                exact
                path="/admin/add-activity"
                isAuthenticated={this.props.auth.isAdmin}
                component={AddActivity}
              />
              <AdminRoute
                exact
                path="/admin/EditActivity/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={EditActivity}
              />

              {/* Videos */}
              <AdminRoute
                exact
                path="/admin/videos"
                isAuthenticated={this.props.auth.isAdmin}
                component={Videos}
              />
              <AdminRoute
                exact
                path="/admin/add-video"
                isAuthenticated={this.props.auth.isAdmin}
                component={AddVideo}
              />
              <AdminRoute
                exact
                path="/admin/EditVideo/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={EditVideo}
              />

              {/* partners  */}
              <AdminRoute
                exact
                path="/admin/showPartners"
                isAuthenticated={this.props.auth.isAdmin}
                component={Partners}
              />
              <AdminRoute
                exact
                path="/admin/add-Partners"
                isAuthenticated={this.props.auth.isAdmin}
                component={Addpartners}
              />

              {/* Numbers */}
              <AdminRoute
                exact
                path="/admin/numbers"
                isAuthenticated={this.props.auth.isAdmin}
                component={Numbers}
              />
              <AdminRoute
                exact
                path="/admin/add-number"
                isAuthenticated={this.props.auth.isAdmin}
                component={AddNumber}
              />
              <AdminRoute
                exact
                path="/admin/editNumber/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={editNumber}
              />

              {/* Folders */}
              <AdminRoute
                exact
                path="/admin/folders"
                isAuthenticated={this.props.auth.isAdmin}
                component={Folders}
              />
              <AdminRoute
                exact
                path="/admin/add-folder"
                isAuthenticated={this.props.auth.isAdmin}
                component={AddFolder}
              />
              <AdminRoute
                exact
                path="/admin/EditFolder/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={EditFolder}
              />

              {/* Shared Files */}
              <AdminRoute
                exact
                path="/admin/sharedfiles"
                isAuthenticated={this.props.auth.isAdmin}
                component={SharedFiles}
              />
              <AdminRoute
                exact
                path="/admin/add-sharedfile"
                isAuthenticated={this.props.auth.isAdmin}
                component={AddSharedFile}
              />
              <AdminRoute
                exact
                path="/admin/EditSharedfile/:id"
                isAuthenticated={this.props.auth.isAdmin}
                component={EditSharedFile}
              />

              {/********** Member space routes ***********/}
              <Route exact path="/member-space" component={spaceMembre} />

              {/********** front routes ***********/}
              <PrivateRoute exact path="/" component={LandingPage} />
              <PrivateRoute exact path="/aboutus" component={AboutUs} />
              <PrivateRoute
                exact
                path="/damya-numbers"
                component={DamyaCounter}
              />
              <PrivateRoute exact path="/partners" component={Partner} />
              <PrivateRoute exact path="/boards" component={Board} />
              <PrivateRoute exact path="/contact" component={ContactezNous} />
              <PrivateRoute exact path="/actualities" component={Actualities} />
              <PrivateRoute
                exact
                path="/actuality/:id"
                component={SingleActuality}
              />
              <PrivateRoute exact path="/programs" component={NosActivites} />
              <AuthRoute exact path="/investor" component={Investor} />
              <AuthRoute exact path="/startup" component={Startup} />
              <Route exact path="/coming-soon" component={ComingSoon} />

              {/* Auth */}
              <AuthRoute exact path="/login" component={LoginForm} />
              <AuthRoute exact path="/reset" component={ForgotPassword} />
              <AuthRoute
                exact
                path="/admin/loginAdmin"
                component={LoginAdmin}
              />
            </Switch>
          </Router>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainRoute);
