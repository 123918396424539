import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Image, Table, Modal, Input, Space  } from 'antd';
import { apiURL } from '../../../Config/Config';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const indexURL = apiURL+"getAllBoards"

const Boards = (props) => {
    const [data, setData] = useState([''])
    const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [idMember, setIdMember] = useState('');
    const [visibleModal, setvisibleModal] = useState(false);


    useEffect(() => {
        getData()
    }, [])

    const getData=async()=>{
    const requestOptions = {
        method: 'GET',
    };
    const res =  await fetch(indexURL, requestOptions);
    if(res.status == 200){
        const resJson = await res.json();
        setData(resJson);
    }            
    }

    const removeData =async (id) => {
        console.log("testtt",id);
        const requestOptions = {
            method: 'POST',
          };
          const data = await fetch(apiURL+'deleteMember/'+id,requestOptions);
          const dataJson = await data.json();
          console.log("here2",dataJson);
          if(data.status == 200){
            console.log("success");
            setvisibleModal(false)
            window.location='/admin/boards'
          }
    }


    const renderHeader = () => {
        let headerElement = ['id','name', 'image', 'poste en français', 'poste en anglais', 'email', 'linkedIn', 'description en français', 'description en anglais', 'Action']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const addRedirection =()=>{
    window.location="/admin/add-board"
    }
    const showModal = (id) => {
        setIdMember(id)
        setvisibleModal(true);
      };
    const  closeModal=()=>{
        setvisibleModal(false);
      }

    const  handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0])
        setsearchedColumn(dataIndex)
    };

    const  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
        //   ref={node => {
        //     searchInput = node;
        //   }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setsearchText(selectedKeys[0])
                setsearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
    });

    const  handleReset = clearFilters => {
        clearFilters();
        setsearchText('')
    };

    const columns =[
        {
            title: 'id',
            dataIndex: 'id',            
            width: 50,
            ...getColumnSearchProps('id'),
             fixed: 'left',
        },
        {
            title: 'Nom et prénom',
            dataIndex: 'nom',                        
            ...getColumnSearchProps('id'),             
        },
        {
            title: 'Image',
            dataIndex: 'image',            
            ...getColumnSearchProps('image'),
            render: (val)=>(val&&val.length>0?<Image src={apiURL+val.replace('/public/', '')} width={50} height={50} />:""),            
        },
        {
            title: 'Poste en francais',
            dataIndex: 'poste',                        
            ...getColumnSearchProps('poste'),
            render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")             
        },
        {
            title: 'Poste en anglais',
            dataIndex: 'posteEn',                        
            ...getColumnSearchProps('posteEn'),
            render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")             
        },
        {
            title: 'Email',
            dataIndex: 'email',                        
            ...getColumnSearchProps('email'),             
        },        
        // {
        //     title: 'Description en francais',
        //     dataIndex: 'description',                        
        //     ...getColumnSearchProps('description'),
        //     render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")             
        // },
        // {
        //     title: 'Description en anglais',
        //     dataIndex: 'descriptionEn',                        
        //     ...getColumnSearchProps('descriptionEn'),
        //     render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")             
        // },
        {
            title: 'Linkedin',
            dataIndex: 'linkedin',                        
            ...getColumnSearchProps('linkedin'),
            render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")             
        },
        {             
            title: 'action',
            fixed: 'right',
            width: 120,
            render: (dataIndex) => (
            <div style={{display:'flex', 'align-items':'flex-end', 'flex-direction': 'column', gap:3}}>
                <Link to={`/admin/editBoard/${dataIndex.id}`}>
                  <Button size='small' type="primary">Editer</Button>
                </Link>
                <Button size='small' type="danger" onClick={()=>showModal(dataIndex.id)}>Supprimer</Button>
            </div>
            ),            
        },
    ];

    return (
        <>
            <h1 id='title'>Boards</h1>
            {/* <center>
                <button className='button' onClick={() => addRedirection()}>Add</button>
            </center> */}
            <Row justify="center">
                {/* <table id='employee'>
                    <thead>
                        <tr>{renderHeader()}</tr>
                    </thead>
                    <tbody>
                    {data.length>0 && Object.keys(data[0]).length !== 0 && data.map(({ id, image, nom, poste, posteEn, email, linkedin, description, descriptionEn }) => (
                        <tr key={id}>
                            <td>{id}</td>
                            <td>{nom}</td>
                            <td>
                                <Image src={apiURL+image.replace('/public/', '')} width={80} height={80} />
                            </td>
                            <td>{poste}</td>
                            <td>{posteEn}</td>
                            <td>{email}</td>
                            <td>{linkedin.substring(0, 11)}</td>
                            <td>{description.substring(0, 105)}...</td>
                            <td>{descriptionEn.substring(0, 105)}...</td>
                            <td className='opration'>
                                <Link className='button' to={`/admin/editBoard/${id}`}>Edit</Link>
                                <button className='button' onClick={()=>showModal(id)}>Delete</button>
                            </td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table> */}
                <Col>
                    <Button type="primary" style={{float:'right', marginBottom:20}}  onClick={() => addRedirection()}>Ajouter</Button>
                    <Table columns={columns} dataSource={data} scroll={{ x: 1300 }}
                    pagination={{ defaultPageSize: 10, showSizeChanger: true}}
                    />
                </Col>
            </Row>
            <Modal title="supprimer board" visible={visibleModal} footer={null} mask={true} closable={false} maskClosable={true}>
            <p>veuillez confirmer votre choix</p>
            <Button onClick={()=>removeData(idMember)}>delete</Button>
            <Button onClick={()=>closeModal()} >cancel</Button>

      </Modal>
        </>
    )
}


export default Boards