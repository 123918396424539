import React, { Component, useEffect, useState  } from "react";
import { Row, Col, Input, Form, Button, Table, Space } from 'antd';
import { apiURL } from "../../Config/Config";
import { connect } from "react-redux";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

const AllCandidature =(props)=>{
    const [candidature, setcandidature] = useState('');
    const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');

    useEffect(() => {
        getCandidature()
    }, [])
    const getCandidature=async()=>{
            const requestOptions = {
                method: 'GET',
              };
              const data = await fetch(apiURL+'getAllCandidature',requestOptions);
              const dataJson = await data.json();
              console.log("here2",dataJson);
              if(data.status == 200){
                console.log("success");
                setcandidature(dataJson)
              }
        
    }


    const  getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
            //   ref={node => {
            //     searchInput = node;
            //   }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setsearchText(selectedKeys[0])
                    setsearchedColumn(dataIndex)
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            // setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    const  handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0])
        setsearchedColumn(dataIndex)
      };
    
    const  handleReset = clearFilters => {
        clearFilters();
        setsearchText('')
      };

      const columns = [
        {
          title: 'nom',
          dataIndex: 'nom',
          key: 'nom',
          ...getColumnSearchProps('nom'),
        },
        {
            title: 'prenom',
            dataIndex: 'prenom',
            key: 'prenom',
            ...getColumnSearchProps('prenom'),
        },
        {
          title: 'nomStartup',
          dataIndex: 'nomStartup',
          key: 'nomStartup',
          ...getColumnSearchProps('nomStartup'),
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'), 
        },
        {
          title: 'tel',
          dataIndex: 'tel',
          key: 'tel',
          ...getColumnSearchProps('tel'),
        },
        {
             
            title: 'action',
            render: (dataIndex) => <Link to={`/showCandidature/${dataIndex.id}`}>show</Link>,
            
          },
      ];
    return(
        <div>
             <Table loading={candidature? false:true} columns={columns} dataSource={candidature} />;
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(AllCandidature);