const messagesFr = {

    // Home Page 
    'DevenirDamyaAngel': "Devenir Damya angel",
    'DéposezVotreProjet': "Déposez votre projet",
    // 'slider1TitlePart1': "Le Premier Réseau",
    // 'slider1TitlePart2': "De Femmes Business Angels",
    // 'slider1TitlePart3': "Tunisiennes",
    // 'slider2TitlePart1': "Faites",
    // 'slider2TitlePart2': "un Impact",
    // 'slider2TitlePart3': "Rejoignez-nous",
    // 'impactText': "Les Business Angels investissent au capital des PME et startups innovantes, à fort potentiel de croissance.",
    'servicesTitle': "Nos Valeurs",
    'serviceTitle1': "Engagement",
    'serviceTitle2': "Confiance",
    'serviceTitle3': "Croissance",
    'serviceTitle4': "Réseautage",
    'serviceTitle5': "Impact",
    'serviceTitle6': "Rayonnement",
    'serviceTitle7': "Éthique",
    'actuality': "Actualités",
    'showAll': "Affichez tous",
    'startupTitle': "Pour les Startup",
    'startupText': "Vous souhaitez accélérer le développement de votre entreprise et maximiser votre impact et accéder aux financement ainsi q'un coaching personnalisé ? être accompagné par des Business Angels expérimentés ?",
    'startupTextOne': "Grace à DAMYA, vous accédez à un accompagnement personnalisé de haut niveau et un financement pour assurer la réussite de votre projet.",
    'knowMore': "En savoir plus",
    'investTitle': "Pour les investisseurs",
    'investText': "DAMYA Business Angels est un réseau de femmes investisseuses à impact, permettant de soutenir l'entrepreneuriat innovant et durable, et en particulier les initiatives féminines en Tunisie.",
    'boardTitle': "Notre board",
    'boardText': "Des femmes tunisiennes, chefs d'entreprise, managers, cadres et experts se sont réunies pour lancer  le 1er réseau Tunisien de Women Business Angels Animées par l'ambition de créer de la valeur",
    'memberPresident': "Présidente",
    'memberVicePresident': "Vice-Présidente",
    'treasurer': "Trésorière",
    'defaultMember': "membre",
    'moreAboutTeam': "En savoir plus sur l'équipe",
    'testimonyTitle': "Témoignage femme Damya",
    'testimonyTitle1':'Découvrez Damya en vidéo',
    'testimonySubtitle1': "De 0 à 25 000 DT de chiffre d’affaires en 8 mois",
    'testimonySubtitle2': "De 0 à 9 000 DT de chiffre d’affaires en 8 mois",
    'testimonySubtitle3': "De 0 à 20 000 DT de chiffre d’affaires en 8 mois",
    'testimonyText1': "‘’Être un réseau de femmes investisseuses à impact permettant de",
    'testimonyText2': "‘’Être un réseau de femmes investisseuses à impact permettant de",
    'testimonyText3': "‘’Être un réseau de femmes investisseuses à impact permettant de",
    'testimonyTextLineBreak1': "soutenir l’entrepreneuriat’’",
    'testimonyTextLineBreak2': "soutenir l’entrepreneuriat’’",
    'testimonyTextLineBreak3': "soutenir l’entrepreneuriat’’",
    'partnerTitle': "Nos Partenaires",
    'yes': "Oui",
    'no': "Non",

    // TopBar & Header
    'home': "Damya",
    'aboutUs': "Qui sommes nous",
    'ourTeam': "Nos angels",
    'program': "Nos activités",
    'board': "Notre board",
    'raiseFunds': "Trouver un investisseur",
    'ourPartner': "Nos partenaires",
    'damyaInNumbers': "Damya en chiffres",
    'contact': "Contact us",
    'profile': "Profil",
    'settings': "Paramètres",
    'logout': "Se déconnecter",
    'memberSpace': "Espace membre",

    // Footer
    'followUs': "Suivez nous:",
    'navigation': "Navigation",
    'news': "Actualités",
    'startupServices': "Service pour les Startups",
    'contactfooter': "Contact",
    'schedule': "Lun - Sam: 9:00 - 18:00",
    'newsletter': "Newsletter",
    'subscriptionText': "Abonnez-vous à notre newsletter. Soyez informé de toutes les nouvelles!",
    'inscription': "Inscription",
    'exampleEmail': "exemple@gmail.com",
    
    // About us
    'aboutUsTitle': "Qui sommes-nous ?",
    'aboutUsLeftColumn': "Le 1er réseau de femmes Business Angels Tunisiennes",    
    'aboutUsText1': "DAMYA, est le nom de la reine berbère d’Ifriquiyâ connue aussi sous le nom de la Kahena.",
    'aboutUsText2': "Elle fut l’une des premières féministes et reines guerrières de l’histoire, emblème des femmes braves et déterminées et symbole de force lucide.",
    'aboutUsText3': "Le 17 décembre 2019, des femmes tunisiennes, chefs d'entreprises, managers, cadres et expertes se sont réunies pour lancer le 1er  réseau Tunisien de Women Business Angels.",
    'aboutUsText4': "Animées par l'ambition de créer de la valeur, elles sont convaincues de pouvoir soutenir des projets innovants et à fort potentiel en Tunisie, en investissant dans ces projets à titre personnel mais aussi en apportant du conseil, de l’expertise, de l’expérience et un réseautage dont tout entrepreneur a grand besoin.",
    'aboutUsText5': "DAMYA Business Angels est un réseau de femmes investisseures à impact, permettant de soutenir l'entrepreneuriat innovant et durable, et en particulier les initiatives féminines en Tunisie.",
    'ourMission': "Notre Mission",
    'missionText': "Damya Tunisian Women Business Angels Network a pour mission de contribuer à l'essor des entrepreneurs Tunisiens de demain en injectant du capital à valeur ajoutée (Smart Capital) dans des entreprises innovantes à fort potentiel de croissance.",
    'ourVision': "Notre Vision",
    'visionText': "La vision de Damya est de contribuer au développement d’un écosystème de business angel investment en Tunisie qui favorise le rayonnement des entrepreneurs Tunisiens et l’essor d’une communauté de business angels impactante.",
    'ourApproach': "Notre Approche",
    'approachText': "Nous croyons que la meilleure façon de booster une entreprise et de l’accompagner vers le succès est de l'entourer d'investisseurs à valeur ajoutée qui apportent du capital, de l’expertise et un réseau d'affaires qui ouvre leur des portes.",
    'angelsDefinition': "Qui sont les anges?",
    'angelsDefinitionText': "Nos anges sont des entrepreneurs expérimentés qui ont connu du succès en affaires, ainsi qu’une riche expérience et connaissance du secteur privé, Ce sont des particuliers à valeur nette élevée qui se sont réunis et qui sont prêts à investir dans des entreprises Tunisiennes.",
    'angelsGoals': "Que recherchent les anges?",
    'angelsGoalsText': "Nos anges partagent tous le même but : le professionnalisme de l’angel investment et assurer la réussite et la croissance des entreprises dans lesquelles ils investissent.",
    'ourObjectif': "Nos Objectifs",
    'objectifText': "Promouvoir la culture et diffuser les activités liées à l' Angel investissement et à l'entrepreneuriat.",
    'objectifText1': "Soutenir les femmes entrepreneures et organiser des rencontres régulières entre les membres et les entrepreneurs.",
    'objectifText2': "Faciliter l'évaluation et l'investissement dans des projets entrepreneuriaux d'entreprises portées par des femmes et des hommes qui sont dans la phase initiale de leurs activités, et/ou qui ont un fort potentiel de croissance et/ou qui se caractérisent par un fort taux d'innovation.",
    'objectifText3': "Créer un pont entre les femmes investisseures en Tunisie et la diaspora à l'étranger, afin de les encourager à contribuer au développement des PME et des start-ups en Tunisie.",
    'makeImpact': "#Make_an_impact",
    'makeImpact1': "and join the first Tunisian Woman business angel network",

    // Program
    'activityTitle': "Nos activités",
    'activity1': "Mise à disposition d'un réseau de femmes Business Angels au profit des startups accompagnées.",
    'activity2': "Stimulation de l'entreprenariat féminin, notamment dans les secteurs innovants et les zones défavorisées.",
    'activity3': "La promotion du financement alternatif (BA, crowdfunding, Equity...).",
    'activity4': "L’accompagnement vers la lever des fonds.",
    'activity5': "Organisation de formations sur des thèmes liés à l'investissement et au financement des projets.",
    'activity6': "Réalisation des activités d'évaluation de projets d'investissement.",
    'activity7': "L'organisation des rencontres entre des investisseurs potentiels et les PME et les startups Tunisiennes.",

    // Partners
    '': "",

    // Validation messages
    'required': "*obligatoire",
    'requiredCorrectEmail': "*merci de remplir votre email correctement",
    'requiredCorrectPhoneNumber': "*doit être un numéro de téléphone",
    'requiredCorrectPhoneNumber1': "*doit être un numéro de téléphone valide",
    'numberRequired': "*doit être un numéro",
    'requiredValideNumber': "*doit être nombre valide",
    'requiredUsedEmail': "*email déja utilisée",

    // Startup Step zero
    'startupStepZeroTitle1': "Vous souhaitez lever des fonds pour votre projet ?",
    'startupStepZeroTitle2': "Votre aventure commence ici", 
    'startupStepZeroText1': "Vous portez un projet à fort potentiel de croissance, vous êtes à la recherche de fonds pour votre entreprise ?",
    'startupStepZeroText2': "Vous souhaitez accélérer le développement de votre entreprise et maximiser votre impact et accéder aux financements ainsi qu’un accompagnement personnalisé par des Business Angels expérimentés ?", 
    'startupStepZeroText3': "Grace à DAMYA, vous accédez à un mentoring personnalisé de haut niveau et/ou un financement pour assurer la réussite de votre projet.",

    // Startup Step one
    'formStep1': "ÉTAPE 01",
    'founder': "Fondateur / co-Fondateur",
    'teamMember': "Membre de l'équipe",
    // 'startupFromWarning': "Avant de commencer, assure-toi d'avoir en main une présentation PPT ou PDF de ton entreprise.",
    'lastName': "Nom",
    'firstName': "Prénom",
    'gender': "Genre",
    'male': "Homme",
    'female': "Femme",
    'other': "Autre",
    'email': "Adresse email",
    'phone': "Numéro de téléphone",
    'position': "Votre position dans l'entreprise",
    'startupName': "Nom de la startup",
    'businessCreation': "Date de création de l'entreprise",
    'projectDefinition': "Définition du projet en une seule phrase",

    // Startup Step two
    'formStep2': "ÉTAPE 02",
    'eCommerce': "E-commerce",
    'technology': "Technologie",
    'industry': "Industrie",
    'Agriculture': "Agriculture",
    'communication': "Communication",
    'service': "Service",
    'marketCustomerNeeds': "Analyse du marché et des besoins des clients",
    'productServiceDesign': "Conception du produit ou du service",
    'testPhase': "Phase de test sur les premiers clients",
    'startupMarketing': "Commercialisation",
    'businessDevelopment': "Développement de l'activité (phase de croissance)",
    'activitySector': "Secteur d'activité",
    'projectDescription': "Description détaillée du projet (services, produits commercialisés...)",
    'startupAct': "Vous avez le Label Startup Act ?",
    'projectStage': "A quel stade en êtes-vous dans le développement de votre projet ?",
    'capital': "Votre capital en Dinars",
    'funding': "Votre besoin en financement en Dinars",
    'stageFunds': "Disposez-vous de fonds à ce stade ?",
    'turnover': "Votre chiffre d'affaire pour l'année 2020 ?",

    // Startup Step three
    'formStep3': "ÉTAPE 03",
    'membersNumber': "Combien de personnes êtes-vous dans l'équipe ?",
    'femalesNumber': "Combien de femmes êtes-vous dans l'équipe ?",
    'impact': "Considérez vous que votre projet a un impact positif sur la société ?",
    'positifImpact': "Quel est votre impact positif ?",
    'projectCreativity': "En quoi consiste votre créativité dans ce projet ?",
    'joinDamyaBusinessAngels': "Pourquoi souhaitez-vous rejoindre Damya Business Angels ?",
    'supportProgram': "Postulez-vous en parallèle à programme d'accompagnement ?",
    'notesComments': "Remarques et commentaires éventuels",
    'suggestCVDownload': "Veuillez télécharger votre curriculum vitae",
    'clickDownload': "Cliquez pour télécharger",
    'requiredWordFile': "*Seul fichier word accepté",
    'suggestDocDownload': "Veuillez télécharger vos documents",
    'requiredImage':'*Seul image accepter',

    // Startup thanking Step
    'stepThx': "Merci !",
    'startupStepThx1': "Votre candidature a été bien reçue",
    'programDiscover': "Découvrir Notre Programme",
    'backHome': "Revenir à l'acceuil",

    // Investor Step zero
    'investorStepZeroTitle': "Qu’est-ce qu’un Damya angel ?",
    'investorStepZeroText1': "Les Business Angels investissent au capital des PME et startups, innovantes, à fort potentiel de croissance.",
    'investorStepZeroText2': "À la différence des autres acteurs du capital-risque, nos membres n'apportent pas seulement aux entrepreneurs un financement mais aussi de conseil, de l’expérience et du réseautage",
    'investorStepZeroText3': "Les membres du réseau Damya Business Angels sont aussi des mentors et des experts de l’écosystème entrepreneurial Tunisien qui consacrent leur temps à l'accompagnement !",

    // Investor Step one
    'chefEntreprise': "Chef d'entreprise/ Manager",
    'employee': "Salarié(e)",
    'retired': "Retraité(e)",
    'liberalProfession': "Profession libérale",
    'consultant': "Consultant(e)",
    'finance': "Gestion / Comptabilité, Finance",
    'direction': "Management / Direction générale",
    'marketing': "Marketing / Communication",
    'humanResource': "Ressource humaine / Formation",
    'Accompaniement': "Conseil / Accompagnement",
    'rse-ess-finance': "RSE / ESS, Finance",
    'jural': "Juridique / Fiscalité",
    'production': "Production / Opération, R&D",
    // 'investorFromWarning': "Avant de commencer, assure-toi d'avoir en main une présentation PPT ou PDF de ton entreprise.",
    'usedEmail': "email déja utilisée",
    'professionalSituation': "Actuellement vous êtes",
    'skillsTraining': "Compétences et formation",
    'currentBusinessOrganization': "Entreprise ou organisation actuelle",
    'dominantSunctionalSkills': "Compétences fonctionnelles dominantes",
    'selectIem': "Choisissez...",

    // Investor Step two
    'lessThan5000': "Moins de 5000 DT",
    'from5000': "À partir de 5000 DT",
    'between5000and10000': "Entre 5000 DT et 10000 DT",
    'moreThan10000': "Plus de 10000 DT",
    'moreThan20000': "Plus de 20000 DT",
    'experience': "Nombre d'année d'expérience dans votre domaine",
    'investExperience': "Expérience d'investissement direct en entreprise ?",
    'investExperienceField': "Expérience d'investissement dans quel domaine et quelle entreprise ?",
    'investCapacity': "Quel ticket êtes-vous en capacité d'investir dans une entreprise ?",
    'investActivitySector': "Secteurs d'activité dans lesquels vous aimeriez investir",
    // Investor Step three
    'animation': "Animation du réseau",
    'projectStudy': "Recherche et étude des projets",
    'workGroup': "Groupe de travail divers",
    'nonDisponible': "Non disponible",
    'disponibilityFct': "En fonction de mes disponibilité",
    'availability': "Disponibilité pour participer à l'organisation du réseau",
    'interestCenter': "Centre d'intérêt et autres engagements",
    // Investor Thanking Step 
    'investorStepThx1': "Vos informations ont bien été reçues.",

    // Login Form
    'loginMemberSpace': "Espace Membre.",
    'login': "Login",
    'mdp' : "Mot de passe",
    'connexion' : "Connexion",
    'mdpoublié' : "Mot de passe oublié ?",
    'cliquezici' : "Cliquez ici",

    // Forget password
    'mdpoublié2': "Mot de passe oublié !",
    'instruction': "Entrez votre adresse mail pour recevoir les instructions de réinitialisation de mot de passe.",
    'adressemail' : "adresse e-mail",
    'confirmer' : "Confirmer",

    // Contactez nous
    'titre': "Contactez-nous",
    'nometprenom': "Prénom et nom",
    'emailc' : "Email",
    'message' : "Votre message",
    'btnenvoyer' : "Envoyer",

    // Investor Page
    'next': "Suivant",
    'previous': "Précedant",
    'submit': "Submit",
    'become': "Devenir",
    'damyaAngel': "Damya Angel",
    'return': "Retour",

    // Startup Page
    'submitProject': "Déposer votre projet",
    'project': "un projet",

    // Login admin
    'loginAdminSpace':'Espace Admin .',

    //damya numbers
    // 'number':'nombres',
    // 'ofcoachedstartups':'des Startups Coachées',
    // 'offundedstartups':'des Startups Financées',
    // 'oftraining':'des Formations',
    // 'ofpartners':'des Partenaires',
    // 'ofevents':'des Événement',

    //member space
    'replace':'Activer les modifications',
    'profileModification':'modifiez le profile',
    'personalInfo':'Informations personnelle',
    'documents':'Documents',
    'returnAcceuil': 'Retour vers l’accueil',
    'save': 'Enregistrer',
    'skillsTrainingBio': "Courte biographie (compétences et formation)",
    };


  export default messagesFr;