import React, {Component} from 'react';
import LoaderGif from '../assets/img/loader.gif'

class FullPageLoader extends Component {
    state = {  }


    render() { 
      

        return ( 
            <div class="loader-container">
                <div className="loader">
                    <img src={LoaderGif} />
                </div>
            </div>
         );
    }
}



export default (FullPageLoader);