import React from 'react';
import MainRoute from "./Routes/MainRoute"
import 'antd/dist/antd.css'
import "../src/assets/en.css"
import "../src/assets/fr.css"
import "./lang/en"
import "./lang/fr"




function App() {
  return (
    <div>
     <MainRoute/>
    </div>
  );
}

export default App;
