import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload} from 'antd';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"
import isURL from 'validator/es/lib/isURL';


class AddVideo extends Component {

    constructor(props) {
    super(props);
    this.state = {
      userLanguage:'',
      loading:false,      
      videoData:{url:'',title_en:'',title_fr:'',content_en:'',content_fr:'',
         validation:{error:[true,true,true,true,true],errorMsg:
         ['*required',
         '*required',
         '*required',
         '*required',
         '*required',
         ]}},
          videoError:[true,true,true,true,true],
          videoErrorMsg:['','','','',''],
        };
    }
    
    componentDidMount(){
        window.scrollTo(0, 0)
    }

    onChangeVideo=(value,key,index)=>{
    let aux ={...this.state.videoData}
      aux[key]=value
      if(key=="url"){
        if(!isURL(value)){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='Veuillez saisir une URL valide.'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="title_fr"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="title_en"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }    
      if(key=="content_fr"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="content_en"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }    
      
      const action ={type:"SET_SLIDER_DATA", value:aux}
      this.props.dispatch(action);
      console.log(aux);
      this.setState({videoData:aux})
  }

  handleVideoForm =async()=>{
      const ERROR = [...this.state.videoData.validation.error]
      const ERROR_MSG=[...this.state.videoData.validation.errorMsg]
      this.setState({
        videoError:ERROR,
        videoErrorMsg:ERROR_MSG
      })
      if(!this.state.videoData.validation.error.includes(true)){
      this.setState({loading:true})
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const video = this.state.videoData
      let formdata =new FormData()
      formdata.append('url',video.url)
      formdata.append('title_fr',video.title_fr)
      formdata.append('title_en',video.title_en)      
      formdata.append('content_fr',video.content_fr)
      formdata.append('content_en',video.content_en)      
      
      const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata
      };
      const data = await fetch(apiURL+'addVideo',requestOptions);
      console.log("here",data);
      const dataJson = await data.json();
      console.log("here2",dataJson);
      if(data.status == 201){
        console.log("success");
        this.setState({loading:false})
        window.location='/admin/videos'
      }
    }
  }

    render() {
     
        return (
            <>
            
                <h1 id='title'>Ajouter une Vidéo</h1>
                
                <Row align="center">
                        <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                    <Form
                    name="basic"
                    layout="vertical"
                    >
                    
                    <Form.Item
                        label="Video"
                        name="url"
                        >
                        <Input onChange={(e)=>this.onChangeVideo(e.target.value,'url',0)} value={this.state.videoData.url} />
                        {/* <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).required}</span> */}
                        {this.state.videoError[0]&&<div style={{color:'red'}}>{this.state.videoErrorMsg[0]}</div>} 
                    </Form.Item>   
                    <Form.Item
                        label="titre en francais"
                        name="title_fr"
                        >
                        <Input onChange={(e)=>this.onChangeVideo(e.target.value,'title_fr',1)} value={this.state.videoData.title_fr} />
                        {this.state.videoError[1]&&<div style={{color:'red'}}>{this.state.videoErrorMsg[1]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="titre en anglais"
                        name="title_en"
                        >
                        <Input onChange={(e)=>this.onChangeVideo(e.target.value,'title_en',2)} value={this.state.videoData.title_en} />
                        {this.state.videoError[2]&&<div style={{color:'red'}}>{this.state.videoErrorMsg[2]}</div>} 
                    </Form.Item>                               
                    <Form.Item
                        label="contenu en francais"
                        name="content_fr"
                        >
                        <Input.TextArea onChange={(e)=>this.onChangeVideo(e.target.value,'content_fr',3)} value={this.state.videoData.content_fr} />
                        {this.state.videoError[3]&&<div style={{color:'red'}}>{this.state.videoErrorMsg[3]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="contenu en anglais"
                        name="content_en"
                        >
                        <Input.TextArea onChange={(e)=>this.onChangeVideo(e.target.value,'content_en',4)} value={this.state.videoData.content_en} />
                        {this.state.videoError[4]&&<div style={{color:'red'}}>{this.state.videoErrorMsg[4]}</div>} 
                    </Form.Item>                   
                    <Button disabled={this.state.loading==true} onClick={this.handleVideoForm}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                        {getLanguage(this.props).submit} <RightOutlined />
                    </Button>
                    </Form>
                    </Col>
                    </Row>
                    {this.state.loading == true ? <FullPageLoader  />: null}
            </>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(AddVideo);