import React, { useState, useEffect } from "react";
import { Row, Col, Input, Form, Button, notification } from 'antd';
import { connect } from "react-redux"
import tel from '../../assets/img/tel.png'
import mail from '../../assets/img/mail.png'
import adresse from '../../assets/img/adresse.png'
import { RightOutlined } from '@ant-design/icons';
import ReactMapboxGl, { Layer, Feature, Marker } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import getLanguage from "../../utilFunction/utilFunction";
import ReactMapGL from 'react-map-gl';
import Aos from "aos";
import "aos/dist/aos.css";
import { apiURL } from "../../Config/Config";
import isEmail from "validator/lib/isEmail";
import mapImage from '../../assets/img/map.png'
  
const Map = ReactMapboxGl({
    accessToken:
      'pk.eyJ1IjoiZGFteWFhIiwiYSI6ImNrcmYxOXgxMDVzaHMydnFwYXd0OGN4b2cifQ.yow5su4gKeoxPyiqnGxR5g'
  });

const ContactezNous =(props)=>{    

    const [step, setStep] = useState({nom:'',email:'',text:'', validation:{error:[true,true,true], errorMsg:["required","required","required"]}});
    const [stepError, setStepError] = useState([true,true,true]);
    const [stepErrorMsg, setstepErrorMsg] = useState(['','','']);
    const [errorName, setErrorName] = useState('')
    const [errorEmail, setErrorEmail] = useState('')
    const [errorText, setErrorText] = useState('')





    useEffect(() => {
        Aos.init({
            duration : 1000,
            delay: 0,
            once: true,
            disable: 'mobile'
        }); 
        window.scrollTo(0, 0)
        
    }, []);

    const [viewport, setViewport] = useState({
        latitude: 36.8023,
        longitude:  10.1792,
        zoom: 8
      });





    const onChangeStepOneData=(value,key,index)=>{
        let aux ={...step}
            aux[key]=value
            if(key=="nom"){
              if(value.trim()===''){
                aux.validation.error[index]=true
                aux.validation.errorMsg[index]='required'
              }else{
                aux.validation.error[index]=false
                aux.validation.errorMsg[index]=''
              }
            }
            
            
            if(key=="email"){
              if(value.trim()===''){
                aux.validation.error[index]=true
                aux.validation.errorMsg[index]='required'
              }
              if(!isEmail(value)){
            
            aux.validation.error[index]=true
            aux.validation.errorMsg[index]='verifier que vous avez saisir une adresse email valide'
          }
              else{
                aux.validation.error[index]=false
                aux.validation.errorMsg[index]=''
              }
            }
            if(key=="text"){
                if(value.trim()===''){
                  aux.validation.error[index]=true
                  aux.validation.errorMsg[index]='required'
                }else{
                  aux.validation.error[index]=false
                  aux.validation.errorMsg[index]=''
                }
              }
            console.log(aux);
            setStep(aux)
        }

        const submitForm =async()=>{
            const ERROR = [...step.validation.error]
            const ERROR_MSG=[...step.validation.errorMsg]
            setStepError(ERROR)
            setstepErrorMsg(ERROR_MSG)
            
            if(!step.validation.error.includes(true)){
                            let formdata =new FormData()
                            formdata.append('nom',step.nom)
                            formdata.append('email',step.email)
                            formdata.append('text',step.text)
                    
                            const requestOptions = {
                              method: 'POST',
                              body: formdata
                            };
                          
                              const data = await fetch(apiURL+'addContact',requestOptions);
                              const dataJson = await data.json();
                              console.log("here2",dataJson);
                              if(data.status == 201){ 
                                notification.success({
                                    message: `success `,
                                    description:
                                    'votre remarque a été envoyer',
                                })
                                setTimeout(() => {
                                    window.location.reload()
                                }, 1000);
                                
                              }
            }
        }

    return(
        <div className="contacteznous-form-container">
            <Row className="contacteznous-title" data-aos="fade-up">{getLanguage(props).titre}</Row>
            <div className="contact-info" data-aos="fade-in">
            <span className="contacteznous-text"><img src={tel} className="contacteznous-icon"/>
                +216 24 713 711 </span>
            <span className="contacteznous-text"><img src={mail} className="contacteznous-icon"/>
                Network@damyaangels.org.tn </span> 
            <span className="contacteznous-text"><img src={adresse} className="contacteznous-icon"/>
                3 Rue Kawakibi, Tunis 1002 </span> 
            </div>
        <div className="contacteznous-card">
            <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos="fade-right" className="contacteznous-form">
                <p className="contact-form-title">Envoyez-nous un message</p>
                <Form name="basic" layout="vertical" className="contact-form">
                <Form.Item
            label={getLanguage(props).nometprenom}
            name="lastFirstName"
          >
       

        <Input onChange={(e)=>onChangeStepOneData(e.target.value,'nom',0)} value={step.nom}  />
        {errorName&&<div style={{color:'red'}}>{errorName}</div>}
        {stepError[0]&&<div style={{color:'red'}}>{stepErrorMsg[0]}</div>}


        </Form.Item>
        <Form.Item
            label={getLanguage(props).emailc}
            name="email"
          >
        <Input onChange={(e)=>onChangeStepOneData(e.target.value,'email',1)} value={step.email} />
        {stepError[1]&&<div style={{color:'red'}}>{stepErrorMsg[1]}</div>}
        </Form.Item>
        <Form.Item
            label={getLanguage(props).message}
            name="message"
          >
        <Input.TextArea onChange={(e)=>onChangeStepOneData(e.target.value,'text',2)} value={step.text} className="contact-text-area"/>
        {stepError[2]&&<div style={{color:'red'}}>{stepErrorMsg[2]}</div>}
        </Form.Item>
        <Row justify="center">
        <Button onClick={submitForm}  className="btn-contact">
        {getLanguage(props).btnenvoyer} <RightOutlined className="arrow-contact"/>
        </Button>
        </Row>
      </Form>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} data-aos="fade-left" className="contact-map-height">
                    {/* <Map
                    style="mapbox://styles/mapbox/streets-v9"
                    containerStyle={{
                        height: '100%',
                        width: '100%'
                    }}
                    center={[ 10.1792 , 36.8023 ]}
                    >
                    <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
                        <Feature coordinates={[ 10.1792 , 36.8023 ]} />
                    </Layer>
                    <Marker
                        coordinates={[10.1792, 36.8023]}
                        anchor="bottom">
                        
	                </Marker>
                    </Map> */}
                        {/* <ReactMapGL
                        {...viewport}
                        width="100%"
                        height="100%"
                        onViewportChange={(viewport) => setViewport(viewport)}
                        mapboxApiAccessToken={ 'pk.eyJ1IjoiZGFteWFhIiwiYSI6ImNrcmYxOXgxMDVzaHMydnFwYXd0OGN4b2cifQ.yow5su4gKeoxPyiqnGxR5g'}
                        /> */}
                        <img src={mapImage} width={'100%'} height={'100%'} />
                </Col>
            </Row>
           
        </div>
        </div>


    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(ContactezNous);