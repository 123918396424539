import React, { Component } from "react";
import InvestorForm from './investor/InvestorForm';
// import StepTwo from './investor/StepTwo';
// import StepThree from './investor/StepThree';
import { Row, Col, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { connect } from "react-redux"
import { Link } from 'react-router-dom'
import investorFormBackground from '../assets/img/investor-form-background.png'
import arrowLeft from '../assets/img/arrow-left.png'
import {apiURL} from '../Config/Config'
import StepZero from "./investor/StepZero";
import flechCercle from "../assets/img/flech-cercleee.png"
import FullPageLoader from "./FullPageLoader";
import StepsThxInvestor from "./investor/StepsThxInvestor";
import isEmail from 'validator/lib/isEmail';
import getLanguage from "../utilFunction/utilFunction"


class Investor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userLanguage:'',
      existEmail:false,
      current:0,
      loading:false,
      investorFormData:{nom:'',prenom:'',email:'',tel:'',sPle:'',eOAlle:'',cFDtes:'',nAEce:'',eIDEse:'',tCIEse:'',sAIir:'',rDBA:'',dPORsau:'',CIAEents:'',RCEels:'',
         validation:{error:[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],errorMsg:
         [getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,]}},
          investorFormError:[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
          investorFormErrorMsg:['','','','','','','','','','','','','','','',''],         
        };
  }
  componentDidMount(){
    window.scrollTo(0, 0)
  }

  verifEmail =async(value)=>{
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let formdata =new FormData()
    formdata.append('email',value)
    const requestOptions = {
      method: 'POST',      
      body: formdata
    };
    const data = await fetch(apiURL+'verifyUser',requestOptions);
     const dataStatus = await data.status;
    console.log("here2",dataStatus);
    if(dataStatus !== 200){
      console.log("success");
      this.setState({existEmail:true})
    }
  }

  onChangeInvestorFormData=(value,key,index)=>{
  let aux ={...this.state.investorFormData}
      aux[key]=value
      if(key=="nom"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="prenom"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="email"){
        console.log(!isEmail(value));
        
        this.setState({existEmail:false})
        this.verifEmail(value)
        if(this.state.existEmail == true){
          
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='*email déja utilisée'
        }
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }if(!isEmail(value) ==true){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).requiredCorrectEmail
        }
        else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
        
      }
      if(key=="tel"){
        
        if(value==undefined){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }
       
        if(!isNaN(Number(value)) ==false){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).requiredCorrectPhoneNumber
        }
        // if(value.length > 8 || value.length < 8){
        //   aux.validation.error[index]=true
        //   aux.validation.errorMsg[index]=getLanguage(this.props).requiredCorrectPhoneNumber1
        // }
        else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="sPle"){
        if(value.length == 0){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }      
      if(key=="eOAlle"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="cFDtes"){
        console.log("value",value);
        if(value.length == 0){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="nAEce"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }if(!isNaN(Number(value)) ==false){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).requiredValideNumber
        }
        else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="eIDEse"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }      
      if(key=="tCIEse"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      // if(key=="cDirs"){
      //   if(value.trim()===''){
      //     aux.validation.error[index]=true
      //     aux.validation.errorMsg[index]=getLanguage(this.props).required
      //   }else{
      //     aux.validation.error[index]=false
      //     aux.validation.errorMsg[index]=''
      //   }
      // }
      if(key=="sAIir"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="rDBA"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="dPORsau"){
        if(value.length == 0){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="CIAEents"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="RCEels"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      const action ={type:"SET_INVESTOR_Form", value:aux}
      this.props.dispatch(action);
      console.log(aux);
      this.setState({investorFormData:aux})
  }

  

  // submit=()=>{
  //     console.log('hello');
  //     if(this.state.current===1){
  //       const ERROR = [...this.state.investorFormData.validation.error]
  //       const ERROR_MSG=[...this.state.investorFormData.validation.errorMsg]
  //       this.setState({
  //         investorFormError:ERROR,
  //         investorFormErrorMsg:ERROR_MSG
  //       })
  //       if(!this.state.investorFormData.validation.error.includes(true)){
  //         this.setState({current: this.state.current+1})
  //       }
  //     }
  // }

  handleRegisterForm =async()=>{
      if(this.state.current === 1){
        const ERROR = [...this.state.investorFormData.validation.error]
        const ERROR_MSG=[...this.state.investorFormData.validation.errorMsg]
        this.setState({
          investorFormError:ERROR,
          investorFormErrorMsg:ERROR_MSG
        })
        if(!this.state.investorFormData.validation.error.includes(true)){
          this.setState({loading:true})
        
      this.setState({loading:true})
      const myHeaders = new Headers();
      console.log('step1', this.state.investorFormData.nom);      
      myHeaders.append("Content-Type", "application/json");
      const investorForm = this.state.investorFormData
      let formdata =new FormData()
      formdata.append('nom',investorForm.nom)
      formdata.append('prenom',investorForm.prenom)
      formdata.append('email',investorForm.email)
      formdata.append('phone',investorForm.tel)
      formdata.append('situation_professionnelle',investorForm.sPle)
      // formdata.append('competences_formation',investorForm.cFon)
      formdata.append('entreprise',investorForm.eOAlle)
      formdata.append('competences_dominantes',investorForm.cFDtes)
      formdata.append('annee_experience',investorForm.nAEce)
      formdata.append('experience_investissement',investorForm.eIDEse)
      // formdata.append('experience_domaine',investorForm.eInt)
      formdata.append('ticket',investorForm.tCIEse)
      formdata.append('secteur_activite',investorForm.sAIir)
      formdata.append('cause_rejoint',investorForm.rDBA)
      formdata.append('disponibilite',investorForm.dPORsau)
      formdata.append('centre_interet',investorForm.CIAEents)
      formdata.append('remarques_commentaires',investorForm.RCEels)
      
      const requestOptions = {
        method: 'POST',        
        body: formdata
      };
      const data = await fetch(apiURL+'addUser',requestOptions);
      console.log("here",data);
      const dataJson = await data.json();
      console.log("here2",dataJson);
      if(data.status == 201){
        console.log("success");
        this.setState({loading:false, current:2})        
      }
    }
      }
  }

  nextStep=()=>{
    this.setState({current: this.state.current+1})
  }

  btnSubmit = () => {
    if(this.state.current == 0){
    return (
      <div>
        <Row>
          <Col className="btn-step-zero-section" xs={24} sm={24} md={24} lg={24} xl={24} className="az">
            <div onClick={this.nextStep} className={this.props.lang == "fr"?this.props.lang+"_btn-next-step-zero":this.props.lang+"_btn-next-step-zero"}>
            <Row className="content-btn-step-zero"><Col xs={20} sm={20} md={20} lg={20} xl={20}>{getLanguage(this.props).DevenirDamyaAngel}</Col><Col xs={4} sm={4} md={4} lg={4} xl={4}><img className="image-btn-step-zero" src={flechCercle} width={"100%"} /></Col></Row> 
            </div>
          </Col>
        </Row>    
      </div>
      )
    }
    else{
    return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={21} xl={21}>          
            <Button disabled={this.state.loading==true} onClick={this.handleRegisterForm} type="link"  htmlType="submit" className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
              {getLanguage(this.props).submit} <RightOutlined />
            </Button>
          </Col>
      </Row>    
    </div>
    )
  }
  }
  
    render() {
      console.log(this.state);
        return (
          
            <div className={this.props.lang == "fr"?this.props.lang+"_investor-form-container":this.props.lang+"_investor-form-container"}>
                <img src={investorFormBackground} className={this.props.lang == "fr"?this.props.lang+"_investor-form-fixed-background-image":this.props.lang+"_investor-form-fixed-background-image"} />
                <span className={this.props.lang == "fr"?this.props.lang+"_fixed-background-image-text":this.props.lang+"_fixed-background-image-text"}>{getLanguage(this.props).become}<br />{getLanguage(this.props).damyaAngel}</span>
                <Link to="/">
                <div className={this.props.lang == "fr"?this.props.lang+"_fixed-investor-vector":this.props.lang+"_fixed-investor-vector"}>
                  <img src={arrowLeft} className={this.props.lang == "fr"?this.props.lang+"_fixed-left-arrow":this.props.lang+"_fixed-left-arrow"} />
                  <h3 className={this.props.lang == "fr"?this.props.lang+"_fixed-oriented-text":this.props.lang+"_fixed-oriented-text"}>{getLanguage(this.props).return}</h3>
                </div>
                </Link>
             
                <div className={ this.props.lang == "fr"?this.props.lang+"_investor-form-background":this.props.lang=="en"? this.props.lang+"_investor-form-background":null}>
                  
                  {this.state.current == 0 ? <StepZero />:null}
                  {this.state.current == 1 ?<InvestorForm onChangeInvestorFormData={this.onChangeInvestorFormData}
                  investorFormData={this.state.investorFormData}
                  investorFormError={this.state.investorFormError}
                  investorFormErrorMsg={this.state.investorFormErrorMsg}
                  existEmail={this.state.existEmail} />  : null}                
                  {this.state.loading == true ? <FullPageLoader  />: null}
                  {this.state.current == 2 ? <StepsThxInvestor /> : null}
                  {this.state.current !==2 ? this.btnSubmit():null}
{console.log(this.state.current,'current')}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch: (action) => {
        dispatch(action);
      },
    };
  };
  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      lang:state.auth.lang
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Investor);