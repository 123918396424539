import React, { Component } from "react";
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { connect } from "react-redux"
import { HashLink as Link } from 'react-router-hash-link';
import getLanguage from "../../utilFunction/utilFunction";
import "aos/dist/aos.css";
import { apiURL } from '../../Config/Config';
import 'react-quill/dist/quill.snow.css';
// import "react-quill/dist/quill.core.css";
import Moment from 'react-moment';
import getAllActualities from '../../utilFunction/showAllActualities';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import carouselRight from '../../assets/img/carousel-right.png';
import { Parser } from 'html-to-react'


const ActualityNextArrow = ({ onClick }) => {
    return (
    <div className="arrow actuality-next" onClick={onClick}>
        <img className="arrow-size" src={carouselRight} />
    </div>
    );
};
     
const settings = {
    infinite: false,
    lazyLoad: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    dots: true,    
    centerPadding: 0,
    nextArrow: <ActualityNextArrow />,
    prevArrow: <ActualityNextArrow/>,
    responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
};
class SingleActuality extends Component {
    constructor(props) {
        super(props);
        this.state = {                                
            actualityData:[''],
            data:[''],            
    };
    }

    componentDidMount(){        
        getAllActualities(this.props).then((e)=>{
          this.setState({actualityData: e})
          console.log(this.state.actualityData,'testr');
        })
        window.scrollTo(0, 0)
        this.getData()
    }

    componentDidUpdate(prevProps) {
        if((this.props.match.params.id != prevProps.match.params.id)) 
            {
                getAllActualities(this.props).then((e)=>{
                this.setState({actualityData: e})
                console.log(this.state.actualityData,'testr');
                })
                window.scrollTo(0, 0)
                this.getData()
            }
        } 

    // componentWillUnmount() {
    //     getAllActualities(this.props).then((e)=>{
    //       this.setState({actualityData: e})
    //       console.log(this.state.actualityData,'testr');
    //     })
    //     this.getData()
    // }

    getData=async()=>{
    const requestOptions = {
        method: 'GET',
    };
    const res =  await fetch(apiURL+"getActuality/"+this.props.match.params.id, requestOptions);
    if(res.status == 200){
        const resJson = await res.json();
        this.setState({data: [resJson]})
    }            
    }

    render() {       
            return (  
            <>
            <div>
                {this.state.data.length>0 && Object.keys(this.state.data[0]).length !== 0 && this.state.data.map((act)=>(                    
                    <div className="single-actuality-section">                                                    
                        <div className="single-actuality-inner-section">
                            <span className="single-actuality-date"><Moment format="DD/MM/YYYY">{act.creationDate}</Moment></span>
                            <h2 className="single-actuality-title">{this.props.lang == "fr" ? act.titleFr : act.titleEn} </h2>
                            <img src={apiURL+act.image.replace('/public/', '')} className="single-actuality-img" />                            
                        </div>                        
                        {/* <p className="single-actuality-content">{this.props.lang == "fr" ? act.contentFr: act.contentEn}</p> */}
                        <div className="ql-snow" style={{paddingTop:60}}>
                        <div className="ql-editor">
                        {Parser().parse(this.props.lang == "fr" ? act.contentFr: act.contentEn)}
                        </div>
                        </div>
                        <div className="single-actuality-slider">
                            <h4 className="single-actuality-more-news">Plus d'actualité</h4>                            
                             {/* <RenderActuality actualityId={this.props.match.params.id}/>  */}
                             <Slider {...settings}>
                             {this.state.actualityData.length>0 && Object.keys(this.state.actualityData[0]).length !== 0 && this.state.actualityData.map((acts)=>(                    
                                acts.id != this.props.match.params.id ?
                                <div>
                                    <div className="single-actuality-card">
                                    <img src={apiURL+acts.image.replace('/public/', '')} className="actuality-img" />
                                    <div className="single-actuality-card-content">
                                    {/* <span className="new-single-actuality">Nouvel</span> */}
                                    <p className="actuality-date"><Moment format="DD/MM/YYYY">{acts.creationDate}</Moment></p>
                                    <h2 className="actuality-title">{this.props.lang == "fr" ? acts.titleFr.substring(0,30) : acts.titleEn.substring(0,30)} ..</h2>
                                    <p className="single-actuality-text"> {this.props.lang == "fr" ? acts.excerptFr.substring(0, 100) : acts.excerptEn.substring(0, 100)}...</p>
                                    <Link to={`/actuality/${acts.id}`}>
                                        <Button className="actuality-btn">
                                        {getLanguage(this.props).knowMore} <RightOutlined className="actuality-btn-arrow"/>
                                        </Button>
                                    </Link>
                                    </div>
                                </div>            
                                </div>:''
                            ))} 
                        </Slider>
                        </div>
                        
                    </div>))}
                </div>
            </> 
            );
    }
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        lang:state.auth.lang
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(SingleActuality);