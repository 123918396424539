import React, { Component } from 'react';
import { Row, Col, Input, Form, Button, notification } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import getLanguage from "../utilFunction/utilFunction"
import { connect } from "react-redux"
import Aos from "aos";
import "aos/dist/aos.css";
import isEmail from "validator/lib/isEmail";
import { apiURL } from '../Config/Config';


class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
          email:null,
          password:null
        };
    }
    
    componentDidMount(){
    Aos.init({
      duration : 1000,
      delay: 0,
      once: true,
      disable: 'mobile'
    }); 
    window.scrollTo(0, 0)
  }

  resetPassword =async()=>{
    if(!isEmail(this.state.email)){
      this.setState({errorMsg:'verifier que vous avez saisir une adresse email valide'})
    }else{
      let formdata =new FormData()
      formdata.append('email',this.state.email)
      const requestOptions = {
        method: 'POST',
        body: formdata
    };
    const res =  await fetch(apiURL+"resetPassword", requestOptions);
    const angelDataJson = await res.json();
    if(res.status == 200){
        notification.success({
                    message: `success `,
                    description:
                    'Un email à été envoyer',
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
    }
    else{
      this.setState({errorMsg:'verifier votre adresse email'})
    }
    }
     
  }



    render() { 
        return ( 
            <div className={this.props.lang == "fr"?this.props.lang+"_login-form-container":this.props.lang+"_login-form-container"}>
        <Row align="center">
          <Col xs={24} sm={22} md={18} lg={10} xl={10}>
            <div className="login-card" data-aos="zoom-in">
              <h3 className="forgot-password-card-title">{getLanguage(this.props).mdpoublié2}</h3>
              <p className="forgot-password-text">{getLanguage(this.props).instruction}</p>
              <center> 
                <Row align="center">
                    <Col xs={22} sm={22} md={18} lg={18} xl={18}>
                        <Form
                            name="basic"
                            layout="vertical"
                            >   
                            <Input className="forgot-password-email" onChange={(e)=>this.setState({email:e.target.value})} placeholder={getLanguage(this.props).adressemail} name="email" />
                            <Row className="forgot-password-error">
                                {this.state.errorMsg&&<div style={{color:'red'}}>{this.state.errorMsg}</div>} 
                            </Row>
                            <Button onClick={this.resetPassword} className="btn-forgot-password">
                            {getLanguage(this.props).confirmer}<RightOutlined className="arrow-forgot-password"/>
                            </Button>
                        </Form>   
                    </Col>
                </Row>
               </center>
            </div>
             </Col>
                  </Row>
        </div>
       
        );
            
            
    }
}
 
const mapDispatchToProps = (dispatch) => {
    return {
      dispatch: (action) => {
        dispatch(action);
      },
    };
  };
  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      lang:state.auth.lang
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);