import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload, Switch} from 'antd';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"

class editNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title_fr:'',title_en:'',number:'',
            idNumber:'',            
  
         validation:{error:[true,true,true],errorMsg:
         [getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,         
        ]},
          numberError:[true,true,true],
          numberErrorMsg:['','','']}
       
    }

    componentDidMount(){
        this.setState({idNumber:this.props.match.params.id})
        console.log(this.props.match.params.id);
        this.getNumberById()
    }
    getNumberById=async()=>{
        const requestOptions = {
            method: 'GET',
          };
          const data = await fetch(apiURL+'getNumber/'+this.props.match.params.id,requestOptions);
          const dataJson = await data.json();
          if(data.status == 200){
            console.log("success");
            this.setState({title_fr:dataJson.titleFr, title_en:dataJson.titleEn,
            number:dataJson.number});           
          }
    }

    numberValidation =() =>{
      let aux ={...this.state.validation}
        if(this.state.title_fr.trim()=='' ){
        
          aux.error[0]=true
          aux.errorMsg[0]=getLanguage(this.props).required
        }else{
            aux.error[0]=false
            aux.errorMsg[0]=''
        }
        if(this.state.title_en.trim()=='' ){
          
          aux.error[1]=true
          aux.errorMsg[1]=getLanguage(this.props).required
        }else{
            aux.error[1]=false
            aux.errorMsg[1]=''
        }
        if(isNaN(this.state.number) || this.state.number.trim()==''){
          aux.error[2]=true
          aux.errorMsg[2]=this.state.number.trim()==''?getLanguage(this.props).required:getLanguage(this.props).numberRequired
        }else{
            aux.error[2]=false
            aux.errorMsg[2]=''
        }
        this.setState({validation:aux})
    }
  
    updateNumber=async()=>{
      this.numberValidation()
         const ERROR = [...this.state.validation.error]
            const ERROR_MSG=[...this.state.validation.errorMsg]
            this.setState({
              numberError:ERROR,
              numberErrorMsg:ERROR_MSG
            })
        if(!this.state.validation.error.includes(true)){
        this.setState({loading:true})
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let formdata =new FormData()              
        formdata.append('title_fr',this.state.title_fr)
        formdata.append('title_en',this.state.title_en)
        formdata.append('number',this.state.number)        
        
        const requestOptions = {
          method: 'POST',
          // headers: myHeaders,
          body: formdata
        };
        console.log('aaaa',apiURL+'updateNumbers/'+this.state.idNumber,requestOptions);
        const data = await fetch(apiURL+'updateNumbers/'+this.state.idNumber,requestOptions);
        console.log("here",data);
        const dataJson = await data.json();
        console.log("here2",dataJson);
        if(data.status == 200){
          console.log("success");
          this.setState({loading:false})
          window.location='/admin/numbers'
        }
        }
    }

    render() { 
       
        return (  <>
            
            <h1 id='title'>Modifier la statistique</h1>
            
            <Row align="center">
                    <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                <Form
                name="basic"
                layout="vertical"
                >                                
                <Form.Item
                    label="titre en francais"
                    name="title_fr"
                    >
                    <Input onChange={(e)=>this.setState({title_fr: e.target.value})} value={this.state&&this.state.title_fr} />
                    {this.state.numberError[0]&&<div style={{color:'red'}}>{this.state.numberErrorMsg[0]}</div>}                </Form.Item>
                <Form.Item
                    label="titre en anglais"
                    name="title_en"
                    >
                    <Input onChange={(e)=>this.setState({title_en: e.target.value})} value={this.state&&this.state.title_en} />
                    {this.state.numberError[1]&&<div style={{color:'red'}}>{this.state.numberErrorMsg[1]}</div>}                </Form.Item>
                <Form.Item
                    label="nombre"
                    name="number"
                    >
                    <Input onChange={(e)=>this.setState({number: e.target.value})} value={this.state&&this.state.number} />
                    {this.state.numberError[2]&&<div style={{color:'red'}}>{this.state.numberErrorMsg[2]}</div>}                
                </Form.Item>                
                <Button disabled={this.state.loading==true} onClick={this.updateNumber}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                    {getLanguage(this.props).submit} <RightOutlined />
                </Button>
                </Form>
                </Col>
                </Row>
                {this.state.loading == true ? <FullPageLoader  />: null}
        </> );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
export default connect(mapStateToProps, mapDispatchToProps)(editNumber);