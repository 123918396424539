import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Image, Table, Modal, Input, Space  } from 'antd';
import { apiURL } from '../../../Config/Config';
import { Link } from 'react-router-dom';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';


const indexURL = apiURL+"getPartners"
const Partners = (props) => {
    const [data, setData] = useState([''])
    const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');
    const [idParners, setidParners] = useState('');
    const [visibleModal, setvisibleModal] = useState(false);


    useEffect(() => {
        getData()
    }, [])

    const getData=async()=>{
        const requestOptions = {
            method: 'GET',
        };
        const res =  await fetch(indexURL, requestOptions);
        if(res.status == 200){
            const resJson = await res.json();
            console.log('resJson',resJson);
            setData(resJson);
        }            
        }

        const renderHeader = () => {
            let headerElement = ['id','name', 'image','url', 'Action']
    
            return headerElement.map((key, index) => {
                return <th key={index}>{key.toUpperCase()}</th>
            })
        }
        const showModal = (id) => {
            console.log("id",id);
            setidParners(id)
            setvisibleModal(true);
          };
        const  closeModal=()=>{
            setvisibleModal(false);
          }

          const removeData =async (id) => {
              console.log("id",id);
            let formdata =new FormData()
            formdata.append('id',id)
            const requestOptions = {
              method: 'POST',
              // headers: myHeaders,
              body: formdata
            };
           
              const data = await fetch(apiURL+'deletePartners',requestOptions);
              const dataJson = await data.json();
              console.log("here2",dataJson);
              if(data.status == 200){
                console.log("success");
                setvisibleModal(false)
                window.location.reload();
              }
        }
        const addRedirection =()=>{
            window.location="/admin/add-Partners"
            }

    const  handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0])
        setsearchedColumn(dataIndex)
    };

    const  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
        //   ref={node => {
        //     searchInput = node;
        //   }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setsearchText(selectedKeys[0])
                setsearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
    });

    const  handleReset = clearFilters => {
        clearFilters();
        setsearchText('')
    };

    const columns =[
        {
            title: 'id',
            dataIndex: 'id',            
            width: 50,
            ...getColumnSearchProps('id'),             
        },        
        {
            title: 'Nom',
            dataIndex: 'name',                        
            ...getColumnSearchProps('name'),            
        },
        {
            title: 'Image',
            dataIndex: 'path',                        
            ...getColumnSearchProps('path'),             
            render: (val)=>(val&&val.length>0?<Image src={apiURL+val.replace('/public/', '')} width={50} height={50} />:""),            
        },
        {
            title: 'Lien',
            dataIndex: 'url',                        
            ...getColumnSearchProps('url'),
            render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")             
        },        
        {             
            title: 'action',                        
            render: (dataIndex) => (
            <div>                
                <Button size='small' type="danger" onClick={()=>showModal(dataIndex.id)}>Supprimer</Button>
            </div>
            ),            
        },
    ];    

    return (
        <>
        <h1 id='title'>Partenaires</h1>            
        <Row justify="center">              
                <Col>
                    <Button type="primary" style={{float:'right', marginBottom:20}}  onClick={() => addRedirection()}>Ajouter</Button>
                    <Table columns={columns} dataSource={data}
                    pagination={{ defaultPageSize: 10, showSizeChanger: true}}
                    />
                </Col>
            </Row>
            <Modal title="supprimer slider" visible={visibleModal} footer={null} mask={true} closable={false} maskClosable={true}>
            <p>veuillez confirmer votre choix</p>
            <Button onClick={()=>removeData(idParners)} >delete</Button>
            <Button onClick={()=>closeModal()} >cancel</Button>

      </Modal>
            </>
    )
}


export default Partners