import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Image,Table, Modal, Input, Space  } from 'antd';
import { apiURL } from '../../../Config/Config';
import { Link } from 'react-router-dom';
import getData from '../../../utilFunction/showAllActualities';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const indexURL = apiURL+"getAllActualities"
const deleteURL = apiURL+"api/deleteActuality"

const Actuality = (props) => {
    const [data, setData] = useState([''])
     const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [idActuality, setIdActuality] = useState('');
    const [visibleModal, setvisibleModal] = useState(false);


    useEffect(() => {
        getData(props).then((e)=>{
            setData(e)
        })
    }, [])

    const removeData =async (id) => {
        console.log("testtt",id);
        const requestOptions = {
            method: 'POST',
          };
          const data = await fetch(apiURL+'deleteActuality/'+id,requestOptions);
          const dataJson = await data.json();
          console.log("here2",dataJson);
          if(data.status == 200){
            console.log("success");
            setvisibleModal(false)
            window.location='/admin/actualities'
          }
    }

console.log('data',data);
    const renderHeader = () => {
        let headerElement = ['id', 'image', 'title fr', 'title en', 'excerpt fr', 'excerpt en', 'content fr', 'content en', 'social_link', 'meeting_link', 'Action']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const addRedirection =()=>{
    window.location="/admin/add-actuality"
    }
    const showModal = (id) => {
        setIdActuality(id)
        setvisibleModal(true);
      };
    const closeModal=()=>{
        setvisibleModal(false);
      }

    const  handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0])
        setsearchedColumn(dataIndex)
    };

    const  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
        //   ref={node => {
        //     searchInput = node;
        //   }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setsearchText(selectedKeys[0])
                setsearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

const  handleReset = clearFilters => {
    clearFilters();
    setsearchText('')
  };

    const columns =[
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            ...getColumnSearchProps('id'),
             fixed: 'left',
        },
        {
            title: 'Image',
            dataIndex: 'image',            
            ...getColumnSearchProps('image'),
            render: (val)=>(val&&val.length>0?<Image src={apiURL+val.replace('/public/', '')} width={50} height={50} />:""),            
        },
        {
            title: 'Titre en français',
            dataIndex: 'titleFr',
            // key: 'id',
            ...getColumnSearchProps('titleFr'),
            render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")
        },
        {
            title: 'Titre en anglais',
            dataIndex: 'titleEn',
            // key: 'id',
            ...getColumnSearchProps('titleEn'),
            render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")
        },
        {
            title: 'Extrait en français',
            dataIndex: 'excerptFr',
            // key: 'id',
            ...getColumnSearchProps('excerptFr'),
            render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")
        },
        {
            title: 'Extrait en anglais',
            dataIndex: 'excerptEn',
            // key: 'id',
            ...getColumnSearchProps('excerptEn'),
            render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")
        },
        {
            title: 'Contenu en français',
            dataIndex: 'contentFr',
            // key: 'id',
            ...getColumnSearchProps('contentFr'),
             render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")
        },
        {
            title: 'Contenu en anglais',
            dataIndex: 'contentEn',
            // key: 'id',
            ...getColumnSearchProps('contentEn'),
             render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")
        },
        {
            title: 'Social link',
            dataIndex: 'socialLink',            
            ...getColumnSearchProps('socialLink'),
            render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")
        },
        {
            title: 'Meeting link',
            dataIndex: 'meetingLink',        
            ...getColumnSearchProps('meetingLink'),
            render: (val)=>(val&&val.length>0?val.substring(0,50)+'...':"")
        },
        {             
            title: 'action',
            fixed: 'right',
            render: (dataIndex) => (
            <div style={{display:'flex', 'align-items':'flex-end', 'flex-direction': 'column', gap:3}}>
                <Link to={`/admin/editActuality/${dataIndex.id}`}>
                  <Button size='small' type="primary">Editer</Button>
                </Link>                
                <Button size='small' type="danger" onClick={()=>showModal(dataIndex.id)}>Supprimer</Button>
            </div>
            ),
        },
    ];

    return (
        <>
            <h1 id='title'>Actualités</h1>
            {/* <center>
                <button className='button' onClick={() => addRedirection()}>Add</button>
            </center> */}
            <Row justify="center">
                {/* <table id='employee'>
                    <thead>
                        <tr>{renderHeader()}</tr>
                    </thead>
                    <tbody>                    
                    {data.length>0 && Object.keys(data[0]).length !== 0 && data.map(({ id, image, titleFr, titleEn, excerptFr, excerptEn, contentFr, contentEn, socialLink, meetingLink }) => (
                        <tr key={id}>
                            <td>{id}</td>                    
                            <td>
                                <Image src={apiURL+image.replace('/public/', '')} width={50} height={50} />
                            </td>
                            <td>{titleFr.substring(0,10)}...</td>
                            <td>{titleEn.substring(0,10)}...</td>
                            <td>{excerptFr!==undefined?excerptFr.substring(0,10):''}...</td>
                            <td>{excerptEn!==undefined?excerptEn.substring(0,10):''}...</td>
                            <td>{contentFr.substring(0,10)}...</td>
                            <td>{contentEn.substring(0,10)}...</td>
                            <td>{socialLink.substring(0,10)}...</td>
                            <td>{meetingLink.substring(0,10)}...</td>
                            <td className='opration'>
                                <Link className='button' to={`/admin/editActuality/${id}`}>Edit</Link>
                                <button className='button' onClick={()=>showModal(id)}>Delete</button>
                            </td>
                        </tr>
                    ))
                                        }
                    </tbody>
                </table> */}
                <Col>
                    <Button type="primary" style={{float:'right', marginBottom:20}}  onClick={() => addRedirection()}>Ajouter</Button>
                    <Table columns={columns} dataSource={data} scroll={{ x: 1300 }}/>
                </Col>
            </Row>
            <Modal title="supprimer actuality" visible={visibleModal} footer={null} mask={true} closable={false} maskClosable={true}>
            <p>veuillez confirmer votre choix</p>
            <Button type="danger" onClick={()=>removeData(idActuality)} >delete</Button>
            <Button onClick={()=>closeModal()} >cancel</Button>

      </Modal>
        </>
    )
}


export default Actuality