import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload, Switch} from 'antd';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"


class AddSlider extends Component {

    constructor(props) {
    super(props);
    this.state = {
      userLanguage:'',
      loading:false,
      buttons:false,
      sliderData:{sliderImg:'',text_en:'',title_fr:'',title_en:'',text_fr:'',
         validation:{error:[true,true,true,false,false],errorMsg:
         ['*required',
         '*required',
         '*required',
         '*required',
         '*required',]}},
          sliderError:[true,true,true,false,false],
          sliderErrorMsg:['','','','',''],
        };
    }
    
    componentDidMount(){
        window.scrollTo(0, 0)
    }

    onChangeSlider=(value,key,index)=>{
    let aux ={...this.state.sliderData}
      aux[key]=value
      if(key=="sliderImg"){
        if(!value){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      // if(this.state.buttons==1){
      //   aux.validation.error[3]=false
      //   aux.validation.error[4]=false
      //   aux.validation.errorMsg[3]=''
      //   aux.validation.errorMsg[4]=''
      // }
      if(key=="title_fr"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="title_en"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]=getLanguage(this.props).required
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
    
      // if(key=="text_fr"){
      //       if(value.trim()==='' && this.state.buttons==0){
      //         aux.validation.error[index]=true
      //         aux.validation.errorMsg[index]=getLanguage(this.props).required
      //       }else{
      //         aux.validation.error[index]=false
      //         aux.validation.errorMsg[index]=''
      //       }
      //     }
      //     if(key=="text_en"){
      //       if(value.trim()==='' && this.state.buttons==0){
      //         aux.validation.error[index]=true
      //         aux.validation.errorMsg[index]=getLanguage(this.props).required
      //       }else{
      //         aux.validation.error[index]=false
      //         aux.validation.errorMsg[index]=''
      //       }
      //     }
      
      const action ={type:"SET_SLIDER_DATA", value:aux}
      this.props.dispatch(action);
      console.log(aux);
      this.setState({sliderData:aux})
  }

  handleSliderForm =async()=>{
      const ERROR = [...this.state.sliderData.validation.error]
      const ERROR_MSG=[...this.state.sliderData.validation.errorMsg]
      this.setState({
        sliderError:ERROR,
        sliderErrorMsg:ERROR_MSG
      })
      if(!this.state.sliderData.validation.error.includes(true)){
      this.setState({loading:true})
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const slider = this.state.sliderData
      let formdata =new FormData()
      formdata.append('sliderImg',slider.sliderImg)
      formdata.append('title_fr',slider.title_fr)
      formdata.append('title_en',slider.title_en)
      formdata.append('text_fr',slider.text_fr)
      formdata.append('text_en',slider.text_en)
      formdata.append('buttons',this.state.buttons)
      
      
      const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata
      };
      const data = await fetch(apiURL+'addSlider',requestOptions);
      console.log("here",data);
      const dataJson = await data.json();
      console.log("here2",dataJson);
      if(data.status == 201){
        console.log("success");
        this.setState({loading:false})
        window.location='/admin/sliders'
      }
    }
  }

    render() {
      const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };
      

        return (
            <>
            
                <h1 id='title'>Ajouter un carrousel</h1>
                
                <Row align="center">
                        <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                    <Form
                    name="basic"
                    layout="vertical"
                    >
                    
                    <Form.Item
                        label="Filename"
                        name="sliderImg"
                        >
                            <ImgCrop grid aspect={1.93/1}>   
                              <Upload
                              maxCount={1}
                              // onChange={onChange} 
                              onPreview={onPreview}
                              listType="picture-card"
                              // onChange={(info)=>console.log(info,'info')}
                              onChange={(info)=>{info.fileList.length>0 ?this.onChangeSlider(info.fileList[0].originFileObj,'sliderImg',0):this.onChangeSlider('','sliderImg',0)}}
                              value={this.state.sliderData.sliderImg}
                              accept=".png,.jpeg,.jpg"
                              className="upload-list-inline"
                              // beforeUpload={() => false}
                              >
                                <Button icon={<UploadOutlined />}>Upload</Button>
                              </Upload>
                            </ImgCrop>                        
                        <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).requiredImage}</span>
                        {this.state.sliderError[0]&&<div style={{color:'red'}}>{this.state.sliderErrorMsg[0]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="enable button"
                        name="buttons"
                        >
                        <Switch
                          checked={this.state.buttons}
                          checkedChildren="enabled"
                          unCheckedChildren="disabled"
                          onChange={(e) => {
                            this.setState({buttons:!this.state.buttons})
                            console.log(this.state.buttons,'button');
                            }
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="titre en francais"
                        name="title_fr"
                        >
                        <Input onChange={(e)=>this.onChangeSlider(e.target.value,'title_fr',1)} value={this.state.sliderData.title_fr} />
                        {this.state.sliderError[1]&&<div style={{color:'red'}}>{this.state.sliderErrorMsg[1]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="titre en anglais"
                        name="title_en"
                        >
                        <Input onChange={(e)=>this.onChangeSlider(e.target.value,'title_en',2)} value={this.state.sliderData.title_en} />
                        {this.state.sliderError[2]&&<div style={{color:'red'}}>{this.state.sliderErrorMsg[2]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="contenu en français"
                        name="text_fr"
                        >
                        <Input disabled={this.state.buttons} onChange={(e)=>this.onChangeSlider(e.target.value,'text_fr',3)} value={this.state.sliderData.text_fr} />
                        {this.state.sliderError[3]&&<div style={{color:'red'}}>{this.state.sliderErrorMsg[3]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="text en anglais"
                        name="text_en"
                        >
                        <Input disabled={this.state.buttons} onChange={(e)=>this.onChangeSlider(e.target.value,'text_en',4)} value={this.state.sliderData.text_en} />
                        {this.state.sliderError[4]&&<div style={{color:'red'}}>{this.state.sliderErrorMsg[4]}</div>} 
                    </Form.Item>
                    <Button disabled={this.state.loading==true} onClick={this.handleSliderForm}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                        {getLanguage(this.props).submit} <RightOutlined />
                    </Button>
                    </Form>
                    </Col>
                    </Row>
                    {this.state.loading == true ? <FullPageLoader  />: null}
            </>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(AddSlider);