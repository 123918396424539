import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload, Select, Switch} from 'antd';
import { UploadOutlined, RightOutlined, FolderOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"

class editSharedFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
          downloadable:false,
          previewing:false,
          data:[''],
          idSharedfile:'',
          current: 0,
          name: '',
          fileList: [''],          
          file:'',folder:'',      
          validation:{error:[true,true],errorMsg:
          [getLanguage(this.props).required,
          getLanguage(this.props).required,
          ]},
            sharedfileError:[true,true],
            sharedfileErrorMsg:['',''],
          };                            
    }

    componentDidMount(){
        this.setState({idSharedfile:this.props.match.params.id})
        console.log(this.props.match.params.id);
        this.getSharedfileById()
        this.getData()
    }

    getData=async()=>{
    const requestOptions = {
        method: 'GET',
    };
    const res =  await fetch(apiURL+"getAllFolders", requestOptions);
    if(res.status == 200){
        const resJson = await res.json();        
        this.setState({data:resJson.reverse()})
    }            
    }

    getSharedfileById=async()=>{
        const requestOptions = {
            method: 'GET',
          };
          const data = await fetch(apiURL+'getSharedFile/'+this.props.match.params.id,requestOptions);
          const dataJson = await data.json();
          if(data.status == 200){
            console.log("success");
            this.setState({file:dataJson.path, name:dataJson.name, downloadable:dataJson.downloadable, folder:dataJson.folder.id.toString(),
            previewing:dataJson.previewing});        
          }
    }

    sharedfileValidation =() =>{
      let aux ={...this.state.validation}
      if(!this.state.file ){
          aux.error[0]=true
          aux.errorMsg[0]=getLanguage(this.props).required
        }else{
            aux.error[0]=false
            aux.errorMsg[0]=''
        }
        if(this.state.folder.trim()=='' ){
        
          aux.error[1]=true
          aux.errorMsg[1]=getLanguage(this.props).required
        }else{
            aux.error[1]=false
            aux.errorMsg[1]=''
        }        
        this.setState({validation:aux})
    }
  
    updateSharedfile=async()=>{
      this.sharedfileValidation()
         const ERROR = [...this.state.validation.error]
            const ERROR_MSG=[...this.state.validation.errorMsg]
            this.setState({
              sharedfileError:ERROR,
              sharedfileErrorMsg:ERROR_MSG
            })
        if(!this.state.validation.error.includes(true)){
        this.setState({loading:true})
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let formdata =new FormData()
        formdata.append('path',this.state.file)                
        formdata.append('current',this.state.current)
        formdata.append('downloadable',this.state.downloadable)
        formdata.append('previewing',this.state.previewing)
        formdata.append('folder',this.state.folder)        
        
        const requestOptions = {
          method: 'POST',
          // headers: myHeaders,
          body: formdata
        };
        console.log('aaaa',apiURL+'editSharedFile/'+this.state.idSharedfile,requestOptions);
        const data = await fetch(apiURL+'editSharedFile/'+this.state.idSharedfile,requestOptions);
        console.log("here",data);
        const dataJson = await data.json();
        console.log("here2",dataJson);
        if(data.status == 200){
          console.log("success");
          this.setState({loading:false})
          window.location='/admin/sharedfiles'
        }
        }
    }

     handleChange = (e) => {
       console.log(e);
        this.setState({
            folder: e,
        });
        // console.log(this.state.folder);
    }

    render() {        
       const defaultFile = [
        {
          uid: '0',
          name: this.state.name,
          status: 'done',
          url: this.state.file && this.state.file.length>0? apiURL+this.state.file.replace('/public/', ''): '',
          thumbUrl: this.state.file && this.state.file.length>0? apiURL+this.state.file.replace('/public/', ''): '',
        },
      ];      
       
      const onChange = ({ fileList: newFileList }) => {        
        newFileList.length>0 ? this.setState({file: newFileList[0].originFileObj}): this.setState({file:''})
        this.setState({current: 1})
        this.setState({fileList: newFileList});
        console.log(newFileList,'newFilelist');
        console.log(this.state.fileList,'fileList');
        console.log(this.state.file,'file');
      };

      const { Option } = Select;            

        return (  <>
            
            <h1 id='title'>Editer un fichier</h1>
            
            <Row align="center">
                    <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                <Form
                name="basic"
                layout="vertical"
                >
                <div className="inline-inputs mobile-block" style={{'justify-content':'space-between'}}>       
                <Form.Item
                    label="Filename"
                    name="name"
                    >     
                          <Upload                            
                            // listType="picture-card"
                            maxCount={1}
                            onChange={onChange}                             
                            accept=".png,.jpeg,.jpg,.doc,.docx,application/msword,.pdf,.pptx,.csv,.xls,.xlsx"
                            fileList={this.state.current==0?[...defaultFile]:this.state.fileList}
                            // className="upload-list-inline"
                            // beforeUpload={() => false}
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload> 
                          
                    <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).required}</span>
                    {this.state.sharedfileError[0]&&<div style={{color:'red'}}>{this.state.sharedfileErrorMsg[0]}</div>} 
                </Form.Item>      
                <Form.Item
                        label="Dossier associé"
                        name="folderId"
                        tooltip={{
                          title: "Dossier Associé",
                          icon: <FolderOutlined />,
                        }}   
                        >                            
                      {/* <Select style={{ width: 120 }} onSelect={(value, event) => this.handleOnChange(value, event)} onChange={(e)=>this.setState({folder: e.value})} value={this.state&&this.state.folder}>
                        {this.state.data.length>0 && Object.keys(this.state.data[0]).length !== 0 && this.state.data.map(( folder ) => 
                          <Option value={folder.id.toString()}>{folder.title_fr}</Option>
                        )
                        }                       
                      </Select> */}
                      {this.state.data.length>0 && Object.keys(this.state.data[0]).length !== 0 && this.state.data?
                        <Select 
                        value={this.state.folder}
                        onChange={(e)=>this.handleChange(e)}>
                        {/* <Option value={}></Option> */}
                        {this.state.data.map((data, index) => <Option value={data.id.toString()} >{data.title_fr}</Option>)}
                        </Select>
                      :''}
                      {this.state.sharedfileError[1]&&<div style={{color:'red'}}>{this.state.sharedfileErrorMsg[1]}</div>} 
                      </Form.Item>   
                      </div>
                      <div className="inline-inputs mobile-block" style={{gap:100}}>       
                <Form.Item
                        label="Téléchargeable"
                        name="downloadable"
                        >
                        <Switch
                          checked={this.state.downloadable}
                          checkedChildren="Oui"
                          unCheckedChildren="Non"
                          onChange={(e) => {
                            this.setState({downloadable:!this.state.downloadable})
                            console.log(this.state.downloadable,'button');
                            }
                            }
                        />
                    </Form.Item>
                   <Form.Item
                        label="Aperçu"
                        name="previewing"
                        >
                        <Switch
                          checked={this.state.previewing}
                          checkedChildren="Oui"
                          unCheckedChildren="Non"
                          onChange={(e) => {
                            this.setState({previewing:!this.state.previewing})
                            console.log(this.state.previewing,'button');
                            }
                            }
                        />
                    </Form.Item>     
                    </div>           
                <Button disabled={this.state.loading==true} onClick={this.updateSharedfile}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                    {getLanguage(this.props).submit} <RightOutlined />
                </Button>
                </Form>
                </Col>
                </Row>
                {this.state.loading == true ? <FullPageLoader  />: null}
        </> );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
export default connect(mapStateToProps, mapDispatchToProps)(editSharedFile);