import React, { Component, useEffect, useState  } from "react";
import { Row, Col, Input, Form, Button, Table, Card, Modal, Checkbox, message, notification  } from 'antd';
import { apiURL } from "../../Config/Config";
import { connect } from "react-redux";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import DownloadLink from "react-download-link";



const ShowUser =(props)=>{
    const [idUser, setidUser] = useState('');
    const [user,setUser] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);




    useEffect(() => {
        setidUser(props.match.params.id)
        getUserById()

    }, [])
    const getUserById=async()=>{
            const requestOptions = {
                method: 'GET',
              };
              const data = await fetch(apiURL+'showUser/'+props.match.params.id,requestOptions);
              const dataJson = await data.json();
              console.log("here2",dataJson);
              if(data.status == 200){    
                setUser(dataJson)
              }
        
    }

    const approuvUser =async()=>{
        let formdata =new FormData()
            formdata.append('actif',1)
            const requestOptions = {
            method: 'POST',
            // headers: myHeaders,
            body: formdata
            };
       
          const data = await fetch(apiURL+'approveUser/'+props.match.params.id,requestOptions);
          const dataJson = await data.json();
          console.log("here2",dataJson);
          if(data.status == 201){ 
            setIsModalVisible(false);   
            openNotificationApprouved()
            window.location='/admin'
          }
    }
    const RefuseUser =async()=>{
        let formdata =new FormData()
            formdata.append('actif',0)
            const requestOptions = {
            method: 'POST',
            // headers: myHeaders,
            body: formdata
            };
       
          const data = await fetch(apiURL+'approveUser/'+props.match.params.id,requestOptions);
          const dataJson = await data.json();
          console.log("here2",dataJson);
          if(data.status == 201){ 
            setIsModalVisible(false);   
            openNotificationRefused()
            window.location='/admin'

          }
    }
    const openNotificationRefused = placement => {
        notification.warning({
          message: `Notification ${placement}`,
          description:
            'utilisateur refuser',
          placement,
        });
      };
      const openNotificationApprouved = placement => {
        notification.success({
          message: `Notification ${placement}`,
          description:
            'utilisateur refuser',
          placement,
        });
      };

    const showModal=()=>{
        setIsModalVisible(true);
        
    }


    const closeModal=()=>{
        setIsModalVisible(false);
    }


    
    return(
        <div>
            <Card title="Default size card" extra={<a href="#">More</a>} style={{ width: '100%' }}>
                 <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>Nom: <span className='text-candidiature'>{user.nom}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>Prenom: <span className='text-candidiature'>{user.prenom}</span> </p>
                    </Col>
                </Row>
               <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>email: <span className='text-candidiature'>{user.email}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>telephone: <span className='text-candidiature'>{user.phone}</span> </p>
                    </Col>
                </Row>
                 <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>situation professionnelle: <span className='text-candidiature'>{user.situation_professionnelle}</span></p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>nom de l'entreprise: <span className='text-candidiature'>{user.entreprise}</span> </p>
                    </Col>
                </Row>
                <Row>
                   
                    <Col md={20}>
                    <p className='label-candidiature'>competences dominantes: <span className='text-candidiature'>{user.competences_dominantes}</span> </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>annee d'éxperience:<span className='text-candidiature'>{user.annee_experience}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>experience d'investissement ?:<span className='text-candidiature'>{user.experience_investissement === true ? "Oui" : "Non"}</span> </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>Quel ticket êtes-vous en capacité d'investir dans une entreprise ?:<span className='text-candidiature'>{user.ticket}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>Secteurs d'activité dans lesquels vous aimeriez investir<span className='text-candidiature'>{user.secteur_activite}</span> </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>Pourquoi souhaitez-vous rejoindre Damya Business Angels ?<span className='text-candidiature'>{user.cause_rejoint}</span> </p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>Disponibilité pour participer à l'organisation du réseau<span className='text-candidiature'>{user.disponibilite}</span> </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    <p className='label-candidiature'>Centre d'intérêt et autres engagements: <span className='text-candidiature'>{user.centre_interet}</span></p>
                    </Col>
                    <Col md={12}>
                    <p className='label-candidiature'>Remarques et commentaires éventuels <span className='text-candidiature'>{user.remarques_commentaires}</span></p>
                    </Col>
                </Row>
                
               
                <Row>
                    <Col md={112}>
                    <Button onClick={showModal} >Approuver user</Button>
                    </Col>
                    
                </Row>

        <Modal visible={isModalVisible} footer={false} closable={false} maskClosable={true}>
        {/* {renderChecknox()} */}
        <Row className='modalApprouv'>Voulez vous approuver cette utilisateur</Row>
        <Row style={{paddingTop:"50px"}}>
            
            <Col span={12}>
            <Button onClick={approuvUser} >Confirmer</Button>
            </Col>
            <Col span={12} style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
            <Button onClick={RefuseUser}>Refuser</Button>
            </Col>
        </Row>
      </Modal>
    </Card>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(ShowUser);