import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload} from 'antd';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"


class AddEvent extends Component {

    constructor(props) {
    super(props);
    this.state = {
      userLanguage:'',
      loading:false,
      eventData:{eventImg:'',title_fr:'',title_en:'',content_fr:'',content_en:'',social_link:'',meeting_link:'',
         validation:{error:[true,true,true,true,true],errorMsg:
         ['*required',
         '*required',
         '*required',
         '*required',
         '*required',
         '*required',
         '*required',]}},
          eventError:[true,true,true,true,true,true,true],
          eventErrorMsg:['','','','','','',''],
        };
    }
    
    componentDidMount(){
        window.scrollTo(0, 0)
    }

    onChangeEvent=(value,key,index)=>{
    let aux ={...this.state.eventData}
      aux[key]=value
      if(key=="eventImg"){
        if(!value){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='*required'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="title_fr"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='*required'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="title_en"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='*required'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="content_fr"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='*required'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="content_en"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='*required'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="social_link"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='*required'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      if(key=="meeting_link"){
        if(value.trim()===''){
          aux.validation.error[index]=true
          aux.validation.errorMsg[index]='*required'
        }else{
          aux.validation.error[index]=false
          aux.validation.errorMsg[index]=''
        }
      }
      const action ={type:"SET_EVENT_DATA", value:aux}
      this.props.dispatch(action);
      console.log(aux);
      this.setState({eventData:aux})
  }

  handleEventForm =async()=>{
      const ERROR = [...this.state.eventData.validation.error]
      const ERROR_MSG=[...this.state.eventData.validation.errorMsg]
      this.setState({
        eventError:ERROR,
        eventErrorMsg:ERROR_MSG
      })
      if(!this.state.eventData.validation.error.includes(true)){
      this.setState({loading:true})
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const event = this.state.eventData
      let formdata =new FormData()
      formdata.append('event_img',event.eventImg)
      formdata.append('title_fr',event.title_fr)
      formdata.append('title_en',event.title_en)
      formdata.append('content_fr',event.content_fr)
      formdata.append('content_en',event.content_en)
      formdata.append('social_link',event.social_link)
      formdata.append('meeting_link',event.meeting_link)
      
      
      const requestOptions = {
        method: 'POST',
        // headers: myHeaders,
        body: formdata
      };
      const data = await fetch(apiURL+'addEvent',requestOptions);
      console.log("here",data);
      const dataJson = await data.json();
      console.log("here2",dataJson);
      if(data.status == 201){
        console.log("success");
        this.setState({loading:false})
        window.location='/admin/events'
      }
    }
  }

    render() {

        const event = {
          name: 'event',
            // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
          headers: {
            authorization: 'authorization-text',
          },
          onChange(info) {
            this.setState({document:info.originFileOb})
            console.log("info",info);
          },
        };

        return (
            <>
            
                <h1 id='title'>Add Event</h1>
                
                <Row align="center">
                        <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                    <Form
                    name="basic"
                    layout="vertical"
                    >
                    
                    <Form.Item
                        label="Filename"
                        name="eventImg"
                        >
                        <Upload maxCount={1} accept=".png,.jpeg,.jpg"  {...event} onChange={(info)=>this.onChangeEvent(info.fileList[0].originFileObj,'eventImg',0)} value={this.state.eventData.eventImg}>
                            <Button icon={<UploadOutlined />}>{getLanguage(this.props).clickDownload}</Button>
                        </Upload>
                        <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).requiredWordFile}</span>
                        {this.state.eventError[0]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[0]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="titre en francais"
                        name="title_fr"
                        >
                        <Input onChange={(e)=>this.onChangeEvent(e.target.value,'title_fr',1)} value={this.state.eventData.title_fr} />
                        {this.state.eventError[1]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[1]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="titre en anglais"
                        name="title_en"
                        >
                        <Input onChange={(e)=>this.onChangeEvent(e.target.value,'title_en',2)} value={this.state.eventData.title_en} />
                        {this.state.eventError[2]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[2]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="content en francais"
                        name="content_fr"
                        >
                        <Input onChange={(e)=>this.onChangeEvent(e.target.value,'content_fr',3)} value={this.state.eventData.content_fr} />
                        {this.state.eventError[3]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[3]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="contenu en anglais"
                        name="content_en"
                        >
                        <Input onChange={(e)=>this.onChangeEvent(e.target.value,'content_en',4)} value={this.state.eventData.content_en} />
                        {this.state.eventError[4]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[4]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="Social Link"
                        name="social_link"
                        >
                        <Input onChange={(e)=>this.onChangeEvent(e.target.value,'social_link',5)} value={this.state.eventData.social_link} />
                        {this.state.eventError[5]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[5]}</div>} 
                    </Form.Item>
                    <Form.Item
                        label="Meeting Link"
                        name="meeting_link"
                        >
                        <Input onChange={(e)=>this.onChangeEvent(e.target.value,'meeting_link',6)} value={this.state.eventData.meeting_link} />
                        {this.state.eventError[6]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[6]}</div>} 
                    </Form.Item>
                    <Button disabled={this.state.loading==true} onClick={this.handleEventForm} type="link"  htmlType="submit" className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                        {getLanguage(this.props).submit} <RightOutlined />
                    </Button>
                    </Form>
                    </Col>
                    </Row>
                    {this.state.loading == true ? <FullPageLoader  />: null}
            </>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);