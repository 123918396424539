import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload} from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"

class editEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventImg:'',title_fr:'',title_en:'',content_fr:'',content_en:'',social_link:'',meeting_link:'',
            idEvent:'',
            current: 0,
            fileList: [''],
  
         validation:{error:[true,true,true,true,true,true,true],errorMsg:
         [getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
        ]},
          eventError:[true,true,true,true,true,true,true],
          eventErrorMsg:['','','','','','','']}
       
    }

    componentDidMount(){
        this.setState({idEvent:this.props.match.params.id})
        console.log(this.props.match.params.id);
        this.getActualityById()
    }
    getActualityById=async()=>{
        const requestOptions = {
            method: 'GET',
          };
          const data = await fetch(apiURL+'getActuality/'+this.props.match.params.id,requestOptions);
          const dataJson = await data.json();
          if(data.status == 200){
            console.log("success");
            this.setState({eventImg:dataJson.image, title_fr:dataJson.titleFr, title_en:dataJson.titleEn,
            content_fr:dataJson.contentFr,content_en:dataJson.contentEn,social_link:dataJson.socialLink,meeting_link:dataJson.meetingLink});
          }
    }

    eventValidation =() =>{
      let aux ={...this.state.validation}
      if(!this.state.eventImg ){
          aux.error[0]=true
          aux.errorMsg[0]=getLanguage(this.props).required
        }else{
            aux.error[0]=false
            aux.errorMsg[0]=''
        }
        if(this.state.title_fr.trim()=='' ){
        
          aux.error[1]=true
          aux.errorMsg[1]=getLanguage(this.props).required
        }else{
            aux.error[1]=false
            aux.errorMsg[1]=''
        }
        if(this.state.title_en.trim()=='' ){
          
          aux.error[2]=true
          aux.errorMsg[2]=getLanguage(this.props).required
        }else{
            aux.error[2]=false
            aux.errorMsg[2]=''
        }
        if(this.state.content_fr.trim()=='' ){
     
          aux.error[3]=true
          aux.errorMsg[3]=getLanguage(this.props).required
        }else{
            aux.error[3]=false
            aux.errorMsg[3]=''
        }
        if(this.state.content_en.trim()=='' ){
         
          aux.error[4]=true
          aux.errorMsg[4]=getLanguage(this.props).required
        }else{
            aux.error[4]=false
            aux.errorMsg[4]=''
        }
        if(this.state.social_link.trim()=='' ){
         
          aux.error[5]=true
          aux.errorMsg[5]=getLanguage(this.props).required
        }else{
            aux.error[5]=false
            aux.errorMsg[5]=''
        }
        if(this.state.meeting_link.trim()=='' ){
         
          aux.error[6]=true
          aux.errorMsg[6]=getLanguage(this.props).required
        }else{
            aux.error[6]=false
            aux.errorMsg[6]=''
        }
        this.setState({validation:aux})
    }
  
    updateEvent=async()=>{
      this.eventValidation()
         const ERROR = [...this.state.validation.error]
            const ERROR_MSG=[...this.state.validation.errorMsg]
            this.setState({
              eventError:ERROR,
              eventErrorMsg:ERROR_MSG
            })
        if(!this.state.validation.error.includes(true)){
        this.setState({loading:true})
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let formdata =new FormData()
        formdata.append('current',this.state.current)
        formdata.append('actuality_img',this.state.eventImg)
        formdata.append('title_fr',this.state.title_fr)
        formdata.append('title_en',this.state.title_en)
        formdata.append('content_fr',this.state.content_fr)
        formdata.append('content_en',this.state.content_en)
        formdata.append('social_link',this.state.social_link)
        formdata.append('meeting_link',this.state.meeting_link)
        
        const requestOptions = {
          method: 'POST',
          // headers: myHeaders,
          body: formdata
        };
        const data = await fetch(apiURL+'updateActuality/'+this.state.idEvent,requestOptions);
        console.log("here",data);
        const dataJson = await data.json();
        console.log("here2",dataJson);
        if(data.status == 200){
          console.log("success");
          this.setState({loading:false})
          window.location='/admin/events'
        }
        }
    }

    render() { 
      const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };
      
       const defaultFile = [
        {
          uid: '0',
          name: this.state.eventImg && this.state.eventImg.length>0?this.state.eventImg.split('/').pop(): '',
          status: 'done',
          url: this.state.eventImg && this.state.eventImg.length>0? apiURL+this.state.eventImg.replace('/public/', ''): '',
          thumbUrl: this.state.eventImg && this.state.eventImg.length>0? apiURL+this.state.eventImg.replace('/public/', ''): '',
        },
      ];      
       
      const onChange = ({ fileList: newFileList }) => {        
        newFileList.length>0 ? this.setState({eventImg: newFileList[0].originFileObj}): this.setState({eventImg:''})
        this.setState({current: 1})
        this.setState({fileList: newFileList});
        console.log(newFileList,'newFilelist');
        console.log(this.state.fileList,'fileList');
        console.log(this.state.eventImg,'eventImg');
      };
        return (  <>
            
            <h1 id='title'>edit Event</h1>
            
            <Row align="center">
                    <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                <Form
                name="basic"
                layout="vertical"
                >
                <Form.Item
                    label="Filename"
                    name="eventImg"
                    >     <ImgCrop grid aspect={303/200}>                          
                          <Upload
                            onPreview={onPreview}
                            listType="picture-card"
                            maxCount={1}
                            onChange={onChange}                             
                            accept=".png,.jpeg,.jpg"
                            fileList={this.state.current==0?[...defaultFile]:this.state.fileList}
                            className="upload-list-inline"                            
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload> 
                          </ImgCrop>                    
                    <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).requiredImage}</span>
                    {this.state.eventError[0]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[0]}</div>} 
                </Form.Item>
                <Form.Item
                    label="titre en francais"
                    name="title_fr"
                    >
                    <Input onChange={(e)=>this.setState({title_fr: e.target.value})} value={this.state&&this.state.title_fr} />
                    {this.state.eventError[1]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[1]}</div>}                </Form.Item>
                <Form.Item
                    label="titre en anglais"
                    name="title_en"
                    >
                    <Input onChange={(e)=>this.setState({title_en: e.target.value})} value={this.state&&this.state.title_en} />
                    {this.state.eventError[2]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[2]}</div>}                </Form.Item>
                <Form.Item
                    label="contenu en français"
                    name="content_fr"
                    >
                    <Input onChange={(e)=>this.setState({content_fr: e.target.value})} value={this.state&&this.state.content_fr} />
                    {this.state.eventError[3]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[3]}</div>}                </Form.Item>
                <Form.Item
                    label="contenu en anglais"
                    name="content_en"
                    >
                    <Input onChange={(e)=>this.setState({content_en: e.target.value})} value={this.state&&this.state.content_en} />
                  {this.state.eventError[4]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[4]}</div>}                </Form.Item>
                  <Form.Item
                    label="social link"
                    name="social_link"
                    >
                    <Input onChange={(e)=>this.setState({social_link: e.target.value})} value={this.state&&this.state.social_link} />
                  {this.state.eventError[5]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[5]}</div>}                </Form.Item>
                  <Form.Item
                    label="meeting link"
                    name="meeting_link"
                    >
                    <Input onChange={(e)=>this.setState({meeting_link: e.target.value})} value={this.state&&this.state.meeting_link} />
                  {this.state.eventError[6]&&<div style={{color:'red'}}>{this.state.eventErrorMsg[6]}</div>}                </Form.Item>
                <Button disabled={this.state.loading==true} onClick={this.updateEvent}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                    {getLanguage(this.props).submit} <RightOutlined />
                </Button>
                </Form>
                </Col>
                </Row>
                {this.state.loading == true ? <FullPageLoader  />: null}
        </> );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
export default connect(mapStateToProps, mapDispatchToProps)(editEvent);