import React, { Component } from "react";
import { connect } from "react-redux"
import getLanguage from "../../utilFunction/utilFunction"
import { Button, Menu, Layout, Popover, Row, Form, Upload, Space, Table, Image } from 'antd';
import Moment from 'react-moment';
import { saveAs } from 'file-saver';
import { SearchOutlined, FolderOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import docPic from '../../assets/img/doc.png'
import File from '../../assets/img/file.png'
import download from '../../assets/img/download.png'
import preview from '../../assets/img/preview.png'
import update from '../../assets/img/remplacez.png'
import femaleAvatar from '../../assets/img/female-avatar.svg'
import { Link } from 'react-router-dom'
import { UploadOutlined, EyeOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { apiURL } from "../../Config/Config";
import isEmail from "validator/lib/isEmail";
import arrowLeft from '../../assets/img/arrow-left.png'
import {MenuOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import womanSpace from '../../assets/img/member-space-woman.png'
import logoutIcon from '../../assets/img/member-space-icon.png'

const { Sider, Content } = Layout;
const { img } = arrowLeft;

class SpaceMembre extends Component {
    constructor(props) {
        super(props);
            this.state = {
            data: [''],
            url:[''],
            isLoading: false,
            error: false,
            folderData: [''],
            dataUser:[],
            dataAngel:[],
            userLanguage:'',
            collapsed: false,
            current: 0,
            currentImage: 0,
            fileList: [''],
            existEmail:false,
            modification: false,
            nom:'',prenom:'',email:'',tel:'',sPle:'',cFon:'',eOAlle:'',linkedin:'',nAEce:'',eIDEse:'',tCIEse:'',sAIir:'',rDBA:'',dPORsau:'',CIAEents:'',RCEels:'',image:'',
             validation:{error:[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],errorMsg:
             [getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
             getLanguage(this.props).required,
          
             getLanguage(this.props).required,]},
              investorError:[true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true, true],
              investorErrorMsg:['','','','','','','','','','','','','','','','',''],
            };
        }
        componentDidMount(){
            console.log("testttt");
              window.scrollTo(0, 0)
              this.getDataAngel()
              // this.getFile()
              this.getDataUser()
              this.getDataDoc()
              if(this.state.dataUser.length == 0){
                this.getDataAngel()
                this.getDataUser()
                // this.getFile()
                this.getDataDoc()
              }     
        }

        updateUser=async()=>{
            
              let formdata =new FormData()
              formdata.append('nom',this.state.nom)
              formdata.append('prenom',this.state.prenom)
              formdata.append('email',this.state.email)
              formdata.append('phone',this.state.tel)
              formdata.append('situation_professionnelle',this.state.sPle)
              formdata.append('competences_dominantes',this.state.cFon)
              formdata.append('entreprise',this.state.eOAlle)
              formdata.append('annee_experience',this.state.nAEce)
              formdata.append('experience_investissement',this.state.eIDEse)
              formdata.append('ticket',this.state.tCIEse)
              formdata.append('remarques_commentaires',this.state.RCEels)
              formdata.append('secteur_activite',this.state.sAIir)
              formdata.append('cause_rejoint',this.state.rDBA)
              formdata.append('disponibilite',this.state.dPORsau)
              formdata.append('centre_interet',this.state.CIAEents)
              const requestOptions = {
                method: 'POST',
                // headers: myHeaders,
                body: formdata
              };
              const data = await fetch(apiURL+'updateUser/'+this.state.idMember,requestOptions);
              console.log("here",data);
              const dataJson = await data.json();
              if(data.status == 201){
               return true
              }
        }

        updateMember=async()=>{
            this.onChangeInvestorData()
               const ERROR = [...this.state.validation.error]
                  const ERROR_MSG=[...this.state.validation.errorMsg]
                  this.setState({
                    investorError:ERROR,
                    investorErrorMsg:ERROR_MSG
                  })
              if(!this.state.validation.error.includes(true)){
              this.setState({loading:true})
              const myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              let formdata =new FormData()
              formdata.append('current',this.state.currentImage)
              formdata.append('image',this.state.image)
              formdata.append('nom',this.state.nom)
              formdata.append('poste',this.state.sPle)
              formdata.append('posteEn',this.state.sPle)
              formdata.append('email',this.state.email)
              formdata.append('linkedin',this.state.linkedin)
              formdata.append('description',this.state.cFon)    
              formdata.append('descriptionEn',this.state.cFon)        
              
              const requestOptions = {
                method: 'POST',
                // headers: myHeaders,
                body: formdata
              };
              const data = await fetch(apiURL+'updateMember/'+this.state.idAngel,requestOptions);
              console.log("here",data);
              const dataJson = await data.json();
              console.log("here2",dataJson);
              this.updateUser().then((e)=>{
                  console.log("eeeee",e);
                  if(e == true){
                    if(data.status == 200){
                        window.location.reload()
                      }
                  }
              })
            }
             
          }

        fileType = (url) =>{          
          const type = url.substring(url.lastIndexOf('/')+1).split('.').pop();          
          if(type == "png" || type == "jpeg" || type == "jpg")
            {return true}
          else
            {return false}
        }
        
        logout =()=>{
            const action = {type:'LOGOUT', token:null, username:null, isLogIn:null, isAdmin:null}
            this.props.dispatch(action);
            setTimeout(() => {
              window.location='/'
            }, 200);
          }
        onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
            console.log('Failed:', this.props);
        };
        getFile = async(id, name) =>{ 
          this.setState({isLoading:false})
          this.setState({error:null});         
        const requestOptions = {
          method: 'GET',
        };
        try {
          const data = await fetch(apiURL+"getFile/"+id, requestOptions);
          const dataJson = await data.json();
          this.setState({url:dataJson});
          console.log(dataJson, 'file');
          this.setState({isLoading:false});
          const byteString = atob(this.state.url.file.split(',')[1]);
          const mimeString = this.state.url.file.split(',')[0].split(':')[1].split(';')[0]
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }          
          const blob = new Blob([ab], {type: mimeString});
          console.log(name,'name');
          saveAs(blob, name)          
        } catch (e) {
          this.setState({error:e});
          console.log(e, 'eror');
          this.setState({isLoading:false});
        }
         
          // let file = new Blob(this.state.url)
          
        }
        getDataUser=async() =>{
            console.log('here');
            this.setState({loading:true})
            const requestOptions = {
                method: 'GET',
        };
         const data = await fetch(apiURL+"api/verifauthuser/"+this.props.auth.username, requestOptions);
         const dataJson = await data.json();
         console.log("hello",dataJson );
         this.setState({nom:dataJson.nom, prenom:dataJson.prenom, email:dataJson.email, tel:dataJson.phone, sPle:dataJson.situationProfessionnelle,
        cFon:dataJson.competencesDominantes, eOAlle:dataJson.entreprise, nAEce:dataJson.anneeExperience,
        eIDEse:dataJson.experienceInvestissement, tCIEse:dataJson.ticket, sAIir:dataJson.secteurActivite, rDBA:dataJson.causeRejoint,
        dPORsau:dataJson.disponibilite, CIAEents:dataJson.centreInteret, RCEels:dataJson.remarquesCommentaires, idMember:dataJson.id
              })
           
                   
          }
        getDataDoc=async()=>{
          console.log("hereeeee");
          const requestOptions = {
              method: 'GET',
          };
          const res =  await fetch(apiURL+"getAllFoldersSharedFile/", requestOptions);
          console.log("res",res);
          if(res.status == 200){
              const resJson = await res.json();
              
              this.setState({
                data: resJson
              })
              console.log("data",resJson);
          }            
        }
        getDataFolder=async()=>{
          const requestOptions = {
              method: 'GET',
          };
          const res =  await fetch(apiURL+"getAllFolders/", requestOptions);
          if(res.status == 200){
              const resJson = await res.json();
              this.setState({
                folderData: resJson
              })
          }            
        }
          getDataAngel =async()=>{
            const requestOptions = {
              method: 'GET',
          };
            const angelData =  await fetch(apiURL+"getAngelByMail/"+this.props.auth.username, requestOptions);
            const angelDataJson = await angelData.json();
            this.setState({ linkedin:angelDataJson.linkedin, image:angelDataJson.image, idAngel:angelDataJson.id})
        }
          returHome =()=>{
            window.location='/'
          } 

          // startDownload = () =>{
          //   let imageURL = "https://cdn.glitch.com/4c9ebeb9-8b9a-4adc-ad0a-238d9ae00bb5%2Fmdn_logo-only_color.svg?1535749917189"

          //   let downloadedImg = new Image
          //   downloadedImg.crossOrigin = "Anonymous";
          //   downloadedImg.addEventListener("load", imageReceived, false);
          //   downloadedImg.src = imageURL;
          // }

          // download = (url) => {
          //   var element = document.createElement("a");
          //   var file = new Blob(
          //     [
          //       "https://timesofindia.indiatimes.com/thumb/msid-70238371,imgsize-89579,width-400,resizemode-4/70238371.jpg"
          //     ],
          //     { type: "image/*" }
          //   );
          //   console.log(file);
          //   element.href = URL.createObjectURL(file);
          //   element.download = url.substring(url.lastIndexOf('/')+1).split('.');
          //   element.click();
          // };

          downloadImage = (url) => {
            console.log(url);
            saveAs(url, url.substring(url.lastIndexOf('/')+1).split('.'))
            
          }
          downloadFile = (url) =>{
            console.log(url);
            saveAs(url, url.substring(url.lastIndexOf('/')+1).split('.'))
          }
          content = (data) => {           
            return (
            <div>
            <div className="member-space-logout">
              <center>
                <img src={femaleAvatar} className="member-space-logout-avatar"/>
                <span className="member-space-logout-name">{data.nom} {data.prenom}</span><br />
                <span className="member-space-logout-email">{this.props.auth.username}</span><br />
                <Row>
                  <Button onClick={this.returHome} className="member-space-logout-btn">Acceuil</Button>
                </Row>
                <Row>
                  <Button onClick={this.logout} className="member-space-logout-btn">Log out</Button>
                </Row>
              </center>
            </div>
            </div>
            );
          }

          renderIcon = (file) =>{
            console.log(file.path,'test');
            if(!this.fileType(file.path)){              
            return(
            <>
              {file.downloadable ?
                <a className="download-link" onClick={()=>this.downloadFile(apiURL+file.path.replace('/public/', ''))} target="_blank" download>                            
                  <img src={download} width={30}/> 
                </a>:''}
              {file.previewing?
                <a href={'https://docs.google.com/gview?url='+apiURL+file.path.replace('/public/', '')+'&embedded=true'} className="download-link" target="_blank">                            
                  <img src={preview} width={25}/>                                                                               
                </a>:''}
            </>
            )
            }
            else{              
            return(
            <>            
                {file.downloadable ?
                <a className="download-link" onClick={()=>this.getFile(file.id, file.name)} download>                            
                  <img src={download} width={30}/> 
                </a>:''}
              {file.previewing?
                <a href={apiURL+file.path.replace('/public/', '')} className="download-link" target="_blank">                            
                  <img src={preview} width={25}/>                                                                               
                </a>:''}
            </>)
            }
          }

          docBody = (data) =>{            
          return (
                  <div className="doc-section">
                    <div className="doc-container">                    
                    {data.length>0 && Object.keys(data[0]).length !== 0 && data.map((folder)=>(
                      <div>
                      {!folder.sharedFiles.length==0?
                      <div style={{display:'flex','align-items':'baseline',marginLeft:-30,gap:10}}>                      
                        <img src={docPic} width={40} height={'auto'}/>                                                        
                        <h2>{folder.title_fr}</h2>                        
                      </div>
                      :''}
                      {folder.sharedFiles.map((files)=>(
                        <div className="doc-item">    
                        <div className="doc-items">
                          <img src={File} width={33} height={'auto'}/>                
                          <h2 className="doc-title"> <span>{files.name}</span><br /><span className="doc-date"><Moment format="YYYY/MM/DD">{files.creationDate}</Moment></span></h2>
                          <div className="download-link">
                          <Space>
                          {this.renderIcon(files)}                        
                          </Space>
                          </div>
                        </div>  
                        </div>   
                      ))}
                      </div>                                                               
                    ))}
                    </div>
                  </div>
                )
          }
          
          onChangeInvestorData=()=>{
            console.log("here");
          let aux ={...this.state}
          console.log("aux", aux);
              if(this.state.nom.trim() == ''){
                  aux.validation.error[0]=true
                  aux.validation.errorMsg[0]=getLanguage(this.props).required
              }else{
                  aux.validation.error[0]=false
                  aux.validation.errorMsg[0]=''
                }
                if(this.state.prenom.trim()===''){
                  aux.validation.error[1]=true
                  aux.validation.errorMsg[1]=getLanguage(this.props).required
                }else{
                  aux.validation.error[1]=false
                  aux.validation.errorMsg[1]=''
                }
              
              if(!isEmail(this.state.email)){
                aux.validation.error[2]=true
                aux.validation.errorMsg[2]=getLanguage(this.props).required
              }
               
                if(this.state.email.trim()===''){
                  aux.validation.error[2]=true
                  aux.validation.errorMsg[2]=getLanguage(this.props).required
                }if(!isEmail(this.state.email) ==true){
                  aux.validation.error[2]=true
                  aux.validation.errorMsg[2]=getLanguage(this.props).requiredCorrectEmail
                }
                else{
                  aux.validation.error[2]=false
                  aux.validation.errorMsg[2]=''
                }
                
               
                if(!isNaN(Number(this.state.tel)) ==false || this.state.tel.length < 8){
                  aux.validation.error[3]=true
                  aux.validation.errorMsg[3]=getLanguage(this.props).requiredCorrectPhoneNumber
                }
                if(this.state.tel.length > 8 || this.state.tel.length < 8){
                  aux.validation.error[3]=true
                  aux.validation.errorMsg[3]=getLanguage(this.props).requiredCorrectPhoneNumber1
                }
                else{
                  aux.validation.error[3]=false
                  aux.validation.errorMsg[3]=''
                }
              
             
                if(this.state.sPle.trim()===''){
                  aux.validation.error[4]=true
                  aux.validation.errorMsg[4]=getLanguage(this.props).required
                }else{
                  aux.validation.error[4]=false
                  aux.validation.errorMsg[4]=''
                }
              
              
                if(this.state.cFon.trim()===''){
                  aux.validation.error[5]=true
                  aux.validation.errorMsg[5]=getLanguage(this.props).required
                }else{
                  aux.validation.error[5]=false
                  aux.validation.errorMsg[5]=''
                }
              
              
                if(this.state.eOAlle.trim()===''){
                  aux.validation.error[6]=true
                  aux.validation.errorMsg[6]=getLanguage(this.props).required
                }else{
                  aux.validation.error[6]=false
                  aux.validation.errorMsg[6]=''
                }
              
           
                if(this.state.linkedin.trim()===''){
                  aux.validation.error[7]=true
                  aux.validation.errorMsg[7]=getLanguage(this.props).required
                }else{
                  aux.validation.error[7]=false
                  aux.validation.errorMsg[7]=''
                }
              
              if(!isNaN(Number(this.state.nAEce)) ==false){
                  aux.validation.error[8]=true
                  aux.validation.errorMsg[8]=getLanguage(this.props).requiredValideNumber
                }
                else{
                  aux.validation.error[8]=false
                  aux.validation.errorMsg[8]=''
                }
              
            
                if(this.state.eIDEse.trim()===''){
                  aux.validation.error[9]=true
                  aux.validation.errorMsg[9]=getLanguage(this.props).required
                }else{
                  aux.validation.error[9]=false
                  aux.validation.errorMsg[9]=''
                }

              
            
                if(this.state.tCIEse.trim()===''){
                  aux.validation.error[10]=true
                  aux.validation.errorMsg[10]=getLanguage(this.props).required
                }else{
                  aux.validation.error[10]=false
                  aux.validation.errorMsg[10]=''
                }
              
            
                if(this.state.sAIir.trim()===''){
                  aux.validation.error[11]=true
                  aux.validation.errorMsg[11]=getLanguage(this.props).required
                }else{
                  aux.validation.error[11]=false
                  aux.validation.errorMsg[11]=''
                }
              
              
                if(this.state.rDBA.trim()===''){
                  aux.validation.error[12]=true
                  aux.validation.errorMsg[12]=getLanguage(this.props).required
                }else{
                  aux.validation.error[12]=false
                  aux.validation.errorMsg[12]=''
                }
              
              
                if(this.state.dPORsau.trim()===''){
                  aux.validation.error[13]=true
                  aux.validation.errorMsg[13]=getLanguage(this.props).required
                }else{
                  aux.validation.error[13]=false
                  aux.validation.errorMsg[14]=''
                }
              
             
                if(this.state.CIAEents.trim()===''){
                  aux.validation.error[14]=true
                  aux.validation.errorMsg[14]=getLanguage(this.props).required
                }else{
                  aux.validation.error[14]=false
                  aux.validation.errorMsg[14]=''
                }
              
              
                if(this.state.RCEels.trim()===''){
                  aux.validation.error[15]=true
                  aux.validation.errorMsg[15]=getLanguage(this.props).required
                }else{
                  aux.validation.error[15]=false
                  aux.validation.errorMsg[15]=''
                }
             
             
                if(!this.state.image){
                  aux.validation.error[16]=true
                  aux.validation.errorMsg[16]=getLanguage(this.props).required
                }else{
                  aux.validation.error[16]=false
                  aux.validation.errorMsg[16]=''
                }
                           
              const action ={type:"UPDATE_INVESTOR", value:aux}
              this.props.dispatch(action);
              console.log(aux);
              this.setState({investorData:aux})
            }

            PersonalInfo = () =>{
                this.setState({
                  current : 0
                })
              }
          
              PersonalDocs = () =>{
                this.setState({
                  current : 1
                })
              }
          
              modificate = () => {
                this.setState({
                modification: !this.state.modification,
                });
          }; 
              toggle = () => {
                  this.setState({
                  collapsed: !this.state.collapsed,
                  });
              };
              
   
    render() {
        const onPreview = async file => {
            let src = file.url;
            if (!src) {
              src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
              });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow.document.write(image.outerHTML);
          };
          const onChange = ({ fileList: newFileList }) => {        
            newFileList.length>0 ? this.setState({image: newFileList[0].originFileObj}): this.setState({image:''})
            this.setState({currentImage: 1})
            this.setState({fileList: newFileList});

          };
          const defaultFile = [
            {
              uid: '0',
              name: this.state.image && this.state.image.length>0?this.state.image.split('/').pop(): '',
              status: 'done',
              url: this.state.image && this.state.image.length>0? apiURL+this.state.image.replace('/public/', ''): '',
              thumbUrl: this.state.image && this.state.image.length>0? apiURL+this.state.image.replace('/public/', ''): '',
            },
          ];  
    
        return (
            <>
            <div>            
                {/* member space section */}
            <Layout className="member-space">
                    <Sider trigger={null} collapsible collapsed={this.state.collapsed} className="member-space-sidebar">
                    <div className="logo" />                         
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['2']}>                                    
                        <div className='menu-espace-membre'>
                        {React.createElement(this.state.collapsed ? MenuOutlined : ArrowLeftOutlined, {
                        className: 'trigger sidebar-icon',
                        onClick: this.toggle, 
                        })}
                        </div>
                        
                       
                        <Menu.Item key="2" onClick={this.PersonalInfo}> 
                          {getLanguage(this.props).personalInfo}
                        </Menu.Item>
                        <Menu.Item key="3" onClick={this.PersonalDocs}>
                          {getLanguage(this.props).documents}
                        </Menu.Item>
                        <Link to="/">
                          <h3 className={this.props.lang == "fr"?this.props.lang+"_fixed-oriented-text":this.props.lang+"_fixed-oriented-text"}>{getLanguage(this.props).returnAcceuil}</h3>
                        </Link>
                    </Menu>
                    </Sider>
                    <Layout className="">
                    <Content className={this.state.collapsed ? 'member-space-content-collapsed' : 'member-space-content'}>
                        <div className="member-space-popover">
                        <Popover className="member-space-popover" placement="bottomRight" content={this.content(this.state.data)} trigger="click">
                          <img src={logoutIcon} className="member-space-logout-icon" />
                        </Popover>
                        </div>                        
                  {this.state.current == 0 ?
                  <Form
                  name="basic"
                  layout="vertical"
                  className="member-space-form"
                  >
                  <div className="member-spacing">
                    <span className="member-space-update-text" onClick={this.modificate}> <img className="member-space-update-icon" src={update}/>{this.state.modification ? getLanguage(this.props).profileModification : getLanguage(this.props).replace} </span>
                  </div>
                    <Form.Item
                    label="Avatar"
                    name="memberImg"
                    >     <ImgCrop grid>                          
                          <Upload
                            onPreview={onPreview}
                            listType="picture-card"
                            maxCount={1}
                            onChange={onChange}                             
                            accept=".png,.jpeg,.jpg"
                            fileList={this.state.currentImage==0?[...defaultFile]:this.state.fileList}
                            className="upload-list-inline"                            
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload> 
                          </ImgCrop>                    
                          <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).requiredImage}</span>
                        {this.state.investorError[16]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[16]}</div>} 
                </Form.Item>
        <div className="inline-inputs">
                 
          <Form.Item
            label={getLanguage(this.props).lastName}
            name="nom"
            style={{ width: '47.4%' }}
          >
          <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} onChange={(e)=>this.setState({nom:e.target.value})} defaultValue={this.state.nom  } />
           {this.state.investorError[0]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[0]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).firstName}
            name="prenom"
            style={{ width: '47.4%' }}
          >
          <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} onChange={(e)=>this.setState({prenom:e.target.value})} defaultValue={this.state && this.state.prenom ? this.state.prenom :null} />
           {this.state.investorError[1]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[1]}</div>}
          </Form.Item>
          </div>
          <div className="inline-inputs">
          <Form.Item
            label={getLanguage(this.props).email}
            name="email"
            style={{ width: '47.4%' }}
          >
          <input disabled={this.state.modification===true ? false : true} style={{ width: '90%' }} className={this.state.existEmail ==true ? "error-input shadow custom-inputs": "shadow custom-inputs"} onChange={(e)=>this.setState({email:e.target.value})} defaultValue={this.state.email} />
           {this.state.investorError[2]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[2]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).phone}
            name="tel"
            style={{ width: '47.4%' }}
          >
          <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} onChange={(e)=>this.setState({tel:e.target.value})} defaultValue={this.state.tel} />
           {this.state.investorError[3]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[3]}</div>}
          </Form.Item>
          </div>
          <div>
          <Form.Item
            label={getLanguage(this.props).professionalSituation}
            name="sPle"
          >
          <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} defaultValue={this.state.sPle} onChange={(e)=>this.setState({sPle:e.target.value})} />
              
           {this.state.investorError[4]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[4]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).skillsTrainingBio}
            name="cFon"
          >
          <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} onChange={(e)=>this.setState({cFon:e.target.value})} defaultValue={this.state.cFon} />
           {this.state.investorError[5]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[5]}</div>}
          </Form.Item>
          </div>
            <Form.Item
              label={getLanguage(this.props).currentBusinessOrganization}
              name="eOAlle" 
            >
              <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} onChange={(e)=>this.setState({eOAlle:e.target.value})} defaultValue={this.state.eOAlle} />
              {this.state.investorError[6]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[6]}</div>}
            </Form.Item>

            <Form.Item
              label={"linkedin"}
              name="linkedin"
            >
            <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" defaultValue={this.state && this.state.linkedin ?this.state.linkedin : null } style={{ width: '90%' }}  onChange={(e)=>this.setState({linkedin:e.target.value})} />
             
              {this.state.investorError[7]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[7]}</div>}
            </Form.Item>
            <Form.Item
              label={getLanguage(this.props).experience}
              name="nAEce"
            >
              <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} onChange={(e)=>this.setState({nAEce:e.target.value})} defaultValue={this.state.nAEce} />
              {this.state.investorError[8]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[8]}</div>}
            </Form.Item>
            <Form.Item
            label={getLanguage(this.props).investExperience}
            name="eIDEse"
            >
            <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} defaultValue={this.state.eIDEse} onChange={(e)=>this.setState({eIDEse:e.target.value})} />
              
           {this.state.investorError[9]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[9]}</div>} 
            </Form.Item>
            {/* <Form.Item
              label={getLanguage(this.props).investExperienceField}
              name="eInt"
            >
            <Input disabled={this.state.modification===true ? false : true} disabled={this.state.modification===true ? false : true} style={{ width: '90%' }} onchange={(e)=>this.props.onChangeInvestorData(e.target.value,'eInt',10)} value={this.props.dataUser.experienceDomaine} />
           {this.props.investorError[10]&&<div style={{color:'red'}}>{this.props.investorErrorMsg[10]}</div>} 
          </Form.Item> */}

          <Form.Item
            label={getLanguage(this.props).investCapacity}
            name="tCIEse"
          >
          <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} defaultValue={this.state.tCIEse} onChange={(e)=>this.setState({tCIEse:e.target.value})} />
              
           {this.state.investorError[10]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[10]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).investActivitySector}
            name="sAIir"
            style={{ width: '90%' }}
          >
          <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" onChange={(e)=>this.setState({sAIir:e.target.value})} defaultValue={this.state.sAIir} />
           {this.state.investorError[11]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[11]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).joinDamyaBusinessAngels}
            name="rDBA"
            style={{ width: '90%' }}
          >
          <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" onChange={(e)=>this.setState({rDBA:e.target.value})} defaultValue={this.state.rDBA} />
           {this.state.investorError[12]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[12]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).availability}
            name="dPORsau"
            style={{ width: '90%' }}
          >
            <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" defaultValue={this.state.dPORsau} onChange={(e)=>this.setState({dPORsau:e.target.value})} />
            {this.state.investorError[13]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[13]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).interestCenter}
            name="CIAEents"
          >
          <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} onChange={(e)=>this.setState({CIAEents:e.target.value})} defaultValue={this.state.CIAEents} />
            {this.state.investorError[14]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[14]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).notesComments}
            name="RCEels"
          >
          <input disabled={this.state.modification===true ? false : true} className="shadow custom-inputs" style={{ width: '90%' }} onChange={(e)=>this.setState({RCEels:e.target.value})} defaultValue={this.state.RCEels} />
            {this.state.investorError[15]&&<div style={{color:'red'}}>{this.state.investorErrorMsg[15]}</div>} 
          </Form.Item>
          <Button disabled={this.state.modification===true ? false : true} onClick={this.updateMember} className="btn-info-submit">{getLanguage(this.props).save}</Button>
        </Form>:this.state.data.length>0 && Object.keys(this.state.data[0]).length !== 0 ? this.docBody(this.state.data) : ''}
                        <img src={womanSpace} className={this.state.collapsed ? 'fixed-member-space-woman-collapsed' : 'fixed-member-space-woman'} />
                    </Content>
                    </Layout>
                </Layout>
            </div>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch: (action) => {
        dispatch(action);
      },
    };
  };
  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      lang:state.auth.lang
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(SpaceMembre);