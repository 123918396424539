import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload, Switch} from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"

class EditActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photo:'',content_fr:'',content_en:'',
            idActivity:'',
            current: 0,
            fileList: [''],
  
         validation:{error:[true,true,true],errorMsg:
         [getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,    
        ]},
          activityError:[true,true,true],
          activityErrorMsg:['','','']}
       
    }

    componentDidMount(){
        this.setState({idActivity:this.props.match.params.id})
        console.log(this.props.match.params.id);
        this.getActivityById()
    }
    getActivityById=async()=>{
        const requestOptions = {
            method: 'GET',
          };
          const data = await fetch(apiURL+'getActivity/'+this.props.match.params.id,requestOptions);
          const dataJson = await data.json();
          if(data.status == 200){
            console.log("success");
            this.setState({photo:dataJson.photo, content_fr:dataJson.contentFr, content_en:dataJson.contentEn});
            // if(this.state.buttons == false){
            //   this.setState({buttons: 0})
            //   console.log('uifaux', this.state.buttons);
            // }
            // else{
            //   this.setState({buttons: 1})
            //   console.log('uivrai', this.state.buttons);
            // }
          }
    }

    activityValidation =() =>{
      let aux ={...this.state.validation}
      if(!this.state.photo ){
          aux.error[0]=true
          aux.errorMsg[0]=getLanguage(this.props).required
        }else{
            aux.error[0]=false
            aux.errorMsg[0]=''
        }
        if(this.state.content_fr.trim()=='' ){
        
          aux.error[1]=true
          aux.errorMsg[1]=getLanguage(this.props).required
        }else{
            aux.error[1]=false
            aux.errorMsg[1]=''
        }
        if(this.state.content_en.trim()=='' ){
          
          aux.error[2]=true
          aux.errorMsg[2]=getLanguage(this.props).required
        }else{
            aux.error[2]=false
            aux.errorMsg[2]=''
        }
        // if(this.state.text_fr.trim()=='' ){
     
        //   aux.error[3]=true
        //   aux.errorMsg[3]=getLanguage(this.props).required
        // }else{
        //     aux.error[3]=false
        //     aux.errorMsg[3]=''
        // }
        // if(this.state.text_en.trim()=='' && this.state.buttons ){
         
        //   aux.error[4]=true
        //   aux.errorMsg[4]=getLanguage(this.props).required
        // }else{
        //     aux.error[4]=false
        //     aux.errorMsg[4]=''
        // }
        this.setState({validation:aux})
    }
  
    updateActivity=async()=>{
      this.activityValidation()
         const ERROR = [...this.state.validation.error]
            const ERROR_MSG=[...this.state.validation.errorMsg]
            this.setState({
              activityError:ERROR,
              activityErrorMsg:ERROR_MSG
            })
        if(!this.state.validation.error.includes(true)){
        this.setState({loading:true})
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let formdata =new FormData()
        formdata.append('photo',this.state.photo)        
        // else{
        //   formdata.append('url',this.state.sliderImg)
        // }
        formdata.append('current',this.state.current)
        formdata.append('content_fr',this.state.content_fr)
        formdata.append('content_en',this.state.content_en)        
        
        const requestOptions = {
          method: 'POST',
          // headers: myHeaders,
          body: formdata
        };
        console.log('aaaa',apiURL+'updateActivity/'+this.state.idActivity,requestOptions);
        const data = await fetch(apiURL+'updateActivity/'+this.state.idActivity,requestOptions);
        console.log("here",data);
        const dataJson = await data.json();
        console.log("here2",dataJson);
        if(data.status == 200){
          console.log("success");
          this.setState({loading:false})
          window.location='/admin/activities'
        }
        }
    }

    render() { 
      const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };
      
       const defaultFile = [
        {
          uid: '0',
          name: this.state.name,
          status: 'done',
          url: this.state.photo && this.state.photo.length>0? apiURL+this.state.photo.replace('/public/', ''): '',
          thumbUrl: this.state.photo && this.state.photo.length>0? apiURL+this.state.photo.replace('/public/', ''): '',
        },
      ];      
       
      const onChange = ({ fileList: newFileList }) => {        
        newFileList.length>0 ? this.setState({photo: newFileList[0].originFileObj}): this.setState({photo:''})
        this.setState({current: 1})
        this.setState({fileList: newFileList});
        console.log(newFileList,'newFilelist');
        console.log(this.state.fileList,'fileList');
        console.log(this.state.photo,'photo');
      };
        return (  <>
            
            <h1 id='title'>Modifier l'activité</h1>
            
            <Row align="center">
                    <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                <Form
                name="basic"
                layout="vertical"
                >
                <Form.Item
                    label="Filename"
                    name="photo"
                    >     <ImgCrop grid aspect={1/1}>                          
                          <Upload
                            onPreview={onPreview}
                            listType="picture-card"
                            maxCount={1}
                            onChange={onChange}                             
                            accept=".png,.jpeg,.jpg"
                            fileList={this.state.current==0?[...defaultFile]:this.state.fileList}
                            className="upload-list-inline"
                            // beforeUpload={() => false}
                          >
                            <Button icon={<UploadOutlined />}>Télécharger</Button>
                          </Upload> 
                          </ImgCrop>                    
                    <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).requiredImage}</span>
                    {this.state.activityError[0]&&<div style={{color:'red'}}>{this.state.activityErrorMsg[0]}</div>} 
                </Form.Item>               
                <Form.Item
                    label="contenu en français"
                    name="content_fr"
                    >
                    <Input.TextArea onChange={(e)=>this.setState({content_fr: e.target.value})} value={this.state&&this.state.content_fr} />
                    {this.state.activityError[1]&&<div style={{color:'red'}}>{this.state.activityErrorMsg[1]}</div>}                </Form.Item>
                <Form.Item
                    label="contenu en anglais"
                    name="content_en"
                    >
                    <Input.TextArea onChange={(e)=>this.setState({content_en: e.target.value})} value={this.state&&this.state.content_en} />
                  {this.state.activityError[2]&&<div style={{color:'red'}}>{this.state.activityErrorMsg[2]}</div>}                
                  </Form.Item>
                <Button disabled={this.state.loading==true} onClick={this.updateActivity}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                    {getLanguage(this.props).submit} <RightOutlined />
                </Button>
                </Form>
                </Col>
                </Row>
                {this.state.loading == true ? <FullPageLoader  />: null}
        </> );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
export default connect(mapStateToProps, mapDispatchToProps)(EditActivity);