import React , { useEffect, useState } from "react";
import { Row, Col, Empty } from 'antd';
import { connect } from "react-redux"
import getLanguage from "../../utilFunction/utilFunction";
import CountUp from 'react-countup';
import Aos from "aos";
import "aos/dist/aos.css";
import getNumbersData from './../../utilFunction/showAllNumbers';

const DamyaCounter =(props)=>{

    const [data,setData]=useState(['']);
    // const [NumberById,setNumberById]=useState([]);
    useEffect(() => {
        Aos.init({
            duration : 1500,
            delay: 250,
            once: true,
            disable: 'mobile'
        });
        window.scrollTo(0, 0)
        getNumbersData(props).then((e)=>{          
            setData(e)
        })
    }, []);
    return(
        
            <div className="damya-counter-section">
                <div data-aos="fade-up" className="damya-counter-title">
                    {getLanguage(props).damyaInNumbers}
                </div>
                {data.length>0 ?
                <Row justify="center">
                {data.length>0 && Object.keys(data[0]).length !== 0 && data.map((n)=>(
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <div className="counter-up">
                            <CountUp end={n.number} duration={2} />
                        </div>
                        <p className="element-number" data-aos="fade-in">{props.lang == "fr" ? n.titleFr:n.titleEn}</p>
                    </Col>
                    ))}                             
                </Row>
                :<Empty />}
            </div>
        
    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        lang:state.auth.lang
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(DamyaCounter);