import React, { Component, useState, useEffect } from "react";
import { Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons';
import { connect } from "react-redux"
import getLanguage from "../../utilFunction/utilFunction";
import Aos from "aos";
import "aos/dist/aos.css";
import { apiURL } from '../../Config/Config';
import Moment from 'react-moment';
import getData from './../../utilFunction/showAllActualities';

const Actualities =(props)=>{

    const [data,setData]=useState(['']);

    useEffect(() => {
        Aos.init({
            duration : 1000,
            delay: 0,
            once: true,
            disable: 'mobile'
        }); 
        getData(props).then((e)=>{
            setData(e)
        })
        window.scrollTo(0, 0)
    }, []);
    // const redirection = (id)=>{
    //     window.location="/actuality/"+id
    // }
    return(
        <>
            <div className="actuality-section-single">
                <h2 data-aos="fade-up" className="title-section-actuality-single">{getLanguage(props).actuality}</h2>
                
                <Row justify="center" gutter={[0,50]}>
                    {data.length>0 && Object.keys(data[0]).length !== 0 && data.map((act)=>(
                    <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                    <div data-aos="fade-in" className="actuality-card">
                    <img src={apiURL+act.image.replace('/public/', '')} className="actuality-img" />
                    <div className="card-content">
                    {/* <span className="new-actuality">Nouvel</span> */}
                    <p className="actuality-date"><Moment format="YYYY/MM/DD">{act.creationDate}</Moment></p>
                    <h2 className="actuality-title">{props.lang == "fr" ? act.titleFr : act.titleEn}</h2>
                    <p className="single-actuality-text"> {props.lang == "fr" ? act.excerptFr.substring(0,100) : act.excerptEn.substring(0,100)}...</p>
                    <Link to={`/actuality/${act.id}`}>
                        <Button className="actuality-btn">
                        {getLanguage(props).knowMore} <RightOutlined className="actuality-btn-arrow"/>
                        </Button>
                    </Link>
                    </div>
                </div>  
                </Col>
                ))}
                </Row>
            </div>
        </>





    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        lang:state.auth.lang
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(Actualities);