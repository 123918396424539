import React, { Component } from 'react';
import { Row, Col, Input, Form, Button,Alert  } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import getLanguage from "../../utilFunction/utilFunction"
import { connect } from "react-redux"
import { apiURL } from '../../Config/Config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'

class LoginAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
          current: false,
          email:null,
          password:null
        };
    }
    
    static propTypes = {
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    }
    
    checkUser =async(token)=>{

      const isUserAdmin = await fetch(apiURL+"api/verifAdminOrNot",{headers: {
        'Authorization': 'Bearer '+token
      }});
      const isUserAdminJson = await isUserAdmin.json();
      console.log(isUserAdmin,'isadmin');      
      return isUserAdminJson
      
    }
    Login=async()=>{
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "username": this.state.email,
          "password": this.state.password
        }),
      };
      await fetch(apiURL+"api/login_check", requestOptions)
        .then(response => {
          if(response.status == 200){
            console.log("successs",response);
            response.text().then(result =>{
              const str = JSON.stringify(result).substring(14)
              const newStr = str.substring(0, str.length - 4);
              this.checkUser(newStr)
              .then((e) => {
                if(e =='true'){
                  const action = {type:"GET_TOKEN", token:newStr, isLogIn:true,username:this.state.email, isAdmin:true}
                  this.props.dispatch(action)
                  // window.location= '/admin'
                  this.props.history.push("/admin");
                }else{
                  this.setState({errorMsg:"mismatch data"})
                }
               }); 
            })
          }
          else{
            this.setState({errorMsg:"mismatch data"})
            const action = {type:"GET_TOKEN", token:'', isLogIn:false, isAdmin:false }
              this.props.dispatch(action)
          }
        })
        .catch(error => console.log('error', error));
    }

    render() { 
        return ( 
            <div className={this.props.lang == "fr"?this.props.lang+"_login-form-container":this.props.lang+"_login-form-container"}>
        <Row align="center">
          <Col xs={24} sm={22} md={18} lg={13} xl={13}>
            <div className="login-card">
              <h3 className="login-card-title">{getLanguage(this.props).loginAdminSpace}</h3>
              <center>
              
                <Row align="center">
                    <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                    {this.state.errorMsg&&  <div style={{marginBottom:"10px"}}>  <Alert message="Error" type="error" showIcon /></div>}
                <Form
                  name="basic"
                  layout="vertical"
                >
                    <Input className="login-email" onChange={(e)=>this.setState({email:e.target.value})} placeholder={getLanguage(this.props).login} name="email" />
                    <Input.Password  className="login-password" onChange={(e)=>this.setState({password:e.target.value})} placeholder={getLanguage(this.props).mdp} />
                      <Button onClick={this.Login} className="btn-login">
                      {getLanguage(this.props).connexion}<RightOutlined className="arrow-login"/>
                      </Button>
                    {/* <p className="login-text">{getLanguage(this.props).mdpoublié}</p>
                    <Link to="/reset" className="login-text-one">{getLanguage(this.props).cliquezici}</Link> 
                    <p className="login-text-two">{getLanguage(this.props).makeImpact}</p> */}
                   
                  </Form>
                  </Col>
                </Row>
               </center>
            </div>
             </Col>
                  </Row>
        </div>
       
                  );
            
            
    }
}
 
const mapDispatchToProps = (dispatch) => {
    return {
      dispatch: (action) => {
        dispatch(action);
      },
    };
  };
  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      lang:state.auth.lang
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginAdmin));