import React from 'react'
import CIcon from '@coreui/icons-react'

const _nav =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/admin',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Dossiers',
    to: '/admin/folders',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Fichiers partageable',
    to: '/admin/sharedfiles',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'carrousels',
    to: '/admin/sliders',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Actualités',
    to: '/admin/actualities',
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Événements',
  //   to: '/admin/events',
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'Boards',
    to: '/admin/boards',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Angels',
    to: '/admin/angels',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'candidatures',
    to: '/candidature',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'activités',
    to: '/admin/activities',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'partenaires',
    to: '/admin/showPartners',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Damya en chiffres',
    to: '/admin/numbers',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Vidéos',
    to: '/admin/videos',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'contact',
    to: '/admin/showFormContact',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'NewsLettre',
    to: '/admin/showSubscriptionNewsLetter',
  },


]

export default _nav
