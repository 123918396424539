import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Image, Modal,Input, Space,Table } from 'antd';
import { apiURL } from '../../../Config/Config';
import { SearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';

const indexURL = apiURL+"getAllSlider"

const Slider = (props) => {
    const [data, setData] = useState([''])
    const [searchText, setsearchText] = useState('');
    const [searchedColumn, setsearchedColumn] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [idSlider, setIdSlider] = useState('');
    const [visibleModal, setvisibleModal] = useState(false);


    useEffect(() => {
        getData()
    }, [])

    const getData=async()=>{
    const requestOptions = {
        method: 'GET',
    };
    const res =  await fetch(indexURL, requestOptions);
    if(res.status == 200){
        const resJson = await res.json();
        setData(resJson.reverse());
    }            
    }

    const removeData =async (id) => {
        console.log("testtt",id);
        const requestOptions = {
            method: 'POST',
          };
          const data = await fetch(apiURL+'deleteSlider/'+id,requestOptions);
          const dataJson = await data.json();
          console.log("here2",dataJson);
          if(data.status == 200){
            console.log("success");
            setvisibleModal(false)
            window.location='/admin/sliders'
          }
    }


    const renderHeader = () => {
        let headerElement = ['id','name', 'image', 'title fr', 'title en', 'text fr', 'text en', 'Action']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    const addRedirection =()=>{
    window.location="/admin/add-slider"
    }
    const updateRedirection =(id)=>{
        window.location="/admin/editSlider/"+id
    }
    
    const showModal = (id) => {
        setIdSlider(id)
        setvisibleModal(true);
      };
    const  closeModal=()=>{
        setvisibleModal(false);
      }

    const  handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setsearchText(selectedKeys[0])
        setsearchedColumn(dataIndex)
    };

    const  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
        //   ref={node => {
        //     searchInput = node;
        //   }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setsearchText(selectedKeys[0])
                setsearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

const  handleReset = clearFilters => {
    clearFilters();
    setsearchText('')
  };

    const columns =[
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            ...getColumnSearchProps('id'),
        },
        {
            title: 'Nom',
            dataIndex: 'name',
            // key: 'id',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Image',
            dataIndex: 'url',
            // key: 'id',
            ...getColumnSearchProps('url'),
            render: (val)=>(val&&val.length>0?<Image src={apiURL+val.replace('/public/', '')} width={50} height={50} />:"")
        },
        {
            title: 'Titre en francais',
            dataIndex: 'titleFr',            
            ...getColumnSearchProps('titleFr'),            
        },
        {
            title: 'Titre en anglais',
            dataIndex: 'titleEn',            
            ...getColumnSearchProps('titleEn'),            
        },
        {
            title: 'Text en anglais',
            dataIndex: 'textEn',            
            ...getColumnSearchProps('textEn'),            
        },
        {
            title: 'Text en anglais',
            dataIndex: 'textEn',            
            ...getColumnSearchProps('textEn'),            
        },
        {             
            title: 'action',
            render: (dataIndex) => (
            <div style={{display:'flex', 'align-items':'flex-end', 'flex-direction': 'column', gap:3}}>
                <Link to={`/admin/editSlider/${dataIndex.id}`}>
                  <Button size='small' type="primary">Editer</Button>
                </Link>
                <Button size='small' type="danger" onClick={()=>showModal(dataIndex.id)}>Supprimer</Button>
            </div>
            ),
        },
    ];

    return (
        <>
            <h1 id='title'>Caroussels</h1>
            {/* <center>
                <button className='button' onClick={() => addRedirection()}>Add</button>
            </center> */}
            <Row justify="center">
                {/* <table id='employee'>
                    <thead>
                        <tr>{renderHeader()}</tr>
                    </thead>
                    <tbody>
                    {data.length>0 && Object.keys(data[0]).length !== 0 && data.map(({ id, url, name, titleFr, titleEn, textFr, textEn }) => (
                        <tr key={id}>
                            <td>{id}</td>
                            <td>{name}</td>
                            <td>
                                <Image src={apiURL+url.replace('/public/', '')} width={50} height={50} />
                            </td>
                            <td>{titleFr}</td>
                            <td>{titleEn}</td>
                            <td>{textFr}</td>
                            <td>{textEn}</td>
                            <td className='opration'>
                                <Link className='button' to={`/admin/editSlider/${id}`}>Edit</Link>
                                <button className='button' onClick={()=>showModal(id)}>Delete</button>
                            </td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table> */}
                <Col>
                    <Button type="primary" style={{float:'right', marginBottom:20}}  onClick={() => addRedirection()}>Ajouter</Button>
                    <Table columns={columns} dataSource={data} />
                </Col>
            </Row>
            <Modal title="supprimer slider" visible={visibleModal} footer={null} mask={true} closable={false} maskClosable={true}>
            <p style={{textAlign:"center"}}>veuillez confirmer votre choix</p>
            <Row>
                <Col span={12}>
                <Button onClick={()=>removeData(idSlider)} >delete</Button>
                </Col>
                <Col className="modal-button-delete" span={12}>
                <Button onClick={()=>closeModal()} >cancel</Button>
                </Col>
            </Row>
            
            

      </Modal>
        </>
    )
}


export default Slider