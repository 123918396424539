const messagesEn = {
   
    // Home Page 
    'DevenirDamyaAngel': "Become Damya angel",
    'DéposezVotreProjet': "Submit your project",
    // 'slider1TitlePart1': "The First Tunisian",
    // 'slider1TitlePart2': "Women Business Angels",
    // 'slider1TitlePart3': "Network",
    // 'slider2TitlePart1': "Make",
    // 'slider2TitlePart2': "an Impact",
    // 'slider2TitlePart3': "Join us",
    // 'impactText': "Business Angels invest in the capital of innovative SMEs and startups, with high growth potential.",
    'servicesTitle': "Our values",
    'serviceTitle1': "Commitment",
    'serviceTitle2': "Confidence",
    'serviceTitle3': "Growth",
    'serviceTitle4': "Networking",
    'serviceTitle5': "Impact",
    'serviceTitle6': "Influence",
    'serviceTitle7': "Ethics",
    'showAll': "Show all",
    'startupTitle': "For Startups",
    'startupText': "Do you want to accelerate your company's development and maximize your impact? Do you want to raise funds and to to be mentored by experienced business angels ?",
    'startupTextOne': "Through DAMYA, you can get access to high-level personalized coaching and access to funding to ensure the success of your project.",
    'knowMore': "Find out more",
    'investTitle': "For investors",
    'investText': "DAMYA Business Angels is a network of women impact investors, supporting innovative and sustainable entrepreneurship, and in particular women's initiatives in Tunisia.",
    'boardTitle': "Our board",
    'boardText': "Tunisian women, business leaders, managers, executives and experts came together to launch the 1st Tunisian network of Women Business Angels Driven by the ambition to create value",
    'memberPresident': "President",
    'memberVicePresident': "Vice-President",
    'treasurer': "Treasurer",
    'defaultMember': "member",
    'moreAboutTeam': "More about the TEAM",
    'testimonyTitle': "Testimonial woman Damya",
    'testimonyTitle1':'Discover Damya in video',
    'testimonySubtitle1': "From 0 to 25,000 DT in turnover in 8 months",
    'testimonySubtitle2': "From 0 to 9,000 DT in turnover in 8 months",
    'testimonySubtitle3': "From 0 to 20,000 DT in turnover in 8 months",
    'testimonyText1': "‘’Be a network of women impact investors to",
    'testimonyText2': "‘’Be a network of women impact investors to",
    'testimonyText3': "‘’Be a network of women impact investors to",
    'testimonyTextLineBreak1': "support entrepreneurship’’",
    'testimonyTextLineBreak2': "support entrepreneurship’’",
    'testimonyTextLineBreak3': "support entrepreneurship’’",
    'partnerTitle': "Our partners",
    'yes': "Yes",
    'no': "No",

    // TopBar & Header
    'home': "Damya",
    'aboutUs': "Who we are",
    'ourTeam': "Our angels",
    'program': "Our activity",
    'actuality': "News",
    'board': "Our board",
    'raiseFunds': "Find an investor",
    'damyaInNumbers': "Damya in numbers",
    'ourPartner': "Our partners",
    'contact': "Contact us",
    'profile': "Profile",
    'settings': "Settings",
    'logout': "Logout",
    'memberSpace': "Member area",

    // Footer
    'followUs': "Follow us:",
    'navigation': "Navigation",
    'news': "News",
    'startupServices': "Service for Startups",
    'contactfooter': "Contact",
    'schedule': "Mon - Sat: 9:00 - 18:00",
    'newsletter': "Newsletter",
    'subscriptionText': "Subscribe to our newsletter. Be informed about all news!",
    'inscription': "Inscription",
    'exampleEmail': "example@gmail.com",

    // About us    
    'aboutUsTitle': "Who are we ?",
    'aboutUsLeftColumn': "The 1st network of Tunisian Business Angels women",    
    'aboutUsText1': "DAMYA, is the name of the Berber queen of Ifriquiyâ also known as the Kahena.",
    'aboutUsText2': "She was one of the first feminists and warrior queens in history, an emblem of brave and determined women and a symbol of lucid strength.",
    'aboutUsText3': "On December 17, 2019, Tunisian women, business leaders, managers, executives and experts gathered to launch the 1st Tunisian network of Women Business Angels.",
    'aboutUsText4': "Driven by the ambition to create value, they are convinced of being able to support innovative projects with high potential in Tunisia, by investing in these projects on a personal basis but also by providing advice, expertise, experience and networking that every entrepreneur greatly needs.",
    'aboutUsText5': "DAMYA Business Angels is a network of women impact investors, supporting innovative and sustainable entrepreneurship, and in particular women's initiatives in Tunisia.",
    'ourMission': "Our Mission",
    'missionText': "Damya Tunisian Women Business Angels Network's mission is to contribute to the development of Tunisian entrepreneurs of tomorrow by injecting value-added capital (Smart Capital) into innovative companies with high growth potential.",
    'ourVision': "Our Vision",
    'visionText': "Damya's vision is to contribute to the development of a business angel investment ecosystem in Tunisia that promotes the influence of Tunisian entrepreneurs and the development of an impactful business angel community.",
    'ourApproach': "Our Approach",
    'approachText': "We believe that the best way to boost a business and support it towards success is to surround it with value-added investors who bring capital, expertise and a business network that opens doors for them.",
    'angelsDefinition': "Who are the angels?",
    'angelsDefinitionText': "Our angels are experienced entrepreneurs who have had success in business, as well as a wealth of experience and knowledge of the private sector, They are high net worth individuals who have come together and are ready to invest in Tunisian companies.",
    'angelsGoals': "What are the angels looking for?",
    'angelsGoalsText': "Our angels all share the same goal: the professionalism of angel investment and the success and growth of the companies in which they invest.",
    'ourObjectif': "Our goals",
    'objectifText': "Promote the culture and disseminate activities related to the Angel investing and entrepreneurship.",
    'objectifText1': "Support women entrepreneurs and organize regular meetings between members and entrepreneurs.",
    'objectifText2': "Facilitate the evaluation and investment in entrepreneurial projects led by women and men who are in the initial phase of their activities, and/or who have a strong growth potential and/or who are characterized by a high rate of innovation.",
    'objectifText3': "Create a bridge between women investors in Tunisia and the diaspora abroad, in order to encourage them to contribute to the development of SMEs and start-ups in Tunisia.",
    'makeImpact': "#Make_an_impact",
    'makeImpact1': "and join the first Tunisian Woman business angel network",

    // Program
    'activityTitle': "Our activities",
    'activity1': "Provision of a network of women Business Angels for the benefit of supported startups.",
    'activity2': "Stimulation of female entrepreneurship, particularly in innovative sectors and disadvantaged areas.",
    'activity3': "The promotion of alternative financing (BA, crowdfunding, Equity ...).",
    'activity4': "Support towards fundraising.",
    'activity5': "Organization of training courses on topics related to investment and project financing.",
    'activity6': "Conducting evaluation activities of investment projects.",
    'activity7': "The organization of meetings between potential investors and Tunisian SMEs and startups.",

     // Validation messages
    'required': "*required",
    'requiredCorrectEmail': "*please fill in your email correctly",
    'requiredCorrectPhoneNumber': "*must be a phone number",
    'requiredCorrectPhoneNumber1': "*must be a valid phone number",
    'numberRequired': "*must be a number",
    'requiredValideNumber': "*must be valid number",
    'requiredUsedEmail': "*email already used",

    // Startup Step zero
    'startupStepZeroTitle1': "Do you want to raise funds for your project?",
    'startupStepZeroTitle2': "Your adventure starts here", 
    'startupStepZeroText1': "Do you have a project with high growth potential, are you looking for funds for your business?",
    'startupStepZeroText2': "Do you want to accelerate the development of your business and maximize your impact and access funding as well as personalized support from experienced Business Angels?", 
    'startupStepZeroText3': "Thanks to DAMYA, you have access to high-level personalized mentoring and / or funding to ensure the success of your project.",

    // Startup Step one
    'formStep1': "STEP 01",
    'founder': "Founder / Co-Founder",
    'teamMember': "Team member",
    // 'startupFromWarning': "Before you begin, make sure you have a PPT or PDF presentation of your business on hand.",
    'lastName': "Name",
    'firstName': "Surname",
    'gender': "Gender",
    'male': "Male",
    'female': "Female",
    'other': "Other",
    'email': "E-mail address",
    'phone': "Phone number",
    'position': "Your position in the company",
    'startupName': "Startup name",
    'businessCreation': "Date of establishment of the company",
    'projectDefinition': "Definition of the project in one sentence",

    // Startup Step two
    'formStep2': "STEP 02",
    'eCommerce': "E-commerce",
    'technology': "Technology",
    'industry': "Industry",
    'Agriculture': "Agriculture",
    'communication': "Communication",
    'service': "Service",
    'marketCustomerNeeds': "Market and customer needs analysis",
    'productServiceDesign': "Product or service design",
    'testPhase': "Test phase on the first customers",
    'startupMarketing': "Marketing",
    'businessDevelopment': "Business development (growth phase)",
    'activitySector': "Activity sector",
    'projectDescription': "Detailed description of the project (services, marketed products, etc.)",
    'startupAct': "Do you have the Startup Act Label?",
    'projectStage': "At what stage are you in the development of your project?",
    'capital': "Your capital in Dinars",
    'funding': "Your financing need in Dinars",
    'stageFunds': "Do you have funds at this stage?",
    'turnover': "Your turnover for the year 2020?",
    

    // Startup Step three
    'formStep3': "STEP 03",
    'membersNumber': "How many people are you on the team?",
    'femalesNumber': "How many women are you on the team?",
    'impact': "Do you consider that your project has a positive impact on society?",
    'positifImpact': "What is your positive impact?",
    'projectCreativity': "What is your creativity in this project?",
    'joinDamyaBusinessAngels': "Why do you wish to join Damya Business Angels ?",
    'supportProgram': "Are you applying in parallel to a support program?",
    'notesComments': "Remarks and possible comments",
    'suggestCVDownload': "Please upload your curriculum vitae",
    'clickDownload': "Click to download",
    'requiredWordFile': "*Only word file accepted",
    'suggestDocDownload': "Please upload your documents",
    'requiredImage':'*Only image accepted',

    // Startup thanking Step
    'stepThx': "Thanks !",
    'startupStepThx1': "Your application has been received",
    'programDiscover': "Discover Our Program",
    'backHome': "Return to home",

    // Investor Step zero
    'investorStepZeroTitle': "What is a Damya angel ?",
    'investorStepZeroText1': "Business Angels invest in the capital of innovative SMEs and startups with high growth potential.",
    'investorStepZeroText2': "Unlike other venture capitalists, our members not only provide entrepreneurs with financing but also advice, experience and networking",
    'investorStepZeroText3': "Members of the Damya Business Angels network are also mentors and experts in the Tunisian entrepreneurial ecosystem who devote their time to support!",

    // Investor Step one
    'chefEntreprise': "Entrepreneur / Manager",
    'employee': "Employee",
    'retired': "Retired",
    'liberalProfession': "Liberal profession",
    'consultant': "Consultant",
    'finance': "Management / Accounting, Finance",
    'direction': "Management / Executive management",
    'marketing': "Marketing / Communication",
    'humanResource': "Human resource / Formation",
    'Accompaniement': "Advice / Accompaniement",
    'rseEssFinance': "RSE / ESS, Finance",
    'jural': "Jural / Taxation",
    'production': "Production / Operation, R&D",
    'investorFromWarning': "Before you begin, make sure you have a PPT or PDF presentation of your business on hand.",
    'usedEmail': "email already used",
    'professionalSituation': "Professional background",
    'skillsTraining': "Skills and training",
    'currentBusinessOrganization': "Current company or organization",
    'dominantSunctionalSkills': "Main functional competencies",
    'selectIem': "Select...",

    // Investor Step two
    'lessThan5000': "Less than 5000 DT",
    'from5000': "From 5000 DT",
    'between5000and10000': "Between 5000 DT and 10000 DT",
    'moreThan10000': "More than 10000 DT",
    'moreThan20000': "More than 20000 DT",
    'experience': "Years of experience in your field",
    'investExperience': "Direct business investment experience ?",
    'investExperienceField': "Investment experience in which area and which company ?",
    'investCapacity': "How much can you invest in a company ?",
    'investActivitySector': "Sectors of activity in which you would like to invest",
    // Investor Step three
    'animation': "Network animation",
    'projectStudy': "Research and study of projects",
    'workGroup': "Miscellaneous working group",
    'nonDisponible': "Not available",
    'disponibilityFct': "According to my availability",
    'availability': "Availability to participate in the organization of the network",
    'interestCenter': "Interests and other commitments",
    // Investor Thanking Step 
    'investorStepThx1': "Your information has been received.",

    // Login Form
    'loginMemberSpace': "Member Area.",
    'login': "Login",
    'mdp' : "Password",
    'connexion' : "Log in",
    'mdpoublié' : "Forgot your password ?",
    'cliquezici' : "Click here",

    // Login Admin
    'loginAdminSpace': "Admin Area",

    // Forget password
    'mdpoublié2': "Forgot your password !",
    'instruction': "Enter your email address to receive password reset instructions.",
    'adressemail' : "E-mail adress",
    'confirmer' : "Confirm",

    // Contactez nous
    'titre': "Contact us",
    'nometprenom': "First and last name",
    'emailc' : "Email",
    'message' : "Your message",
    'btnenvoyer' : "Send",

    // Investor Page
    'next': "Next",
    'previous': "Previous",
    'submit': "Submit",
    'become': "Become",
    'damyaAngel': "Damya Angel",
    'return': "Return",

    // Startup Page
    'submitProject': "Submit Your project",
    'project': "a project",

    //damya numbers
    // 'number':'numbers',
    // 'ofcoachedstartups':'of Coached startups',
    // 'offundedstartups':'of Funded Startups',
    // 'oftraining':'of Training',
    // 'ofpartners':'of Partners',
    // 'ofevents':'of Events',

    //member space
    'replace':'replace',
    'profileModification':'modify profile',
    'personalInfo':'Personal information',
    'documents':'Documents',
    'returnAcceuil': 'Back home',
    'save' : 'Save',
    'skillsTrainingBio': "small bio (Skills and training)",
  };

  export default messagesEn;