import React, { Component } from "react";
import { Row, Col, Input, Form, Select, Alert, Space } from 'antd';
import { connect } from "react-redux"
import getLanguage from "../../utilFunction/utilFunction";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


const { Option } = Select;

class InvestorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: false,
      value:[],
      value1:[],
      value2:[]
    };
  }
  componentDidMount(){
    window.scrollTo(0, 0)
  }
onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
  console.log('Failed:', this.props);
};

   onClose = (e) => {
    console.log(e, 'I was closed.');
  };
  CompétencesFonctionnellesDominantes = () => {
    const children = [];
    children.push(<Option key={"Gestion / Comptabilité, Finance"}>{getLanguage(this.props).finance}</Option>);
    children.push(<Option key={"Management / Direction générale"}>{getLanguage(this.props).direction}</Option>);
    children.push(<Option key={"Marketing / Communication"}>{getLanguage(this.props).marketing}</Option>);
    children.push(<Option key={"Ressource humaine / Formation"}>{getLanguage(this.props).humanResource}</Option>);
    children.push(<Option key={"Conseil / Accompagnement"}>{getLanguage(this.props).Accompaniement}</Option>);
    children.push(<Option key={"RSE / ESS, Finance"}>{getLanguage(this.props).rseEssFinance}</Option>);
    children.push(<Option key={"Juridique / Fiscalité"}>{getLanguage(this.props).jural}</Option>);
    children.push(<Option key={"Production / Opération, R&D"}>{getLanguage(this.props).production}</Option>);
    const value = this.state.value
    const selectProps = {
      mode: 'multiple',
      style: {
        width: '100%',
      },
      value,
      children,
      onChange: (newValue) => {
        this.props.onChangeInvestorFormData(newValue,'cFDtes',6)
        this.setState({value:newValue})
      },
      placeholder: getLanguage(this.props).selectIem,
      maxTagCount: 'responsive',
    };
    return (
      <Space
        direction="vertical"
        style={{
          width: '100%',
        }}
      >
        <Select {...selectProps} />
      </Space>
    );
  };

DisponibilitéParticiperOrganisationRéseau = () => {
    const children = [];
    children.push(<Option key={"Animation du réseau"}>{getLanguage(this.props).animation}</Option>);
    children.push(<Option key={"Recherche et étude des projets"}>{getLanguage(this.props).projectStudy}</Option>);
    children.push(<Option key={"Groupe de travail divers"}>{getLanguage(this.props).workGroup}</Option>);
    children.push(<Option key={"Non disponible"}>{getLanguage(this.props).nonDisponible}</Option>);
    children.push(<Option key={"En fonction de mes disponibilité"}>{getLanguage(this.props).disponibilityFct}</Option>);
    children.push(<Option key={"autres"}>{getLanguage(this.props).other}</Option>);

    const value1 = this.state.value1
    const selectProps = {
      mode: 'multiple',
      style: {
        width: '100%',
      },
      value1,
      children,
      onChange: (newValue) => {
        this.props.onChangeInvestorFormData(newValue,'dPORsau',12)
        this.setState({value1:newValue})
      },
      placeholder: getLanguage(this.props).selectIem,
      maxTagCount: 'responsive',
    };
    return (
      <Space
        direction="vertical"
        style={{
          width: '100%',
        }}
      >
        <Select {...selectProps} />
      </Space>
    );
  }; 

  
SituationProfessionnelle =()=>{
  const children = [];
  children.push(<Option key={"Chef d'entreprise/ Manager"}>{getLanguage(this.props).chefEntreprise}</Option>);
  children.push(<Option key={"Salarié(e)"}>{getLanguage(this.props).employee}</Option>);
  children.push(<Option key={"Retraité(e)"}>{getLanguage(this.props).retired}</Option>);
  children.push(<Option key={"Profession libérale"}>{getLanguage(this.props).liberalProfession}</Option>);
  children.push(<Option key={"Consultant(e)"}>{getLanguage(this.props).consultant}</Option>);
  const value2 = this.state.value2
  const selectProps2 = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    value2,
    children,
    onChange: (newValue) => {
      this.props.onChangeInvestorFormData(newValue,'sPle',4)
      this.setState({value2:newValue})
    },
    placeholder: getLanguage(this.props).selectIem,
    maxTagCount: 'responsive',
  };
  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
      <Select {...selectProps2} />
    </Space>
  );
};

    render() {
     console.log("selected",this.state.value);
        return (
            <div>
                
            <Row type="flex">
            <Col xs={24} sm={24} md={24} lg={21} xl={21}> 
            <h3 className={this.props.lang == "fr"?this.props.lang+"_startup-form-title":this.props.lang+"_startup-form-title"}>{getLanguage(this.props).formStep1}</h3>
            <p className={this.props.lang == "fr"?this.props.lang+"_startup-form-description-content":this.props.lang+"_startup-form-description-content"}>{getLanguage(this.props).investorFromWarning}</p>
            {this.props.existEmail == true?<Alert
              message={getLanguage(this.props).requiredUsedEmail}
              type="error"
              closable
              onClose={this.onClose}
            />:null}

          <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >    
    <div className="inline-inputs">
          <Form.Item
            label={getLanguage(this.props).lastName}
            name="nom"
            style={{ width: '50%' }}
          >
          <Input style={{ width: '100%' }} onChange={(e)=>this.props.onChangeInvestorFormData(e.target.value,'nom',0)} value={this.props.investorFormData.nom} />
           {this.props.investorFormError[0]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[0]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).firstName}
            name="prenom"
            style={{ width: '50%' }}
          >
          <Input style={{ width: '100%' }} onChange={(e)=>this.props.onChangeInvestorFormData(e.target.value,'prenom',1)} value={this.props.investorFormData.prenom} />
           {this.props.investorFormError[1]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[1]}</div>}
          </Form.Item>
          </div>
          <div className="inline-inputs">
          <Form.Item
            label={getLanguage(this.props).email}
            name="email"
            style={{ width: '50%' }}
          >
          <Input className={this.props.existEmail ==true ? "error-input": null} style={{ width: '100%' }} onChange={(e)=>this.props.onChangeInvestorFormData(e.target.value,'email',2)} value={this.props.investorFormData.email} />
           {this.props.investorFormError[2]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[2]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).phone}
            name="tel"
            style={{ width: '50%' }}
          >
          <PhoneInput defaultCountry="TN" style={{ width: '100%' }} onChange={(e)=>this.props.onChangeInvestorFormData(e,'tel',3)} value={this.props.investorFormData.tel} />
          
           {this.props.investorFormError[3]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[3]}</div>}
          </Form.Item>
          </div>
          <div className="inline-inputs mobile-block">
          <Form.Item
            label={getLanguage(this.props).professionalSituation}
            name="sPle"
            style={{ width: '100%' }}
          >
            {this.SituationProfessionnelle()}
           {this.props.investorFormError[4]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[4]}</div>}
          </Form.Item>
          </div>
            <Form.Item
              label={getLanguage(this.props).currentBusinessOrganization}
              name="eOAlle" 
            >
              <Input style={{ width: '100%' }} onChange={(e)=>this.props.onChangeInvestorFormData(e.target.value,'eOAlle',5)} value={this.props.investorFormData.eOAlle} />
              {this.props.investorFormError[5]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[5]}</div>}
            </Form.Item>

            <Form.Item
              label={getLanguage(this.props).dominantSunctionalSkills}
              name="cFDtes"
            >
            {this.CompétencesFonctionnellesDominantes()}
              {this.props.investorFormError[6]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[6]}</div>}
            </Form.Item>        
        <Form.Item
              label={getLanguage(this.props).experience}
              name="nAEce"
            >
              <Input style={{ width: '100%' }} onChange={(e)=>this.props.onChangeInvestorFormData(e.target.value,'nAEce',7)} value={this.props.investorFormData.nAEce} />
              {this.props.investorFormError[7]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[7]}</div>}
            </Form.Item>
            <Form.Item
            label={getLanguage(this.props).investExperience}
            name="eIDEse"
            >
            <Select style={{ width: '100%' }} defaultValue="choix" onChange={(e)=>this.props.onChangeInvestorFormData(e,'eIDEse',8)}>
              <Option value="Oui">{getLanguage(this.props).yes}</Option>
              <Option value="Non">{getLanguage(this.props).no}</Option>
            </Select>
           {this.props.investorFormError[8]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[8]}</div>} 
            </Form.Item>        
          <Form.Item
            label={getLanguage(this.props).investCapacity}
            name="tCIEse"
          >
          <Select style={{ width: '100%' }} defaultValue="choix" onChange={(e)=>this.props.onChangeInvestorFormData(e,'tCIEse',9)}>
              <Option value="Moins de 5000 DT">{getLanguage(this.props).lessThan5000}</Option>
              <Option value="à partir de 5000 DT">{getLanguage(this.props).from5000}</Option>
              <Option value="Entre 5000 DT et 10000 DT">{getLanguage(this.props).between5000and10000}</Option>
              <Option value="Plus de 10000 DT">{getLanguage(this.props).moreThan10000}</Option>
              <Option value="Plus de 20000 DT">{getLanguage(this.props).moreThan20000}</Option>
            </Select>
           {this.props.investorFormError[9]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[9]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).investActivitySector}
            name="sAIir"
            style={{ width: '100%' }}
          >
          <Input onChange={(e)=>this.props.onChangeInvestorFormData(e.target.value,'sAIir',10)} value={this.props.investorFormData.sAIir} />
           {this.props.investorFormError[10]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[10]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).joinDamyaBusinessAngels}
            name="rDBA"
            style={{ width: '100%' }}
          >
          <Input.TextArea onChange={(e)=>this.props.onChangeInvestorFormData(e.target.value,'rDBA',11)} value={this.props.investorFormData.rDBA} />
           {this.props.investorFormError[11]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[11]}</div>}
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).availability}
            name="dPORsau"
            style={{ width: '100%' }}
          >            
            {this.DisponibilitéParticiperOrganisationRéseau()}
            {this.props.investorFormError[12]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[12]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).interestCenter}
            name="CIAEents"
          >
          <Input style={{ width: '100%' }} onChange={(e)=>this.props.onChangeInvestorFormData(e.target.value,'CIAEents',13)} value={this.props.investorFormData.CIAEents} />
            {this.props.investorFormError[13]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[13]}</div>} 
          </Form.Item>
          <Form.Item
            label={getLanguage(this.props).notesComments}
            name="RCEels"
          >
          <Input.TextArea style={{ width: '100%', height: '0' }} onChange={(e)=>this.props.onChangeInvestorFormData(e.target.value,'RCEels',14)} value={this.props.investorFormData.ville} />
            {this.props.investorFormError[14]&&<div style={{color:'red'}}>{this.props.investorFormErrorMsg[14]}</div>} 
          </Form.Item>
          </Form>
              </Col>
            </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch: (action) => {
        dispatch(action);
      },
    };
  };
  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
      lang:state.auth.lang
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(InvestorForm);