import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TopBar from "../../components/TopBar"
import Header from "../../components/Headers"

const AuthRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>(
      <div>
        <TopBar /> 
        <Header />
        <Component {...props}  />
      </div>
    )         
    }
  />
);

AuthRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default AuthRoute;