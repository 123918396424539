import React, { useEffect } from "react";
import { Row } from 'antd';
import { connect } from "react-redux"
import getLanguage from "../../utilFunction/utilFunction";


const StepZeroStartup =(props)=>{
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div>
        <Row className="step-zero-title">{getLanguage(props).startupStepZeroTitle1}<br />
{getLanguage(props).startupStepZeroTitle2}</Row>
        <Row className="step-zero-content">

        <div className="step-zero-content-type">{getLanguage(props).startupStepZeroText1}<br /></div>
          <div className="step-zero-content-type">{getLanguage(props).startupStepZeroText2}<br /> </div>
            <div className="step-zero-content-type">{getLanguage(props).startupStepZeroText3}<br /></div>
              <div className="step-zero-content-type-style">#Pitch_and_raise</div>
        </Row>
        </div>







    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(StepZeroStartup);