import React, { useEffect } from "react";
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
// import { useHistory } from 'react-router-dom';
import getLanguage from "../../utilFunction/utilFunction";
import Aos from "aos";
import "aos/dist/aos.css";
import flechCercle from "../../assets/img/flech-cercleee.png"
import blueWoman from "../../assets/img/about-us-woman-vector.png"
// import imageSectionThree from "../../assets/img/section-three-about-us.svg"
// import imageSectionFour from "../../assets/img/section-four-abous-us.svg"

const AboutUs =(props)=>{
    useEffect(() => {
        Aos.init({
            duration : 1000,
            delay: 0,
            once: true,
            disable: 'mobile'
        }); 
        window.scrollTo(0, 0)
    }, []);

    // const history = useHistory();
    // const lang = props.auth.lang;
    // const redirection =()=>{
    //     window.location="/investor"
    //     history.push('/investor')
    // }
    return(
        <>
        <div className="section-one-about-us" data-aos="fade-up">
            <Row className="about-us-title">{getLanguage(props).aboutUsTitle}</Row>
            <Row className="step-zero-content">
            <Col xs={24} sm={24} md={24} lg={8} xl={8}> 
                <h2 className="about-us-left-side">{getLanguage(props).aboutUsLeftColumn}</h2>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>            
              <div className="about-us-content-type">
                {getLanguage(props).aboutUsText1}<br /><br />
                {getLanguage(props).aboutUsText2}<br /><br />
                {getLanguage(props).aboutUsText3}<br /><br />
                {getLanguage(props).aboutUsText4}<br /><br />
                {getLanguage(props).aboutUsText5}
              </div>
            </Col>
            </Row>
        </div>
        <div data-aos="fade-in" className="section-two-about-us">
            <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="about-us-section-two-our_mission">
                    <span className="our_mission-title">{getLanguage(props).ourMission}<br /> </span>
                    <p className="our_mission-content">{getLanguage(props).missionText}</p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="about-us-section-two-right-image">
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={{span: 12, push: 12}} lg={{span: 12, push: 12}} xl={{span: 12, push: 12}} className="about-us-section-two-our_mission-two">
                <span className="our_mission-title">{getLanguage(props).ourVision}  <br /> </span>
                    <p className="our_mission-content">{getLanguage(props).visionText}</p>
                </Col>
                <Col xs={24} sm={24} md={{span: 12, pull: 12}} lg={{span: 12, pull: 12}} xg={{span: 12, pull: 12}} className="about-us-section-two-left-image">
                </Col>
            </Row>
        </div>
        
        <div data-aos="fade-in" className="section-three-about-us">
        <Row>
           <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <img src={blueWoman} className="about-us-blue-woman"/>                
           </Col>
           <Col xs={24} sm={24} md={14} lg={15} xl={15} className="about-us-approch">
               <h3 className="section-three-title">{getLanguage(props).ourApproach}</h3>
               <div className="section-three-about-us-content">                   
                    <span className="section-three-about-us-text">
                        {getLanguage(props).approachText} <br />
                    <h3 className="section-three-subtitle">{getLanguage(props).angelsDefinition}<br /></h3>
                        <span className="section-three-about-us-text">
                        {getLanguage(props).angelsDefinitionText}<br />
                        </span>
                    <h3 className="section-three-subtitle">{getLanguage(props).angelsGoals}<br /></h3>
                        <span className="section-three-about-us-text">{getLanguage(props).angelsGoalsText}</span>
                    </span>
                </div>
                <h3 className="section-three-title">{getLanguage(props).ourObjectif}</h3>
                <div className="section-three-about-us-content">                   
                    <span className="section-three-about-us-text">
                    {getLanguage(props).objectifText}<br /><br />
                    {getLanguage(props).objectifText1}<br /><br />
                    {getLanguage(props).objectifText2}<br /><br />
                    {getLanguage(props).objectifText3}<br /><br />
                    </span>
                </div>
                    
           </Col>
        </Row>
        </div>
         {/* <div data-aos="fade-in" className="section-three-about-us">
            <img className="section-three-about-us-image-background" src={imageSectionThree} />
            <div className="section-three-about-us-content">
           <div className="section-three-title">{getLanguage(props).ourApproach} <br /></div>
           <span className="section-three-about-us-text">
            {getLanguage(props).approachText} <br />
          <div className="section-three-second-title">{getLanguage(props).angelsDefinition}<br /></div>
            <span className="section-three-about-us-text">
            {getLanguage(props).angelsDefinitionText}<br />
            </span>
           <div className="section-three-second-title">{getLanguage(props).angelsGoals}<br /></div>
             <span className="section-three-about-us-text">{getLanguage(props).angelsGoalsText}</span>
           </span>
           </div>
        </div> */}


        {/* <div data-aos="fade-in" className="section-four-about-us">
            <img src={imageSectionFour} className="section-four-about-us-image-background" />
            <div className="section-four-about-us-content">
           <div className="section-three-title">{getLanguage(props).ourObjectif} <br /></div>
           <span className="section-three-about-us-text">
           {getLanguage(props).objectifText}<br />
           <div className="section-four-about-us-text-sous">{getLanguage(props).objectifText1}</div>
           <div className="section-four-about-us-text-sous">{getLanguage(props).objectifText2}</div> 
           <div className="section-four-about-us-text-sous">{getLanguage(props).objectifText3}</div>
           </span>
           </div>
        </div> */}
        <div data-aos="fade-up" className="section-five-about-us">
            <Row className="section-five-title">
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    {getLanguage(props).makeImpact} {getLanguage(props).makeImpact1}
                </Col>
            </Row>
            <div>
        {/* <Row>
          <Col className="btn-section-five-about-us-section" xs={24} sm={24} md={24} lg={24} xl={24}>
            <div onClick={redirection} className={lang == "fr"?lang+"_btn-next-step-zero":lang+"_btn-next-step-zero"}>
            <Row className="content-btn-step-zero"><Col xs={20} sm={20} md={20} lg={20} xl={20}>{getLanguage(props).DevenirDamyaAngel}</Col><Col xs={4} sm={4} md={4} lg={4} xl={4}><img className="image-btn-step-zero" src={flechCercle} width={"100%"} /></Col></Row> 
            </div>
          </Col>
        </Row>     */}
        <Link to="/investor">
            <Button className="btn-about-us-damya-angel">
                {getLanguage(props).DevenirDamyaAngel} <RightOutlined className="about-us-btn-arrow"/>
            </Button>
        </Link>
      </div>

        </div>
        </>





    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);