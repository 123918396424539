import React, { useEffect } from "react";
import { Row } from 'antd';
import { connect } from "react-redux"
import getLanguage from "../../utilFunction/utilFunction";


const StepZero =(props)=>{
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return(
        <div className="step-zero-section">
        <Row className="step-zero-title">{getLanguage(props).investorStepZeroTitle}</Row>
        <Row className="step-zero-content">

        <div className="step-zero-content-type">{getLanguage(props).investorStepZeroText1}<br /></div>
          <div className="step-zero-content-type">{getLanguage(props).investorStepZeroText2}<br /> </div>
            <div className="step-zero-content-type">{getLanguage(props).investorStepZeroText3}<br /></div>
              <div className="step-zero-content-type"><span className="make-impact-text">{getLanguage(props).makeImpact}</span> {getLanguage(props).makeImpact1}</div>
        </Row>
        </div>
    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(StepZero);