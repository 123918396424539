import React, { useEffect } from "react";
import { Row, Col } from 'antd';
import { connect } from "react-redux"
import { useHistory } from 'react-router-dom';
import getLanguage from "../../utilFunction/utilFunction";
import flechCercle from "../../assets/img/flech-cercleee.png"


const StepThxStartup =(props)=>{
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    const history = useHistory();
    const lang = props.auth.lang;
    const  acceuil=()=>{
        history.push('/')
        // window.location='/'
    }
    const programme =()=>{
        history.push('/programs')
        // window.location='/programs'
    }
    return(
        <div>
            <div  className="step-thx-title-section">
        <Row className="step-thx-title">{getLanguage(props).stepThx}</Row>
         <Row className="step-thx-title">{getLanguage(props).startupStepThx1}</Row>
         </div>
        <div className="btn-step-thx">
            <Row>
            <Col className="btn-step-zero-section" xs={24} sm={24} md={24} lg={24} xl={24}>
              <div onClick={programme}  className={lang == "fr"?lang+"_btn-next-step-thx":lang+"_btn-next-step-thx"}>
              <Row className="content-btn-step-zero"><Col xs={20} sm={20} md={20} lg={20} xl={20}>{getLanguage(props).programDiscover}</Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4}><img className="image-btn-step-thx" src={flechCercle} width={"100%"} /></Col></Row> 
              </div>
          </Col>
          </Row>
          </div>
          <div className="btn-step-thx">
          <Row>
            <Col className="btn-step-zero-section" xs={24} sm={24} md={24} lg={24} xl={24}>
              <div onClick={acceuil}  className={lang == "fr"?lang+"_btn-next-step-thx":lang+"_btn-next-step-thx"}>
              <Row className="content-btn-step-zero"><Col xs={20} sm={20} md={20} lg={20} xl={20}>{getLanguage(props).backHome} 
</Col><Col xs={4} sm={4} md={4} lg={4} xl={4}><img className="image-btn-step-thx" src={flechCercle} width={"100%"} /></Col></Row> 
              </div>
            </Col>
          </Row>
          </div>
        </div>







    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(StepThxStartup);