import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Upload} from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined, RightOutlined } from '@ant-design/icons';
import { apiURL } from '../../../Config/Config';
import getLanguage from "../../../utilFunction/utilFunction"
import FullPageLoader from "../../FullPageLoader"
import { connect } from "react-redux"

class editMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberImg:'',nom:'',poste:'',posteEn:'',email:'',linkedin:'',description:'',descriptionEn:'',
            idMember:'',
            current: 0,
            fileList: [''],
  
         validation:{error:[true,true,true,true,true,true,true,true],errorMsg:
         [getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
         getLanguage(this.props).required,
        ]},
          memberError:[true,true,true,true,true,true,true,true],
          memberErrorMsg:['','','','','','','','']}
       
    }

    componentDidMount(){
        this.setState({idMember:this.props.match.params.id})
        console.log(this.props.match.params.id);
        this.getMemberById()
    }
    getMemberById=async()=>{
        const requestOptions = {
            method: 'GET',
          };
          const data = await fetch(apiURL+'getMember/'+this.props.match.params.id,requestOptions);
          const dataJson = await data.json();
          if(data.status == 200){
            console.log("success");
            this.setState({memberImg:dataJson.image, name:dataJson.nom, post:dataJson.poste, postEn:dataJson.posteEn, email:dataJson.email,
            linkedin:dataJson.linkedin,description:dataJson.description,descriptionEn:dataJson.descriptionEn});
            console.log('azd', this.state.memberImg);
          }
    }

    memberValidation =() =>{
      let aux ={...this.state.validation}
      if(!this.state.memberImg ){
          aux.error[0]=true
          aux.errorMsg[0]=getLanguage(this.props).required
        }else{
            aux.error[0]=false
            aux.errorMsg[0]=''
        }
        if(this.state.name.trim()=='' ){
        
          aux.error[1]=true
          aux.errorMsg[1]=getLanguage(this.props).required
        }else{
            aux.error[1]=false
            aux.errorMsg[1]=''
        }
        if(this.state.post.trim()=='' ){
          
          aux.error[2]=true
          aux.errorMsg[2]=getLanguage(this.props).required
        }else{
            aux.error[2]=false
            aux.errorMsg[2]=''
        }
        if(this.state.postEn.trim()=='' ){
          
          aux.error[3]=true
          aux.errorMsg[3]=getLanguage(this.props).required
        }else{
            aux.error[3]=false
            aux.errorMsg[3]=''
        }
        if(this.state.email.trim()=='' ){
     
          aux.error[4]=true
          aux.errorMsg[4]=getLanguage(this.props).required
        }else{
            aux.error[4]=false
            aux.errorMsg[4]=''
        }
        if(this.state.linkedin.trim()=='' ){
         
          aux.error[5]=true
          aux.errorMsg[5]=getLanguage(this.props).required
        }else{
            aux.error[5]=false
            aux.errorMsg[5]=''
        }
        if(this.state.description.trim()=='' ){
         
          aux.error[6]=true
          aux.errorMsg[6]=getLanguage(this.props).required
        }else{
            aux.error[6]=false
            aux.errorMsg[6]=''
        }
        if(this.state.descriptionEn.trim()=='' ){
         
          aux.error[7]=true
          aux.errorMsg[7]=getLanguage(this.props).required
        }else{
            aux.error[7]=false
            aux.errorMsg[7]=''
        }
        this.setState({validation:aux})
    }
  
    updateMember=async()=>{
      this.memberValidation()
         const ERROR = [...this.state.validation.error]
            const ERROR_MSG=[...this.state.validation.errorMsg]
            this.setState({
              memberError:ERROR,
              memberErrorMsg:ERROR_MSG
            })
        if(!this.state.validation.error.includes(true)){
        this.setState({loading:true})
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let formdata =new FormData()
        formdata.append('current',this.state.current)
        formdata.append('image',this.state.memberImg)
        formdata.append('nom',this.state.name)
        formdata.append('poste',this.state.post)
        formdata.append('posteEn',this.state.postEn)
        formdata.append('email',this.state.email)
        formdata.append('linkedin',this.state.linkedin)
        formdata.append('description',this.state.description)        
        formdata.append('descriptionEn',this.state.descriptionEn)        
        
        const requestOptions = {
          method: 'POST',
          // headers: myHeaders,
          body: formdata
        };
        const data = await fetch(apiURL+'updateMember/'+this.state.idMember,requestOptions);
        console.log("here",data);
        const dataJson = await data.json();
        console.log("here2",dataJson);
        if(data.status == 200){
          console.log("success");
          this.setState({loading:false})
          window.location='/admin/angels'
        }
        }
    }

    render() { 
      const onPreview = async file => {
        let src = file.url;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };
      
       const defaultFile = [
        {
          uid: '0',
          name: this.state.memberImg && this.state.memberImg.length>0?this.state.memberImg.split('/').pop(): '',
          status: 'done',
          url: this.state.memberImg && this.state.memberImg.length>0? apiURL+this.state.memberImg.replace('/public/', ''): '',
          thumbUrl: this.state.memberImg && this.state.memberImg.length>0? apiURL+this.state.memberImg.replace('/public/', ''): '',
        },
      ];      
       
      const onChange = ({ fileList: newFileList }) => {        
        newFileList.length>0 ? this.setState({memberImg: newFileList[0].originFileObj}): this.setState({memberImg:''})
        this.setState({current: 1})
        this.setState({fileList: newFileList});
        console.log(newFileList,'newFilelist');
        console.log(this.state.fileList,'fileList');
        console.log(this.state.memberImg,'memberImg');
      };
        return (  <>
            
            <h1 id='title'>Editer un member</h1>
            
            <Row align="center">
                    <Col xs={22} sm={22} md={16} lg={16} xl={16}> 
                <Form
                name="basic"
                layout="vertical"
                >
                <Form.Item
                    label="Filename"
                    name="memberImg"
                    >     <ImgCrop grid>                          
                          <Upload
                            onPreview={onPreview}
                            listType="picture-card"
                            maxCount={1}
                            onChange={onChange}                             
                            accept=".png,.jpeg,.jpg"
                            fileList={this.state.current==0?[...defaultFile]:this.state.fileList}
                            className="upload-list-inline"                            
                          >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload> 
                          </ImgCrop>                    
                    <span style={{color:"red",fontSize:"10px"}}>{getLanguage(this.props).requiredImage}</span>
                    {this.state.memberError[0]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[0]}</div>} 
                </Form.Item>
                <Form.Item
                    label="nom"
                    name="name"
                    >
                    <Input onChange={(e)=>this.setState({name: e.target.value})} value={this.state&&this.state.name} />
                    {this.state.memberError[1]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[1]}</div>}                </Form.Item>
                <Form.Item
                    label="poste en francais"
                    name="post"
                    >
                    <Input onChange={(e)=>this.setState({post: e.target.value})} value={this.state&&this.state.post} />
                    {this.state.memberError[2]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[2]}</div>}                </Form.Item>
                    <Form.Item
                    label="poste en anglais"
                    name="postEn"
                    >
                    <Input onChange={(e)=>this.setState({postEn: e.target.value})} value={this.state&&this.state.postEn} />
                    {this.state.memberError[3]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[3]}</div>}                </Form.Item>
                <Form.Item
                    label="email"
                    name="email"
                    >
                    <Input onChange={(e)=>this.setState({email: e.target.value})} value={this.state&&this.state.email} />
                    {this.state.memberError[4]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[4]}</div>}                </Form.Item>
                <Form.Item
                    label="linkedin"
                    name="linkedin"
                    >
                    <Input onChange={(e)=>this.setState({linkedin: e.target.value})} value={this.state&&this.state.linkedin} />
                  {this.state.memberError[5]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[5]}</div>}                </Form.Item>
                  <Form.Item
                    label="description en francais"
                    name="description"
                    >
                    <Input onChange={(e)=>this.setState({description: e.target.value})} value={this.state&&this.state.description} />
                  {this.state.memberError[6]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[6]}</div>}                </Form.Item>                
                  <Form.Item
                    label="description en angalis"
                    name="descriptionEn"
                    >
                    <Input onChange={(e)=>this.setState({descriptionEn: e.target.value})} value={this.state&&this.state.descriptionEn} />
                  {this.state.memberError[7]&&<div style={{color:'red'}}>{this.state.memberErrorMsg[7]}</div>}                </Form.Item>
                <Button disabled={this.state.loading==true} onClick={this.updateMember}  className={this.props.lang == "fr"?this.props.lang+"_btn-next":this.props.lang+"_btn-next"}>
                    {getLanguage(this.props).submit} <RightOutlined />
                </Button>
                </Form>
                </Col>
                </Row>
                {this.state.loading == true ? <FullPageLoader  />: null}
        </> );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
export default connect(mapStateToProps, mapDispatchToProps)(editMember);