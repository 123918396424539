import React, { useEffect, useState } from "react";
import { Row, Col } from 'antd';
import { connect } from "react-redux"
import getLanguage from "../../utilFunction/utilFunction";
import Aos from "aos";
import "aos/dist/aos.css";
import { apiURL } from "../../Config/Config";

const indexURL = apiURL+"getPartners"
const Partner =(props)=>{
    const [data, setData] = useState([''])
    useEffect(() => {
        Aos.init({
            duration : 1000,
            delay: 0,
            once: true,
            disable: 'mobile'
        }); 
        window.scrollTo(0, 0)
        getData()
    }, []);

    const getData=async()=>{
        const requestOptions = {
            method: 'GET',
        };
        const res =  await fetch(indexURL, requestOptions);
        if(res.status == 200){
            const resJson = await res.json();
            console.log('resJson',resJson);
            setData(resJson);
        }            
    }
    
    return(
        <>
            <div className="partner-section">
                <h2 className="title-section-partner" data-aos="fade-up">{getLanguage(props).partnerTitle}</h2>
                <Row justify="center" gutter={[50, 50]} data-aos="fade-in">
                    {data.length>0 && Object.keys(data[0]).length !== 0 && data.map(({ id, name, url, path  }) => (
                    <Col xs={24} sm={24} md={12} lg={8} className="">
                    <div className="partner-col shadow">
                        <a href={url} className="" target="_blank">
                            <img src={apiURL+path.replace('/public/', '')} className="partner-img"/>
                        </a>
                    </div>
                    </Col>  
                    ))}                   
                </Row>
            </div>
        </>
    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(Partner);