import React, { Component, useEffect, useState  } from "react";
import { Row, Col, Input, Form, Button, Table, Card, Modal, Checkbox, message, notification  } from 'antd';
import { connect } from "react-redux";
import { apiURL } from "../../../Config/Config";




const ShowContact =(props)=>{

    const [contact,setContact] = useState('')

    useEffect(() => {
        getContact()
    }, [])
    const getContact=async()=>{
      
            const requestOptions = {
                method: 'GET',
              };
              const data = await fetch(apiURL+'getAllContact',requestOptions);
              const dataJson = await data.json();
              console.log("here2",dataJson);
              if(data.status == 200){    
                setContact(dataJson)
              }
    }

    const columns = [
        {
          title: 'nom et prénom',
          dataIndex: 'nom',
          key: 'nom',
       
        },


        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
 
        },
        {
          title: 'remarque',
          dataIndex: 'remarque',
          key: 'remarque',
        
        },

      ];

    
    return(
        <div>
            <Table loading={contact? false:true} columns={columns} dataSource={contact} />;
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(ShowContact);