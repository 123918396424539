import React, { Component, useEffect, useState  } from "react";
import { Row, Col, Input, Form, Button, Table, Card, Modal, Checkbox, message, notification  } from 'antd';
import { connect } from "react-redux";
import { apiURL } from "../../../Config/Config";




const ShowSubscriptionNewsLetter =(props)=>{

    const [newsLetter,setNewsLetter] = useState('')

    useEffect(() => {
        getSubscription()
    }, [])
    const getSubscription=async()=>{
      
            const requestOptions = {
                method: 'GET',
              };
              const data = await fetch(apiURL+'getSubscription',requestOptions);
              const dataJson = await data.json();
              console.log("here2",dataJson);
              if(data.status == 200){    
                setNewsLetter(dataJson)
              }
    }

    const columns = [
        {
          title: 'email',
          dataIndex: 'email',
          key: 'email',
       
        },


      ];

    
    return(
        <div>
            <Table loading={newsLetter? false:true} columns={columns} dataSource={newsLetter} />;
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(ShowSubscriptionNewsLetter);