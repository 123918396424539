import React, {useEffect, useState} from "react";
import { Row, Col, Button, Empty} from 'antd';
import { Link } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons';
import { connect } from "react-redux"
import Aos from "aos";
import "aos/dist/aos.css";
import getActivitiesData from './../../utilFunction/showAllActivities';
import getLanguage from "../../utilFunction/utilFunction";
import { apiURL } from '../../Config/Config';

const NosActivites =(props)=>{   

    const [data,setData]=useState(['']);

    useEffect(() => {
        Aos.init({
          duration : 1000,
          delay: 0,
          once: true,
          disable: 'mobile'
        });         
        window.scrollTo(0, 0)
        getActivitiesData(props).then((e)=>{
          console.log('data',e);
            setData(e)
        })
    }, []); 
    return(
        <>
            <div className="nos-activities-section">
                <h2 data-aos="fade-up" className="nos-activities-title">{getLanguage(props).activityTitle}</h2>
                    <Row data-aos="fade-up" justify="center" className="nos-activities-row" gutter={[{ xs: 8, sm: 24, md: 50, lg: 20, xl: 80 }, 30]}>
                    {data.length>0 && Object.keys(data[0]).length !== 0 && data.map((actuality)=>(
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <div className="nos-activities-cards">
                                <img src={apiURL+actuality.photo.replace('/public/', '')} className="nos-activities-icons" />
                                <p className="nos-activities-text">{props.lang == "fr" ? actuality.contentFr : actuality.contentEn}</p>
                            </div>
                        </Col>
                    ))}
                        {/* <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <div className="nos-activities-cards">
                                <img src={activiteStimulation} className="nos-activities-icons" />
                                <p className="nos-activities-text">{getLanguage(props).activity4}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <div className="nos-activities-cards">
                                <img src={activiteFinancement} className="nos-activities-icons" />
                                <p className="nos-activities-text">{getLanguage(props).activity2}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <div className="nos-activities-cards">
                                <img src={activiteLeverFonds} className="nos-activities-icons" />
                                <p className="nos-activities-text">{getLanguage(props).activity3}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <div className="nos-activities-cards">
                                <img src={activiteOrganisationFormation} className="nos-activities-icons" />
                                <p className="nos-activities-text">{getLanguage(props).activity5}</p>
                            </div>
                        </Col>
                        
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <div className="nos-activities-cards">
                                <img src={activiteEvaluation} className="nos-activities-icons" />
                                <p className="nos-activities-text">{getLanguage(props).activity6}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <div className="nos-activities-cards">
                                <img src={activiteOrganisation} className="nos-activities-icons" />
                                <p className="nos-activities-text">{getLanguage(props).activity1}</p>
                            </div>
                        </Col> */}
                    </Row>
                    <p className="nos-activities-text-impact"><span className="make-impact-text">{getLanguage(props).makeImpact}</span> {getLanguage(props).makeImpact1}</p>
                    <Link to="/investor">
                        <Button className="btn-activities-damya-angel">
                            {getLanguage(props).DevenirDamyaAngel} <RightOutlined className="activities-btn-arrow"/>
                        </Button>
                    </Link>
            </div>
        </>





    )
}

    
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
        dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        lang:state.auth.lang
        };
    };
      
export default connect(mapStateToProps, mapDispatchToProps)(NosActivites);